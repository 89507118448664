.zoho-redirect-wrap .zoho-redirect-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f8fafc;
  text-align: center;
  padding: 3rem;
  padding-bottom: 4rem;
  gap: 6rem;
}
.zoho-redirect-wrap .sync-text {
  margin-top: 3rem;
  font-size: 3.2rem;
  font-weight: 400;
}
.zoho-redirect-wrap .wait-text {
  font-size: 2.2rem;
  font-weight: 300;
  color: #5b6871;
}
.zoho-redirect-wrap .logo-img {
  display: flex;
  justify-content: center;
  gap: 5rem;
  place-items: center;
}
.zoho-redirect-wrap .logo-img img {
  width: 17rem;
}
.zoho-redirect-wrap .logo-img .loading-dots {
  display: flex;
  place-items: center;
  justify-content: center;
  gap: 4rem;
}
.zoho-redirect-wrap .logo-img .loading-dots .dot {
  width: 20px;
  height: 20px;
  background-color: #cc0905;
  border-radius: 50%;
  margin: 0 5px;
  animation: zoomInOut 2s infinite alternate;
}
.zoho-redirect-wrap .logo-img .loading-dots .dot:nth-child(1) {
  animation-duration: 2s;
  animation-delay: 0.2s;
}
.zoho-redirect-wrap .logo-img .loading-dots .dot:nth-child(2) {
  animation-duration: 2s;
  animation-delay: 0.4s;
}
.zoho-redirect-wrap .logo-img .loading-dots .dot:nth-child(3) {
  animation-duration: 2s;
  animation-delay: 0.6s;
}
.zoho-redirect-wrap .logo-img .loading-dots .dot:nth-child(4) {
  animation-duration: 2s;
  animation-delay: 0.8s;
}
.zoho-redirect-wrap .logo-img .loading-dots .dot:nth-child(5) {
  animation-duration: 2s;
  animation-delay: 1s;
}
.zoho-redirect-wrap .stock-img {
  display: flex;
  justify-content: center;
  gap: 3rem;
}
.zoho-redirect-wrap .stock-img img {
  width: 45rem;
}

@keyframes zoomInOut {
  0%, 100% {
    transform: scale(0.2);
    background-color: var(--blue-color);
  }
  50% {
    transform: scale(1.2);
    background-color: #cc0905;
  }
}/*# sourceMappingURL=zohoRedirect.css.map */