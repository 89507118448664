.success-modal-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 2rem;
  padding: 3rem;
  font-weight: 400;
  width: 70rem;
  position: relative;
}
.success-modal-wrap .cancel-icon {
  position: absolute;
  right: 2rem;
  top: 0.5rem;
  cursor: pointer;
  padding: 0.6rem;
  margin: 0.6rem;
  border-radius: 50%;
  background-color: rgb(255, 239, 235);
}
.success-modal-wrap img {
  width: 10rem;
}
.success-modal-wrap p {
  font-size: 1.5rem;
}

.paystack-wrap {
  width: 50rem;
}
.paystack-wrap .cancel {
  display: flex;
  justify-content: right;
  cursor: pointer;
  padding: 0.6rem;
  margin: 0.6rem;
  border-radius: 50%;
}/*# sourceMappingURL=successModal.css.map */