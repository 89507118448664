.expense-projection-main-wrap {
  padding: 3rem 4rem;
  display: flex;
  flex-direction: column;
}
.expense-projection-main-wrap .title-arrow-select-box {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.expense-projection-main-wrap .title-arrow-select-box .subtitle-box {
  margin-right: auto;
  display: flex;
  align-items: center;
}
.expense-projection-main-wrap .title-arrow-select-box .subtitle-box > *:not(:last-child) {
  margin-right: 0.5rem;
}
.expense-projection-main-wrap .title-arrow-select-box .subtitle-box .text {
  cursor: pointer;
}
.expense-projection-main-wrap .title-arrow-select-box .subtitle-box .active {
  font-weight: 600;
  color: var(--black-color);
}
.expense-projection-main-wrap .title-arrow-select-box .select-wrap {
  display: flex;
  flex-direction: column;
}
.expense-projection-main-wrap .title-arrow-select-box .select-wrap .label {
  margin-bottom: 0.4rem;
  color: #84919a;
  font-size: 1.3rem;
}
.expense-projection-main-wrap .title-arrow-select-box .select-wrap .select {
  font-size: 1.2rem;
}
.expense-projection-main-wrap .title-arrow-select-box .select-wrap .select > * {
  color: #1d1d18;
}
.expense-projection-main-wrap .title-arrow-select-box .select-wrap .select > *:nth-child(3) {
  border: none !important;
  min-width: 9rem;
  background-color: var(--bg-white-color);
  border-radius: 0.7rem !important;
  border: 0.1rem solid var(--border-color);
}
.expense-projection-main-wrap .title-arrow-select-box .select-wrap .select > *:nth-child(3) > *:nth-child(1) > *:nth-child(1) {
  color: #1d1d18;
}
.expense-projection-main-wrap .total-info-detail-box {
  display: flex;
  align-items: center;
  gap: 4rem;
  margin-bottom: 2rem;
}
.expense-projection-main-wrap .total-info-detail-box .detail-box {
  display: flex;
  flex-direction: column;
}
.expense-projection-main-wrap .total-info-detail-box .detail-box .title {
  font-weight: 500;
  color: #84919a;
}
.expense-projection-main-wrap .total-info-detail-box .detail-box .value {
  font-weight: 800;
  font-size: 2.5rem;
  color: #5b6871;
}
.expense-projection-main-wrap .card-detail-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  grid-gap: 3rem;
  grid-column-gap: 2rem;
}
.expense-projection-main-wrap .card-detail-wrap .card-box {
  padding: 3rem 2rem;
  border-radius: 1rem;
  background-color: var(--bg-white-color);
  box-shadow: var(--box-shadow-one);
  cursor: pointer;
}
.expense-projection-main-wrap .card-detail-wrap .card-box .name {
  margin-bottom: 1.5rem;
  font-size: 1.3rem;
}
.expense-projection-main-wrap .card-detail-wrap .card-box .value {
  font-weight: 600;
  font-size: 2rem;
  color: var(--black-color);
}
.expense-projection-main-wrap .filter-approve-btn-box {
  margin-top: 3rem;
  display: flex;
  align-items: center;
}
.expense-projection-main-wrap .filter-approve-btn-box .notify-bell-box .img-box {
  width: 4rem;
  height: 4rem;
  margin-right: 2rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.expense-projection-main-wrap .filter-approve-btn-box .notify-bell-box .img-box:hover {
  transform: scale(1.05);
}
.expense-projection-main-wrap .filter-approve-btn-box .notify-bell-box .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.expense-projection-main-wrap .filter-approve-btn-box .select-wrap {
  margin-right: auto;
}
.expense-projection-main-wrap .filter-approve-btn-box .select-wrap .select {
  font-size: 1.2rem;
  z-index: 2;
}
.expense-projection-main-wrap .filter-approve-btn-box .select-wrap .select > * {
  color: #1d1d18;
}
.expense-projection-main-wrap .filter-approve-btn-box .select-wrap .select > *:nth-child(3) {
  border: none !important;
  min-width: 9rem;
  background-color: var(--bg-white-color);
  border-radius: 0.7rem !important;
}
.expense-projection-main-wrap .filter-approve-btn-box .select-wrap .select > *:nth-child(3) > *:nth-child(1) > *:nth-child(1) {
  color: #1d1d18;
}
.expense-projection-main-wrap .filter-approve-btn-box .select-wrap .select > *:nth-child(4) {
  width: -moz-max-content;
  width: max-content;
  left: 50%;
  transform: translateX(-50%);
}/*# sourceMappingURL=ExpenseProjectMain.css.map */