.expense-projection-inde-wrap {
  padding: 3rem 4rem;
}
.expense-projection-inde-wrap .title-hide-box {
  display: flex;
  align-items: center;
  margin: 4rem 0rem 2rem 0rem;
}
.expense-projection-inde-wrap .title-hide-box .title {
  font-size: 1.8rem;
  color: var(--black-color);
  font-weight: 700;
}
.expense-projection-inde-wrap .title-hide-box .title span {
  color: #5b6871;
  font-weight: 500;
  margin-left: 2rem;
  font-size: 1.7rem;
}
.expense-projection-inde-wrap .title-hide-box .hide-box {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.expense-projection-inde-wrap .title-hide-box .hide-box .input {
  display: none;
}
.expense-projection-inde-wrap .title-hide-box .hide-box .input:checked ~ .label {
  background-color: var(--blue-color);
}
.expense-projection-inde-wrap .title-hide-box .hide-box .input:checked ~ .label > *:last-child {
  background-color: var(--bg-white-color);
}
.expense-projection-inde-wrap .title-hide-box .hide-box .input:checked ~ .label > *:first-child {
  background-color: var(--blue-color);
}
.expense-projection-inde-wrap .title-hide-box .hide-box .label-text {
  font-size: 1.6rem;
  font-weight: 500;
  margin-right: 1.5rem;
}
.expense-projection-inde-wrap .title-hide-box .hide-box .label {
  width: 5.5rem;
  height: 2.7rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eceded;
  transition: all 0.3s;
  cursor: pointer;
}
.expense-projection-inde-wrap .title-hide-box .hide-box .label span {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  transition: all 0.3s;
}
.expense-projection-inde-wrap .title-hide-box .hide-box .label > *:first-child {
  background-color: var(--bg-white-color);
}
.expense-projection-inde-wrap .title-hide-box .hide-box .label > *:last-child {
  background-color: #eceded;
  margin-left: 0.5rem;
}
.expense-projection-inde-wrap .common-link-wrap {
  display: flex;
  align-items: center;
  background-color: var(--bg-white-color);
  box-shadow: var(--box-shadow-one);
  padding: 0rem 2rem;
  margin-top: 3rem;
  border-bottom: 0.1rem solid var(--border-color);
}
.expense-projection-inde-wrap .common-link-wrap > *:not(:last-child) {
  margin-right: 5rem;
}
.expense-projection-inde-wrap .common-link-wrap .link {
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.expense-projection-inde-wrap .common-link-wrap .link:hover {
  color: var(--black-color);
}
.expense-projection-inde-wrap .common-link-wrap .link span {
  padding: 1rem 2rem;
}
.expense-projection-inde-wrap .common-link-wrap .link-active {
  font-weight: 500;
  color: var(--black-color);
}
.expense-projection-inde-wrap .common-link-wrap .link-active span {
  position: relative;
}
.expense-projection-inde-wrap .common-link-wrap .link-active span::after {
  content: "";
  position: absolute;
  bottom: 0%;
  left: 0%;
  width: 100%;
  height: 0.3rem;
  background-color: var(--blue-color);
}
.expense-projection-inde-wrap .chart-wrap {
  background-color: var(--bg-white-color);
  border-radius: 0.7rem;
  box-shadow: var(--box-shadow-one);
  margin-top: 2.5rem;
  padding: 2rem 3rem 0rem 3rem;
  display: flex;
  flex-direction: column;
  transition: transform 0.5s linear;
  transform-origin: top right;
  transform: scale(0);
}
.expense-projection-inde-wrap .chart-wrap .title-select-box {
  display: flex;
  align-items: center;
}
.expense-projection-inde-wrap .chart-wrap .title-select-box .title {
  font-weight: 500;
  font-size: 1.55rem;
  color: var(--blue-color);
}
.expense-projection-inde-wrap .chart-wrap .title-select-box .select {
  margin-left: auto;
  width: 15rem;
}
.expense-projection-inde-wrap .chart-wrap .title-select-box .select > * {
  color: #1d1d18;
}
.expense-projection-inde-wrap .chart-wrap .title-select-box .select > *:nth-child(3) {
  min-width: 9rem;
  background-color: var(--bg-white-color);
  border: 0.1rem solid var(--border-color);
}
.expense-projection-inde-wrap .chart-wrap .title-select-box .select > *:nth-child(3) > *:nth-child(1) > *:nth-child(1) {
  color: var(--black-color);
}
.expense-projection-inde-wrap .chart-wrap .chart-box {
  height: 35rem;
  margin-top: 4rem;
}
.expense-projection-inde-wrap .chat-wrap-show {
  transform: scale(1);
}
.expense-projection-inde-wrap .table-box {
  margin-top: 2rem;
}/*# sourceMappingURL=ExpenseProjectionIndex.css.map */