.emp-bulk-upload-wrap {
  background-color: var(--bg-white-color);
  padding: 3rem;
  border-radius: 5px;
  justify-content: center;
  .cancel-wrap {
    display: flex;
    gap: 5rem;
  }
  .title {
    font-weight: 600;
    font-size: 2rem;
    color: var(--black-color);
  }
  .text {
    font-size: 1.45rem;
  }
  .cancel-box {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    justify-self: flex-start;
    display: grid;
    place-items: center;
    background: #dde2e4;
    border: 0.15rem solid var(--border-color);
    cursor: pointer;

    .icon {
      color: var(--black-color);
    }
  }
  .span-wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
  .span-wrap-two {
    display: flex;
    gap: 6rem;
  }
  .span-text {
    color: #5b6871;
    opacity: 0.7;
  }
  .span-text-two {
    color: var(--blue-color);
    margin-right: 1rem;
  }
  .btn-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > *:first-child {
      margin-right: 2rem;
      background-color: var(--bg-white-color);
      border: 0.1rem solid var(--blue-color);
      color: var(--blue-color);
    }
    & > *:nth-child(2) {
      color: #c3c7ce !important;
    }
  }
}
