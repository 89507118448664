


.customization-wrap{
    min-height: 100vh;
    display: flex;
    

    .left-box{
        width: 23rem;
        background-color: var(--bg-white-color);
        border-right: .1rem solid var(--border-color);
        display: flex;
        flex-direction: column;
        padding: 3rem 2.5rem;

        .dashboard{
            margin-bottom: 2rem;
            margin-top: 3rem;
            text-transform: capitalize;
        }

        .title{
            color: var(--blue-color);
            cursor: pointer;
            // background-color: red;
            align-self: flex-start;
        }
    }

    .right-box{
        flex: 1;
        padding: 4rem;

        .title-btn-box{
            border-bottom: .1rem solid var(--border-color);
            padding-bottom: 1rem;
            display: flex;
            align-items: center;

            .button-blue-wrap{
                margin-bottom: unset;
            }
            

            .title{
                margin-right: auto;
                font-weight: 700;
                font-size: 1.5rem;
            }
        }

        .table-wrap-body{
            // height: 30rem;
            // background-color: red;

            .form {
                .wrapper {
                  margin-top: 3rem;
                  // background-color: red;
                  // width: 80%;
                  margin-bottom: 4rem;
                  border-bottom: 0.1rem solid var(--border-color);
                  padding-bottom: 3rem;
          
                  .btn-set-up {
                    align-self: flex-start;
                    margin-top: 4rem;
                    background-color: #eceded;
                    padding: 1.2rem 4rem;
                    color: #c3c7ce;
                    text-align: center;
                    cursor: pointer;
                    border: 0.1rem solid var(--border-color);
                    border-radius: 0.4rem;
                    outline: none;
                    transition: all 0.3s;
                    font-weight: 500;
          
                    &:hover {
                      transform: translateY(-0.1rem);
                    }
                  }
          
                  .btn-set-up-active {
                    background-color: var(--blue-color);
                    color: var(--white-color);
                    border: none;
                  }
          
                  .box-title {
                    color: var(--black-color);
                    margin-bottom: 1rem;
                    font-size: 1.5rem;
                  }
          
                  .small-text {
                    font-size: 1.3rem;
                  }
          
                  .form-wrap {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: repeat(2, 1fr);
                    grid-gap: 3rem;
          
                    .form-group {
                      display: flex;
                      flex-direction: column;
          
                      select {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none; /* Remove default arrow */
                        background-image: url("../../../assets/drop-down-icon.svg");
                        background-repeat: no-repeat; /* Add custom arrow */
                        background-position: 95% center;
                        background-size: 2rem;
                      }
          
                      .label {
                        margin-bottom: 0.4rem;
                        display: flex;
                        align-items: center;
                        color: #5b6871;
          
                        span {
                          margin-left: 0.4rem;
                          color: #cc0905;
                          display: inline-block;
                          font-size: 1.7rem;
                          transform: translateY(0.4rem);
                        }
                      }
          
                      .input {
                        border: 0.1rem solid var(--border-color);
                        border-radius: 0.8rem;
                        padding: 1.3rem 1rem;
                        outline: none;
                        width: 100%;
                        font-size: 1.4rem;
                        //   background-color: transparent;
                        font-size: 1.35rem;
                        color: var(--black-color);
                        background-color: var(--bg-white-color);
          
                        &::placeholder {
                          font-size: 1.2rem;
                          text-transform: capitalize;
                        }
          
                        &:focus {
                          border: 0.1rem solid var(--blue-color);
                          outline: none;
                        }
                      }
          
                      .input-active {
                        border: 0.1rem solid var(--blue-color);
                      }
                    }
                  }
          
                  .form-wrap-six {
                    grid-template-rows: repeat(3, 1fr);
                  }
                }
          
                .input-email {
                  width: 90%;
                  padding: 1.2rem 2rem;
                  border: 0.1rem solid var(--border-color);
                  border-radius: 0.5rem;
                  margin-bottom: 2rem;
                  outline: none;
                  color: var(--black-color);
                  background-color: var(--bg-white-color);
          
                  &:focus {
                    outline: none;
                    border: 0.1rem solid var(--border-color);
                  }
          
                  &::placeholder {
                    color: #5b6871;
                  }
                }
          
                .form-check-box {
                  margin-top: 2rem;
                  background-color: #f6f8f9;
                  padding: 2rem 3rem;
                  border: 0.1rem solid var(--border-color);
                  border-radius: 1rem;
          
                  & > *:not(:last-child) {
                    margin-bottom: 2rem;
                  }
          
                  .check-group {
                    display: flex;
                    align-items: center;
          
                    .check-input {
                      display: none;
          
                      &:checked ~ .check-label .check-circle {
                        background-color: var(--blue-color);
          
                        .icon {
                          color: var(--white-color);
                        }
                      }
                    }
          
                    .check-label {
                      display: grid;
                      align-items: center;
                      grid-template-columns: min-content max-content 1rem max-content;
                      // background-color: red;
                      grid-column-gap: 1rem;
                      cursor: pointer;
          
                      span {
                        display: inline-block;
                      }
          
                      .check-circle {
                        width: 2.5rem;
                        height: 2.5rem;
                        border: 0.1rem solid var(--border-color);
                        border-radius: 50%;
                        margin-right: 1.5rem;
                        display: grid;
                        place-items: center;
                        // padding: .6rem;
          
                        .icon {
                          color: #f6f8f9;
                          width: 1.1rem;
                          height: 1.1rem;
                        }
                      }
          
                      .text-one {
                        font-size: 1.3rem;
                      }
          
                      .text-two {
                        color: #84919a;
                        font-size: 1.25rem;
                      }
                    }
                  }
                }
          
                .form-submit {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding: 1.3rem 3rem;
                  border-radius: 0.8rem;
                  background-color: #eceded;
                  border: none;
                  outline: none;
                  cursor: pointer;
                  transition: all 0.3s;
                  color: #c3c7ce;
                  font-weight: 600;
                  margin-top: 4rem;
                  font-size: 1.3rem;
          
                  &:hover {
                    transform: translateY(-0.15rem);
                  }
                }
          
                .form-submit-active {
                  background-color: var(--blue-color);
                  color: var(--white-color);
                }
              }
        }
    }
}