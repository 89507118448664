.common-sort-box-wrap {
  display: grid;
  grid-template-columns: repeat(7, minmax(min-content, max-content));
  grid-column-gap: 1rem;
  margin-bottom: 1.5rem;
  align-items: center;
  justify-content: flex-end;
}
.common-sort-box-wrap .reset-text {
  color: var(--blue-color);
  display: inline-block;
  transform: translateY(50%);
  font-weight: 500;
  cursor: pointer;
}
.common-sort-box-wrap .select-wrap {
  display: flex;
  flex-direction: column;
}
.common-sort-box-wrap .select-wrap .label {
  margin-bottom: 0.4rem;
  color: #84919a;
  font-size: 1.3rem;
}
.common-sort-box-wrap .select-wrap .select {
  font-size: 1.2rem;
  min-width: 13rem;
  background-color: var(--bg-white-color);
  color: #1d1d18;
}
.common-sort-box-wrap .select-wrap .select > *:nth-child(3) {
  background-color: var(--bg-white-colo);
  border-radius: 0.7rem;
}
.common-sort-box-wrap .select-wrap .select > *:nth-child(3) > *:nth-child(1) > *:nth-child(1) {
  color: #1d1d18;
}
.common-sort-box-wrap .select-wrap .input {
  border: 0.1rem solid var(--border-color);
  border-radius: 0.8rem;
  padding: 1.3rem 1rem;
  outline: none;
  width: 100%;
  font-size: 1.4rem;
  font-size: 1.35rem;
  color: var(--black-color);
  background-color: var(--bg-white-color);
}
.common-sort-box-wrap .select-wrap .input::-moz-placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.common-sort-box-wrap .select-wrap .input::placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.common-sort-box-wrap .select-wrap .input:focus {
  border: 0.1rem solid var(--blue-color);
  outline: none;
}
.common-sort-box-wrap .select-wrap .input-active {
  border: 0.1rem solid var(--blue-color);
}
.common-sort-box-wrap .select-wrap-input {
  align-self: flex-end;
}
.common-sort-box-wrap .select-wrap-input .search-input {
  border-radius: 0.7rem;
  border: 0.1rem solid #b0babf;
  padding: 1rem;
  color: var(--black-color);
  background-color: var(--bg-white-color);
}
.common-sort-box-wrap .select-wrap-input .search-input:focus {
  outline: none;
  border: 0.1rem solid #b0babf;
}
.common-sort-box-wrap .select-wrap-input .search-input::-moz-placeholder {
  color: #b0babf;
}
.common-sort-box-wrap .select-wrap-input .search-input::placeholder {
  color: #b0babf;
}/*# sourceMappingURL=CommonSortBox.css.map */