.organization-wrap {
  padding: 4rem;
  display: flex;
  flex-direction: column;

  .title {
    margin-bottom: 0.5rem;
    font-weight: 700;
    font-size: 2.4rem;
    color: var(--black-color);
    // margin-top: 5rem;
  }

  .text {
    color: #5b6871;
    font-size: 1.45rem;
    margin-bottom: 4rem;
  }

  .select-org-wrap {
    display: flex;
    flex-direction: column;

    .btn-set-up {
      align-self: flex-start;
      margin-top: 4rem;
      background-color: #eceded;
      padding: 1.2rem 4rem;
      color: #c3c7ce;
      text-align: center;
      cursor: pointer;
      border: 0.1rem solid var(--border-color);
      border-radius: 0.4rem;
      outline: none;
      transition: all 0.3s;
      font-weight: 500;

      &:hover {
        transform: translateY(-0.1rem);
      }
    }

    .btn-set-up-active {
      background-color: var(--blue-color);
      color: var(--white-color);
      border: none;
    }

    .title {
      margin-bottom: 3rem;
      font-weight: 500;
      font-size: 1.8rem;
      color: var(--black-color);
      margin-top: 0rem;
    }

    .child {
      cursor: pointer;
      display: flex;
      align-items: center;
      background-color: var(--bg-white-color);
      margin-bottom: 3.5rem;
      box-shadow: var(--box-shadow-one);
      width: 65%;
      transition: all 0.3s;
      padding: 4rem;

      .text-box {
        margin-left: 2rem;

        .name {
          margin-bottom: 0.3rem;
          color: #5b6871;
          font-size: 1.6rem;
          font-weight: 500;
        }

        .text {
          margin-bottom: 0rem;
          font-size: 1.4rem;
          color: #84919a;
        }
      }

      .check-box {
        margin-left: auto;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        display: grid;
        place-items: center;
        border: 0.1rem solid var(--border-color);
        // padding: .7rem;

        .icon {
          color: var(--white-color);
          width: 1rem;
          height: 1rem;
        }
      }
    }

    .child-active {
      background-color: #f4faff;
      position: relative;

      & > * {
        position: relative;
        z-index: 3;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        border: 0.1rem solid var(--blue-color);
        // transition: ;
      }

      .check-box {
        border: unset;
        background-color: var(--blue-color);

        .icon {
          color: #ffffff;
        }
      }
    }
  }

  //   settings styles start ----------
  .subtitle-box {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    border-bottom: 0.1rem solid var(--border-color);
    width: 80%;
    padding-bottom: 1.5rem;
    // background-color: red;

    & > *:not(:last-child) {
      margin-right: 0.5rem;
    }

    .text {
      cursor: pointer;
      margin-bottom: unset;
    }

    .active {
      font-weight: 600;
      color: var(--black-color);
    }
  }

  .wrapper {
    // background-color: red;
    width: 80%;
    margin-bottom: 4rem;
    border-bottom: 0.1rem solid var(--border-color);
    padding-bottom: 3rem;

    .btn-set-up {
      align-self: flex-start;
      margin-top: 4rem;
      background-color: #eceded;
      padding: 1.2rem 4rem;
      color: #c3c7ce;
      text-align: center;
      cursor: pointer;
      border: 0.1rem solid var(--border-color);
      border-radius: 0.4rem;
      outline: none;
      transition: all 0.3s;
      font-weight: 500;

      &:hover {
        transform: translateY(-0.1rem);
      }
    }

    .btn-set-up-active {
      background-color: var(--blue-color);
      color: var(--white-color);
      border: none;
    }

    .box-title {
      color: var(--black-color);
      margin-bottom: 1rem;
      font-size: 1.5rem;
    }

    .small-text {
      font-size: 1.3rem;
    }

    .form-wrap {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 3rem;

      .form-group {
        display: flex;
        flex-direction: column;

        select {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none; /* Remove default arrow */
          background-image: url("../../../assets/drop-down-icon.svg");
          background-repeat: no-repeat; /* Add custom arrow */
          background-position: 95% center;
          background-size: 2rem;
        }

        .label {
          margin-bottom: 0.4rem;
          display: flex;
          align-items: center;
          color: #5b6871;

          span {
            margin-left: 0.4rem;
            color: #cc0905;
            display: inline-block;
            font-size: 1.7rem;
            transform: translateY(0.4rem);
          }
        }

        .input {
          border: 0.1rem solid var(--border-color);
          border-radius: 0.8rem;
          padding: 1.3rem 1rem;
          outline: none;
          width: 100%;
          font-size: 1.4rem;
          //   background-color: transparent;
          font-size: 1.35rem;
          color: var(--black-shade-color);
          color: var(--black-color);
          background-color: var(--bg-white-color);

          &::placeholder {
            font-size: 1.2rem;
            text-transform: capitalize;
          }

          &:focus {
            border: 0.1rem solid var(--blue-color);
            outline: none;
          }
        }

        .input-active {
          border: 0.1rem solid var(--blue-color);
        }
      }
    }

    .note-box {
      background-color: rgba(255, 192, 67, 0.1);
      padding: 1rem 2rem;
      border-radius: 0.6rem;
      margin-top: 2.5rem;

      p {
        font-size: 1.2rem;
        color: #596080;

        span {
          font-weight: 700;
          color: var(--black-color);
          margin-right: 0.8rem;
          text-transform: capitalize;
        }
      }

      & > * {
        display: flex;
        align-items: center;

        .img-box {
          margin-right: 1rem;
          transform: translateY(0.1rem);
        }

        p {
          color: #596080;
          font-size: 1.1rem;
          //   color: red;
        }
      }
    }

    .logo-box {
      // background-color: red;
      margin-top: 2rem;

      .logo-title {
        font-size: 1.35rem;
        font-weight: 500;
        color: var(--black-color);
        margin-bottom: 1.5rem;
      }

      .form-group {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;

        .box {
          border: 0.1rem solid var(--border-color);
          border-radius: 0.8rem;
          padding: 1.1rem 1rem;
          width: 23rem;
          background-color: var(--bg-white-color);

          .label {
            color: #5b6871;
            display: flex;
            align-items: center;
            cursor: pointer;
            width: 100%;

            .img {
              margin-right: 1rem;
            }

            span {
              font-size: 1.4rem;
            }
          }

          #imageUpload {
            display: none;
          }
        }

        .logo-display {
          margin-left: 2rem;
          color: var(--blue-color);
        }
      }

      .logo-text {
        font-size: 1.3rem;
        color: #84919a;
      }
    }

    .color-group-box {
      margin-top: 2rem;
      // display: flex;

      .color-title {
        font-size: 1.35rem;
        font-weight: 500;
        color: var(--black-color);
        margin-bottom: 1.5rem;
      }

      .wrap {
        display: flex;
        // width: 70%;
        flex-direction: column;
        // align-items: center;

        .text-box {
          flex: 0 0 50%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-right: 4rem;

          .color-text {
            font-size: 1.35rem;
          }

          & > *:last-child {
            font-size: 1.25rem;
            // margin-top: .3rem;
          }
        }

        .color-box {
          // flex: 1;

          .form-group {
            display: flex;
            border: 0.1rem solid var(--border-color);
            border-radius: 0.4rem;
            overflow: hidden;
            background-color: var(--bg-white-color);
            // padding: 0rem 1rem;
            width: 30rem;
            margin: 2rem 0rem;

            // .form-input-calendar{
            //     border: 0.1rem solid var(--border-color) !;
            //     border-radius: 0.8rem;
            //     padding: 1.1rem 1rem;
            //     background-color: var(--white-color);
            // }

            .input {
              padding: 1.3rem 1rem;
              background-color: transparent;
              flex: 0 0 75%;
              outline: none;
              border: none;
              // align-items: center;
            color: var(--black-color);
            background-color: var(--bg-white-color);

              &:focus {
                border: none;
                outline: none;
              }
            }

            .display-color {
              flex: 0 0 25%;
              // background-color: ;
              // align-self: stretch;
            }
          }
        }
      }
    }

    .financial-title {
      font-size: 1.35rem;
      font-weight: 500;
      color: var(--black-color);
      margin-bottom: 1.5rem;
    }

    .form-wrap-financial {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      grid-gap: 3rem;
      grid-template-rows: unset;
    }
  }

  .form-input-calendar {
    border: 0.1rem solid var(--border-color);
    border-radius: 0.8rem;
    padding: 1.3rem 1rem;
    background-color: var(--bg-white-color);
  }
}
