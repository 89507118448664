.reusable-table-wrap {

  width: 100%;
  min-height: 20rem;
//   background-color: red;
  margin-bottom: 5rem;

  display: flex;
  flex-direction: column;

  .title-btn-box {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    border-bottom: unset;
    margin-bottom: 1rem;

    .title {
      font-size: 1.8rem;
      font-weight: 600;
      color: var(--black-color);
      text-transform: capitalize;
      // background-color: red;
      align-self: flex-start;
      margin-right: auto;

      span {
        font-weight: 300;
        color: #84919a;
      }
    }

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--white-color);
      background-color: var(--blue-color);
      border: none;
      outline: none;
      cursor: pointer;
      padding: 1.2rem 3rem;
      transition: all 0.3s;
      font-weight: 500;
      font-size: 1.3rem;
      border-radius: 0.6rem;
      text-transform: capitalize;

      &:hover {
        transform: translateY(-0.1rem);
      }

      .icon {
        margin-right: 1rem;
      }
    }
  }

  .table-head {
    display: grid;
    align-items: center;
    background-color: var(--white-color);
    padding: 1rem 2rem;
    box-shadow: var(--box-shadow-two);
    border-radius: 0.5rem;

    span {
      background-color: #f6f8f9;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 0.3rem;
      border: 0.1rem solid var(--border-color);
      margin-right: 2rem;
    }

    .head-text {
      font-weight: 600;
      font-size: 1.3rem;
    }
  }

  .table-body {
    // background-color: red;
    display: flex;
    flex-direction: column;

    &>*:not(:last-child){
        border-bottom: .1rem solid var(--border-color);
    }

    .body-wrap {
    //   background-color: blue;
    width: 100%;
      display: grid;
      align-items: center;
      padding: 1.5rem 2rem;
    //   grid-template-columns: min-content repeat();

      span {
        background-color: #f6f8f9;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 0.3rem;
        border: 0.1rem solid var(--border-color);
        margin-right: 2rem;
      }

      .body-text{
        font-size: 1.3rem;
        color: #84919A;
        margin-right: 1.5rem;
        display: flex;
        align-items: center;

        span{
            display: inline-block;
            width: 1rem;
            height: 1rem;
            background-color: currentColor;
            border-radius: 50%;
            margin-right: .6rem;
        }
      }
    }
  }
}
