.dashboard-home-wrap {
  // height: 30rem;
  // background-color: red;
  padding: 3rem 4rem;

  .complete-checklist-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .wrap {
      display: flex;
      align-items: center;
      background-color: var(--bg-white-color);
      padding: 1rem 2.5rem;
      cursor: pointer;
      box-shadow: var(--box-shadow-one);
      border-radius: 0.5rem;

      &:hover .icon {
        transform: translateX(0.2rem);
      }

      .text {
        color: var(--blue-color);
        margin-right: 1rem;
        font-weight: 500;
      }

      .icon {
        color: var(--blue-color);
        width: 2rem;
        height: 2rem;
        border-radius: 0.4rem;
        border: 0.1rem solid var(--blue-color);
        padding: 0.3rem;
        transition: all 0.45s;
      }
    }
  }
}

// budgetAssumptionModal

.budget-assumption-modal-btn {
  //   padding: 4px 12px 12px 22px;
  padding: 1rem 2rem;
  margin-top: -2rem;
  outline: none;
  border: 0.1rem solid var(--blue-color);
  background-color: var(--blue-color);
  border-radius: 0.5rem;
  //   color: var(--blue-color);
  color: #ffff;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    transform: translateY(-0.1rem);
  }
}


