.emp-advanced-search-wrap {
  padding: 22px 27px;
  gap: 27px;
  width: 60rem;
  height: 55rem;
  //   background: #ffffff;
  border-radius: 10px;

  .search-head {
    font-weight: 500;
    font-size: 1.55rem;
    color: var(--black-color);
  }
  .search-input-wrap {
    padding-top: 3rem;

}
.search-input-box {
    display: grid;
    grid-template-columns: 1fr 5fr;
    gap: 3rem;  
    margin-bottom: 3rem;
    .label {
        margin-top:1rem;
    }
  }
  .search-input-box-three {
    display: grid;
    grid-template-columns: 1fr 2.6fr 2fr;
    gap: 3rem;
    margin-bottom: 3rem;
  }
}
