.emp-top-chart {
  display: flex;
  justify-content: space-between;
}

.emp-month-chart-head {
  font-weight: 500;
  font-size: 1.55rem;
  color: var(--blue-color);
}
