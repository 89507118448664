.expense-projection-inde-wrap {
  padding: 3rem 4rem;

  .title-hide-box {
    display: flex;
    align-items: center;
    margin: 4rem 0rem 2rem 0rem;

    .title {
      font-size: 1.8rem;
      color: var(--black-color);
      font-weight: 700;

      span {
        color: #5b6871;
        font-weight: 500;
        margin-left: 2rem;
        font-size: 1.7rem;
      }
    }

    .hide-box {
      margin-left: auto;
      display: flex;
      align-items: center;

      .input {
        display: none;

        &:checked ~ .label {
          background-color: var(--blue-color);

          & > *:last-child {
            background-color: var(--bg-white-color);
          }

          & > *:first-child {
            background-color: var(--blue-color);
          }
        }
      }

      .label-text {
        font-size: 1.6rem;
        font-weight: 500;
        margin-right: 1.5rem;
      }

      .label {
        width: 5.5rem;
        height: 2.7rem;
        border-radius: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #eceded;
        transition: all 0.3s;
        cursor: pointer;

        span {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          transition: all 0.3s;
        }

        & > *:first-child {
          background-color: var(--bg-white-color);
        }

        & > *:last-child {
          background-color: #eceded;
          margin-left: 0.5rem;
        }
      }
    }
  }

  .common-link-wrap {
    display: flex;
    align-items: center;
    background-color: var(--bg-white-color);
    box-shadow: var(--box-shadow-one);
    padding: 0rem 2rem;
    margin-top: 3rem;
    border-bottom: .1rem solid var(--border-color);

    & > *:not(:last-child) {
      margin-right: 5rem;
    }

    .link {
      cursor: pointer;
      display: flex;
      justify-content: center;

      &:hover {
        color: var(--black-color);
      }

      span {
        padding: 1rem 2rem;
      }
    }

    .link-active {
      font-weight: 500;
      color: var(--black-color);

      span {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          bottom: 0%;
          left: 0%;
          width: 100%;
          height: 0.3rem;
          background-color: var(--blue-color);
        }
      }
    }
  }

  .chart-wrap{
    // min-height: 35rem;
    background-color: var(--bg-white-color);
    border-radius: .7rem;
    box-shadow: var(--box-shadow-one);
    margin-top: 2.5rem;
    padding: 2rem 3rem 0rem 3rem ;
    display: flex;
    flex-direction: column;
    transition: transform .5s linear; 
    transform-origin: top right;
    transform: scale(0);

    .title-select-box{
        display: flex;
        align-items: center;

        .title{
            font-weight: 500;
            font-size: 1.55rem;
            color: var(--blue-color);
        }

        .select{
            margin-left: auto;
            width: 15rem;

            & > * {
              // background-color: var(--bg-white-color);
              color: #1d1d18;
              &:nth-child(3) {
                // border: none !important;
                min-width: 9rem;
                background-color: var(--bg-white-color);
                border: 0.1rem solid var(--border-color);
      
                & > *:nth-child(1) {
                  & > *:nth-child(1) {
                    color: var(--black-color);
                  }
                }
              }
            }
        }
    }

    .chart-box{
    //  display: grid;
    // align-self: center;
    //  width: 80rem;
     height: 35rem;
     margin-top: 4rem;

    //  &>*{
    //    width: 100%;
    //    height: 100%;
    //    object-fit: contain;
    //    display: block;
    //  }
    }
  }

  .chat-wrap-show{
    transform: scale(1);
  }

  .table-box{
    margin-top: 2rem;
  }
}
