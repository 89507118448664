.sub-top-items {
  display: flex;
  justify-content: space-around;
}
.sub-top-items .item-group {
  display: flex;
  gap: 1rem;
}
.sub-top-items .item-group .circle {
  background-color: var(--blue-color);
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1.5rem;
  margin-top: 0.3rem;
}
.sub-top-items .item-group .item-box {
  display: flex;
  flex-direction: column;
}
.sub-top-items .item-group .title {
  font-weight: 500;
  margin-bottom: 0.4rem;
}/*# sourceMappingURL=subscription.css.map */