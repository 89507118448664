

.button-blue-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    background-color: var(--blue-color);
    border: none;
    outline: none;
    cursor: pointer;
    padding: 1.2rem 3rem;
    transition: all 0.3s;
    font-weight: 600;
    font-size: 1.2rem;
    border-radius: 0.6rem;
    text-transform: capitalize;
    // margin: 2rem 0rem 0rem 0rem;

    &:hover {
      transform: translateY(-0.1rem);
    }
}