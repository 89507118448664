.actual-table-wrap {
  display: flex;
  flex-direction: column;
  position: relative;
  // background-color: red;
  .title-btn-box {
    // background-color: red;
    display: flex;
    align-items: center;
    border-bottom: unset;
    padding-bottom: unset;
    margin: 1rem 0rem 2rem 0rem;

    .title {
      font-size: 1.8rem;
      font-weight: 600;
      color: var(--black-color);
      text-transform: capitalize;
      // background-color: red;
      // align-self: flex-start;
      margin-right: auto;
      display: flex;
      align-items: center;

      span {
        font-weight: 300;
        color: #84919a;
      }

      .type-select-box {
        margin-left: 1rem;
        position: relative;
        z-index: 10;

        .select {
          background-color: var(--bg-white-color);
          color: #1d1d18;

          & > *:nth-child(3) {
            height: 1rem;
            padding: 0rem 0.5rem;
            font-size: 1.3rem;
            border: 0.1rem solid var(--border-color);
            // width: 3rem;

            background-color: var(--bg-white-color);

            & > *:nth-child(1) {
              & > *:nth-child(1) {
                color: var(--black-color);
              }
            }
          }

          & > * {
            font-size: 1.3rem;
            font-weight: 400;
          }
        }
      }
    }

    .btn-input-box {
      margin-left: auto;
      display: flex;
      align-items: center;
      // display: none;

      & > *:not(:last-child) {
        margin-right: 3rem;
      }

      .search-box {
        background-color: var(--bg-white-color);
        border-radius: 0.9rem;
        display: flex;
        align-items: center;
        border: 0.1rem solid var(--border-color);
        overflow: hidden;
        padding: 0rem 1rem;

        .input {
          border: none;
          flex: 1;
          padding: 1.2rem;
          background-color: var(--bg-white-color);
          color: var(--black-color);

          &:focus {
            outline: none;
            border: none;
          }

          &::placeholder {
            color: #84919a;
          }
        }

        .label {
          flex: 0 0 8%;
          display: grid;
          place-items: center;
          .icon {
            color: #b0babf;
          }
        }
      }

      .select {
        background-color: var(--dark-fade-color);
        border-radius: 0.9rem;
        display: flex;
        align-items: center;
        border: 0.1rem solid var(--border-color);
        overflow: hidden;
        padding: 1.2rem 1rem;
        width: 15rem;
        color: #6e7c87;
        outline: none;
        border: none;
        // display: none;

        &:focus {
          outline: none;
          border: none;
        }
      }

      .select-filter-by {
        width: 15rem;
        color: #6e7c87;
        position: relative;
        z-index: 10;

        background-color: var(--bg-white-color);
        color: #1d1d18;

        & > *:nth-child(3) {
          background-color: var(--dark-fade-color);
          border-radius: 0.9rem;
          border: 0.1rem solid var(--border-color);
          // background-color: var(--bg-white-color);

          & > *:nth-child(1) {
            & > *:nth-child(1) {
              color: var(--black-color);
            }
          }
        }
      }

      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        background-color: var(--blue-color);
        border: none;
        outline: none;
        cursor: pointer;
        padding: 1.2rem 3rem;
        transition: all 0.3s;
        font-weight: 500;
        font-size: 1.2rem;
        border-radius: 0.6rem;
        text-transform: capitalize;

        &:hover {
          transform: translateY(-0.1rem);
        }

        .icon {
          margin-right: 1rem;
          color: currentColor;
        }
      }

      .btn-bulk-upload {
        background-color: var(--bg-white-color);
        border: 0.1rem solid var(--blue-color);
        color: var(--blue-color);
      }
    }
  }

  .table {
    table-layout: auto;
    width: 100%;
    border: none;

    thead {
      tr {
        background-color: var(--bg-white-color);
        border-radius: 0.5rem;
        overflow: hidden;
        box-shadow: var(--box-shadow-two);
        // border-radius: 0.5rem;

        //   &>*

        .action-td {
          text-align: right;
        }

        th {
          //   background-color: var(--white-color);
          padding: 1rem 2rem;
          font-size: 1.2rem;
          text-align: left;
          text-transform: capitalize;
          color: var(--black-color);

          .table-check-wrap-box {
            display: flex;
            align-items: center;
            justify-content: center;

            .table-check-input-row {
              display: none;

              &:checked ~ .table-check-label-input-row {
                background-color: var(--blue-color);
                border: 0.1rem solid var(--blue-color);
              }

              &:checked ~ .table-check-label-input-row .icon {
                visibility: visible;
              }
            }

            .table-check-label-input-row {
              // margin: auto;
              width: 1.5rem;
              height: 1.5rem;
              display: grid;
              place-items: center;
              // background-color: var(--blue-color);
              cursor: pointer;
              transform: translateY(0.7rem);
              background-color: #f6f8f9;
              border-radius: 0.3rem;
              border: 0.1rem solid var(--border-color);

              .icon {
                width: 0.75rem;
                height: 0.75rem;
                fill: #ffffff;
                visibility: hidden;
              }
            }
          }
        }

        & > *:first-child {
          width: 2rem;
          display: grid;
          place-items: center;
          padding-left: 2rem;
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
          margin-right: -2.2rem;

          span {
            background-color: #f6f8f9;
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 0.3rem;
            border: 0.1rem solid var(--border-color);
            display: inline-block;
          }
        }
      }
    }
    tbody {
      tr {
        position: relative;

        & > *:not(:first-child) {
          border-bottom: 0.1rem solid var(--border-color);
        }

        td {
          //   background-color: var(--white-color);
          padding: 1.5rem 2rem;
          font-size: 1.2rem;
          text-align: left;
          font-size: 1.3rem;
          color: #84919a;
          text-transform: capitalize;

          // &>*:nth-child(2){
          //   background-color: var(--black-color);
          // }

          .table-check-wrap-box {
            display: flex;
            align-items: center;
            justify-content: center;

            .table-check-input-row {
              display: none;

              &:checked ~ .table-check-label-input-row {
                background-color: var(--blue-color);
                border: 0.1rem solid var(--blue-color);
              }

              &:checked ~ .table-check-label-input-row .icon {
                visibility: visible;
              }
            }

            .table-check-label-input-row {
              // margin: auto;
              width: 1.5rem;
              height: 1.5rem;
              display: grid;
              place-items: center;
              // background-color: var(--blue-color);
              cursor: pointer;
              transform: translateY(0.7rem);
              background-color: #f6f8f9;
              border-radius: 0.3rem;
              border: 0.1rem solid var(--border-color);

              .icon {
                width: 0.75rem;
                height: 0.75rem;
                fill: #ffffff;
                visibility: hidden;
              }
            }
          }

          .img-data-name {
            width: 3.5rem;
            height: 3.5rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            background-color: var(--blue-color);
            align-items: center;

            & > *:first-child {
              margin-right: 0.1rem;
            }

            span {
              font-size: 1.2rem;
              font-weight: 600;
              color: var(--white-color);
            }
          }

          .user-box {
            display: flex;
            align-items: center;

            .img-box {
              width: 2.5rem;
              height: 2.5rem;
              margin-right: 0.8rem;

              .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                display: block;
              }
            }
          }

          .drop-wrap {
            width: 100%;
            // background-color: red;
            display: flex;
            justify-content: flex-end;
            position: relative;

            .drop-icon {
              color: #b0babf;
              width: 1rem;
              height: 1.5rem;
              cursor: pointer;
            }

            .drop-box {
              position: absolute;
              top: 110%;
              right: 1.5rem;
              width: 17rem;
              // min-height: 25rem;
              border-radius: 0.8rem;
              box-shadow: var(--box-shadow-one);
              border: 0.1rem solid var(--border-color);
              transition: all 0.2s linear;
              background-color: var(--bg-white-color);
              padding: 2rem 3rem;
              transform: scale(0);
              transform-origin: top right;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              & > *:not(:last-child) {
                margin-bottom: 1.5rem;
              }

              .text {
                color: var(--black-color);
                font-size: 1.3rem;
                // font-weight: 500;
                cursor: pointer;
                text-transform: capitalize;
              }
            }

            .drop-box-show {
              transform: scale(1);
            }
          }

          .progress-text-wrap {
            .text {
              margin-top: 0.5rem;
            }

            .progress-wrap {
              width: 100%;
              // margin: 0rem auto;
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(1rem, 1fr));
              grid-column-gap: 0.2rem;
              justify-content: center;
              align-items: center;

              .status-progress-box {
                position: relative;
                cursor: pointer;

                &:hover {
                  .name-box {
                    visibility: visible;
                  }
                }

                .name-box {
                  position: absolute;
                  top: 100%;
                  left: 50%;
                  transform: translateX(-50%) translateY(1rem);
                  background-color: #ffffff;
                  border-radius: 0.7rem;
                  padding: 1rem;
                  box-shadow: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.05);
                  visibility: hidden;
                  transition: visibility 0.1s ease-in-out;

                  &::after {
                    content: "";
                    position: absolute;
                    top: 0%;
                    left: 50%;
                    transform: translate(-100%, -50%);
                    width: 1.5rem;
                    height: 1.5rem;
                    background-color: #ffffff;
                    box-shadow: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.05);
                    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
                  }

                  .name {
                    color: var("--blue-color") !important;
                    font-size: 1.2rem;
                    text-transform: capitalize;
                    white-space: nowrap;
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }

        & > *:first-child {
          width: 2rem;
          display: grid;
          place-items: center;
          padding-left: 2rem;
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
          margin-right: 0.5rem;
          padding-right: 0rem;

          span {
            background-color: #f6f8f9;
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 0.3rem;
            border: 0.1rem solid var(--border-color);
            display: inline-block;
          }
        }

        p {
          display: flex;
          align-items: center;

          span {
            display: inline-block;
            width: 1rem;
            height: 1rem;
            background-color: currentColor;
            border-radius: 50%;
            margin-right: 0.6rem;
          }
        }

        .img-wrap {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          cursor: pointer;

          & > *:not(:first-child) {
            margin-left: -1rem;
          }

          .img-data-name-two {
            background-color: #2a242b;
            // color: #ffffff;
          }

          .img-box {
            width: 3.5rem;
            height: 3.5rem;
            border-radius: 1rem;
            overflow: hidden;
            border: 0.15rem solid var(--border-color);

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              display: block;
            }
          }

          .other {
            width: 3.5rem;
            height: 3.5rem;
            border-radius: 1rem;
            overflow: hidden;
            border: 0.15rem solid var(--border-color);
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--blue-color);
            font-size: 1.2rem;
            background-color: var(--bg-white-color);
            font-weight: 600;

            .icon {
              width: 1rem;
              height: 1rem;
              margin-right: 0.3rem;
            }
          }
        }
      }
    }
  }

  .pagination-box {
    // height: 5rem;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 3rem;

    & > *:not(:last-child) {
      margin-right: 0.7rem;
    }

    // .text {
    //   // margin-right: 1rem;
    // }

    .next-prev-box {
      margin-left: 2rem;
      margin-bottom: 0rem;
      align-items: center;
      display: flex;
      gap: 0.8rem;

      .icon {
        color: var(--white-color);
        width: 2.5rem;
        height: 2.5rem;
        background-color: #b0babf;
        border-radius: 0.5rem;
        display: grid;
        place-items: center;
        padding: 0.5rem;
        transition: all 0.3s;
      }

      // & > *:first-child {
      //   margin-right: 1.2rem;
      // }

      .icon-active {
        background-color: #b0babf;
        cursor: pointer;
        color: #ffffff;

        &:hover {
          transform: scale(1.03) translateX(0.1rem);
          background-color: var(--blue-color);
        }

        & > *:first-child {
          &:hover {
            transform: scale(1.03) translateX(-0.1rem);
          }
        }
      }

      .current {
        background-color: #b0babf;
        cursor: default;

        &:hover {
          transform: unset;
          background-color: #b0babf;
        }
      }

      .current-chi {
        background-color: var(--blue-color);

        &:hover {
          background-color: var(--blue-color);
        }
      }
    }
  }
}
