.preference-setting-wrap {
  padding: 4rem;
}
.preference-setting-wrap .subtitle-box {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  border-bottom: 0.1rem solid var(--border-color);
  width: 80%;
  padding-bottom: 1.5rem;
}
.preference-setting-wrap .subtitle-box > *:not(:last-child) {
  margin-right: 0.5rem;
}
.preference-setting-wrap .subtitle-box .text {
  cursor: pointer;
}
.preference-setting-wrap .subtitle-box .active {
  font-weight: 600;
  color: var(--black-color);
}
.preference-setting-wrap .title-text-box {
  width: 80%;
  padding-bottom: 1.5rem;
}
.preference-setting-wrap .title-text-box .title {
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: var(--black-color);
}
.preference-setting-wrap .title-text-box .text {
  font-size: 1.35rem;
  cursor: pointer;
}

.form-box-preference {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
}
.form-box-preference .title {
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 1.5rem;
  color: var(--black-color);
}
.form-box-preference .wrap {
  background-color: var(--bg-white-color);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow-two);
}
.form-box-preference .wrap .select {
  margin-top: 1rem;
}
.form-box-preference .wrap .select > * {
  background-color: var(--bg-white-color);
  color: #1d1d18;
}
.form-box-preference .wrap .select > *:nth-child(3) {
  border-radius: 0.5rem;
  background-color: var(--bg-white-color);
  border: 0.1rem solid var(--border-color);
}
.form-box-preference .wrap .select > *:nth-child(3) > *:nth-child(1) > *:nth-child(1) {
  color: var(--black-color);
}
.form-box-preference .wrap .delete-btn {
  width: 40%;
  padding: 1.2rem;
  border: 0.1rem solid #cc0905;
  color: #cc0905;
  border-radius: 0.7rem;
  cursor: pointer;
  transition: all 0.3s;
  text-align: center;
  font-weight: 500;
}
.form-box-preference .wrap .delete-btn:hover {
  transform: translateY(-0.13rem);
}
.form-box-preference .wrap .box {
  width: 40%;
}
.form-box-preference .wrap .subtitle {
  font-weight: 500;
  font-size: 1.4rem;
  margin-bottom: -1rem;
  color: var(--black-color);
}
.form-box-preference .wrap .underline {
  height: 0.1rem;
  width: 100%;
  background-color: var(--border-color);
  margin: 2rem 0rem;
}
.form-box-preference .wrap .small-text {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}
.form-box-preference .wrap .check-label-box {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}
.form-box-preference .wrap .check-label-box .check {
  display: none;
}
.form-box-preference .wrap .check-label-box .check:checked ~ .label span {
  background-color: var(--blue-color);
  border: unset;
}
.form-box-preference .wrap .check-label-box .check:checked ~ .label span .icon {
  visibility: visible;
}
.form-box-preference .wrap .check-label-box .label {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  cursor: pointer;
}
.form-box-preference .wrap .check-label-box .label span {
  margin-right: 1.5rem;
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 0.3rem;
  border: 0.1rem solid var(--border-color);
  display: grid;
  place-items: center;
  transition: all 0.3s;
}
.form-box-preference .wrap .check-label-box .label span .icon {
  width: 1rem;
  height: 1rem;
  color: #ffffff;
  visibility: hidden;
}
.form-box-preference .wrap .form-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.3rem 3rem;
  border-radius: 0.8rem;
  background-color: #eceded;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  color: #c3c7ce;
  font-weight: 600;
  margin-top: 4rem;
  font-size: 1.3rem;
  margin-top: 0rem;
}
.form-box-preference .wrap .form-submit:hover {
  transform: translateY(-0.15rem);
}
.form-box-preference .wrap .form-submit-active {
  background-color: var(--blue-color);
  color: var(--white-color);
}
.form-box-preference .wrap .form-group {
  display: flex;
  align-items: center;
  position: relative;
  border: 0.1rem solid var(--border-color);
  border-radius: 0.8rem;
  padding: 0.5rem 1rem;
  width: 40%;
}
.form-box-preference .wrap .form-group > * {
  position: relative;
  z-index: 3;
}
.form-box-preference .wrap .form-group .show-hide {
  display: grid;
  place-items: center;
  cursor: pointer;
}
.form-box-preference .wrap .form-group .show-hide .icon {
  width: 2rem;
  height: 2rem;
  color: #b0babf;
}
.form-box-preference .wrap .form-group .status {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0.1rem solid var(--blue-color);
  border-radius: 0.8rem;
  z-index: 1;
  visibility: hidden;
  transition: visibility 0.3s;
}
.form-box-preference .wrap .form-group .status-active {
  visibility: visible;
}
.form-box-preference .wrap .form-group .placeholder {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 10%;
  font-size: 1.35rem;
  text-transform: capitalize;
  background-color: var(--bg-white-color);
  padding: 0rem 1rem;
  transition: transform 0.5s;
}
.form-box-preference .wrap .form-group .placeholder-active {
  visibility: visible;
  transform: translateY(-50%);
}
.form-box-preference .wrap .form-group .form-label {
  margin-right: 0.2rem;
  flex: 0 0 7%;
  cursor: pointer;
}
.form-box-preference .wrap .form-group .form-label .img-box {
  width: 2.5rem;
  height: 2.5rem;
}
.form-box-preference .wrap .form-group .form-label .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.form-box-preference .wrap .form-group .form-input {
  border: none;
  flex: 1;
  padding: 1rem 1rem;
  outline: none;
  width: 100%;
  background-color: transparent;
  font-size: 1.35rem;
  color: var(--black-shade-color);
}
.form-box-preference .wrap .form-group .form-input::-moz-placeholder {
  font-size: 1.3rem;
  text-transform: capitalize;
}
.form-box-preference .wrap .form-group .form-input::placeholder {
  font-size: 1.3rem;
  text-transform: capitalize;
}
.form-box-preference .wrap .form-group .form-input:focus::-moz-placeholder {
  visibility: hidden;
}
.form-box-preference .wrap .form-group .form-input:focus::placeholder {
  visibility: hidden;
}
.form-box-preference .wrap .form-group .form-input:focus ~ .placeholder {
  visibility: visible;
  transform: translateY(-50%);
}
.form-box-preference .wrap .form-group .phone-input {
  padding: 0.5rem;
  color: #a5b1bd;
}
.form-box-preference .wrap .form-group .input-class {
  border: none;
  color: #a5b1bd;
}
@media only screen and (max-width: 38em) {
  .form-box-preference .wrap .form-group .input-class {
    width: 100%;
  }
}
.form-box-preference .wrap .form-group .drop-down-class {
  background-color: transparent;
  border: unset;
}
.form-box-preference .wrap .update-text {
  color: var(--blue-color);
  margin: 2rem 0rem 2rem 0rem;
  cursor: pointer;
  align-self: flex-start;
}

.update-password-wrap {
  background-color: var(--bg-white-color);
  width: 40rem;
  border-radius: 1rem;
  min-height: 35rem;
  display: flex;
  flex-direction: column;
}
.update-password-wrap .title-box {
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  border-bottom: 0.1rem solid var(--border-color);
}
.update-password-wrap .title-box .title {
  font-size: 1.7rem;
  font-weight: 600;
  color: var(--black-color);
}
.update-password-wrap .title-box .cancel-box {
  margin-left: auto;
  background-color: #ffefeb;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.update-password-wrap .title-box .cancel-box .icon {
  color: #cc0905;
}
.update-password-wrap .content-box {
  flex: 1;
  padding: 1rem 2rem 3rem 2rem;
  background-color: var(--bg-color);
}
.update-password-wrap .content-box .text {
  font-size: 1.3rem;
  margin: 1rem 0rem 0rem 0rem;
  line-height: 1.6rem;
}
.update-password-wrap .content-box > *:not(:last-child) {
  margin-bottom: 2rem;
}
.update-password-wrap .content-box .form-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.3rem 3rem;
  border-radius: 0.8rem;
  background-color: #eceded;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  color: #c3c7ce;
  font-weight: 600;
  margin-top: 4rem;
  font-size: 1.3rem;
  margin-top: 0rem;
}
.update-password-wrap .content-box .form-submit:hover {
  transform: translateY(-0.15rem);
}
.update-password-wrap .content-box .form-submit-active {
  background-color: var(--blue-color);
  color: var(--white-color);
}
.update-password-wrap .content-box .form-group {
  display: flex;
  align-items: center;
  position: relative;
  border: 0.1rem solid var(--border-color);
  border-radius: 0.8rem;
  padding: 0.5rem 1rem;
  width: 100%;
}
.update-password-wrap .content-box .form-group > * {
  position: relative;
  z-index: 3;
}
.update-password-wrap .content-box .form-group .show-hide {
  display: grid;
  place-items: center;
  cursor: pointer;
}
.update-password-wrap .content-box .form-group .show-hide .icon {
  width: 2rem;
  height: 2rem;
  color: #b0babf;
}
.update-password-wrap .content-box .form-group .status {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0.1rem solid var(--blue-color);
  border-radius: 0.8rem;
  z-index: 1;
  visibility: hidden;
  transition: visibility 0.3s;
}
.update-password-wrap .content-box .form-group .status-active {
  visibility: visible;
}
.update-password-wrap .content-box .form-group .placeholder {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 10%;
  font-size: 1.35rem;
  text-transform: capitalize;
  background-color: var(--bg-color);
  padding: 0rem 1rem;
  transition: transform 0.5s;
}
.update-password-wrap .content-box .form-group .placeholder-active {
  visibility: visible;
  transform: translateY(-50%);
}
.update-password-wrap .content-box .form-group .form-label {
  margin-right: 0.2rem;
  flex: 0 0 7%;
  cursor: pointer;
}
.update-password-wrap .content-box .form-group .form-label .img-box {
  width: 2.5rem;
  height: 2.5rem;
}
.update-password-wrap .content-box .form-group .form-label .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.update-password-wrap .content-box .form-group .form-input {
  border: none;
  flex: 1;
  padding: 1rem 1rem;
  outline: none;
  width: 100%;
  background-color: transparent;
  font-size: 1.35rem;
  color: var(--black-shade-color);
}
.update-password-wrap .content-box .form-group .form-input::-moz-placeholder {
  font-size: 1.3rem;
  text-transform: capitalize;
}
.update-password-wrap .content-box .form-group .form-input::placeholder {
  font-size: 1.3rem;
  text-transform: capitalize;
}
.update-password-wrap .content-box .form-group .form-input:focus::-moz-placeholder {
  visibility: hidden;
}
.update-password-wrap .content-box .form-group .form-input:focus::placeholder {
  visibility: hidden;
}
.update-password-wrap .content-box .form-group .form-input:focus ~ .placeholder {
  visibility: visible;
  transform: translateY(-50%);
}
.update-password-wrap .content-box .form-group .phone-input {
  padding: 0.5rem;
  color: #a5b1bd;
}
.update-password-wrap .content-box .form-group .input-class {
  border: none;
  color: #a5b1bd;
}
@media only screen and (max-width: 38em) {
  .update-password-wrap .content-box .form-group .input-class {
    width: 100%;
  }
}
.update-password-wrap .content-box .form-group .drop-down-class {
  background-color: transparent;
  border: unset;
}
.update-password-wrap .btn-box {
  padding: 1.5rem 2rem;
}
.update-password-wrap .btn-box .form-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.3rem 3rem;
  border-radius: 0.8rem;
  background-color: #eceded;
  border: none;
  outline: none;
  transition: all 0.3s;
  color: #c3c7ce;
  font-weight: 600;
  margin-top: 4rem;
  font-size: 1.3rem;
  margin-top: 0rem;
}
.update-password-wrap .btn-box .form-submit-active {
  background-color: var(--blue-color);
  color: var(--white-color);
  cursor: pointer;
}
.update-password-wrap .btn-box .form-submit-active:hover {
  transform: translateY(-0.15rem);
}

.form-group-currency-modal {
  display: flex;
  flex-direction: column;
}
.form-group-currency-modal select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  background-image: url("../../../assets/drop-down-icon.svg");
  background-repeat: no-repeat; /* Add custom arrow */
  background-position: 95% center;
  background-size: 2rem;
}
.form-group-currency-modal .select > * {
  background-color: var(--bg-white-color);
  color: #1d1d18;
}
.form-group-currency-modal .select > *:nth-child(3) {
  border-radius: 0.5rem;
  background-color: var(--bg-white-color);
  border: 0.1rem solid var(--border-color);
}
.form-group-currency-modal .select > *:nth-child(3) > *:nth-child(1) > *:nth-child(1) {
  color: var(--black-color);
}
.form-group-currency-modal .label {
  margin-bottom: 0.4rem;
  display: flex;
  align-items: center;
  color: #5b6871;
}
.form-group-currency-modal .label span {
  margin-left: 0.4rem;
  color: #cc0905;
  display: inline-block;
  font-size: 1.7rem;
  transform: translateY(0.4rem);
}
.form-group-currency-modal .input {
  border: 0.1rem solid var(--border-color);
  border-radius: 0.8rem;
  padding: 1.3rem 1rem;
  outline: none;
  width: 100%;
  font-size: 1.4rem;
  font-size: 1.35rem;
  color: var(--black-shade-color);
  color: var(--black-color);
  background-color: var(--bg-white-color);
}
.form-group-currency-modal .input::-moz-placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.form-group-currency-modal .input::placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.form-group-currency-modal .input:focus {
  border: 0.1rem solid var(--blue-color);
  outline: none;
}
.form-group-currency-modal .input-active {
  border: 0.1rem solid var(--blue-color);
}/*# sourceMappingURL=PreferenceSettings.css.map */