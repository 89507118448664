.session-time-out-modal-wrap {
  width: 45rem;
  background-color: #ffffff;
  display: grid;
  place-items: center;
  padding: 4rem 0rem;
}
.session-time-out-modal-wrap .wrap {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}
.session-time-out-modal-wrap .wrap .title {
  font-size: 2.2rem;
  font-weight: 600;
  color: rgb(50, 51, 72);
  text-align: center;
}
.session-time-out-modal-wrap .wrap .text {
  font-size: 1.35rem;
  color: rgb(94, 99, 102);
  text-align: center;
}/*# sourceMappingURL=SessionTimeoutModal.css.map */