.upgrade-plan-wrap {
  background-color: rgb(239, 242, 252);
  min-width: 70rem;
  height: 60.5rem;
  border-radius: 1rem;
}
.upgrade-plan-wrap .upgrade-plan-container {
  align-items: center;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.upgrade-plan-wrap .upgrade-title {
  font-weight: 500;
  margin-top: 3rem;
  font-size: 2rem;
  text-transform: uppercase;
}
.upgrade-plan-wrap .cancel-icon {
  position: absolute;
  right: 2rem;
  top: 1rem;
  cursor: pointer;
  padding: 1rem;
  border-radius: 50%;
  background-color: rgb(255, 239, 235);
}
.upgrade-plan-wrap .current-plan-wrap {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 2rem;
}
.upgrade-plan-wrap .current-plan-wrap .upgrade-select {
  width: 18rem;
  font-size: 1.3rem;
  z-index: 500;
}
.upgrade-plan-wrap .plan-content-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.upgrade-plan-wrap .plan-content-wrap .upgrade-amount {
  font-weight: 400;
  margin-bottom: 1rem;
}
.upgrade-plan-wrap .plan-content-wrap .select-date-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background: rgb(236, 237, 237);
  font-weight: 400;
  border-radius: 0.5rem;
  width: 25rem;
  justify-self: space-between;
  cursor: pointer;
}
.upgrade-plan-wrap .plan-content-wrap .select-date-wrap .select-date {
  padding: 1rem 1rem;
}
.upgrade-plan-wrap .plan-content-wrap .select-date-wrap .active-select {
  border-radius: 0.5rem;
  background: var(--blue-color);
  color: #fff;
}
.upgrade-plan-wrap .current-plan-container {
  margin-top: 3rem;
  margin-bottom: 4rem;
}
.upgrade-plan-wrap .current-plan-container .plan-details-wrap .plan-details-container {
  height: 15rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.upgrade-plan-wrap .current-plan-container .plan-details-wrap .plan-details-container::-webkit-scrollbar {
  width: 0.5rem;
}
.upgrade-plan-wrap .current-plan-container .plan-details-wrap .plan-details-container::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 10px;
}
.upgrade-plan-wrap .current-plan-container .plan-details-wrap .plan-details-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--blue-color);
}
.upgrade-plan-wrap .current-plan-container .plan-details-wrap .plan-title {
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  align-items: center;
  width: 30rem;
}
.upgrade-plan-wrap .current-plan-container .plan-details-wrap .plan-details-box {
  display: flex;
  align-items: center;
  gap: 1rem;
  text-transform: capitalize;
  margin-top: 1rem;
  width: 30rem;
}
.upgrade-plan-wrap .current-plan-container .plan-details-wrap .plan-details-box .check-box {
  margin-top: 0.5em;
}
.upgrade-plan-wrap .contact-info-wrap {
  display: flex;
  flex-direction: column;
}
.upgrade-plan-wrap .contact-info-wrap .contact-label {
  font-size: 1.35rem;
  font-weight: 400;
  margin-bottom: 0.3rem;
  display: inline-block;
  text-transform: capitalize;
  opacity: 0.8;
}
.upgrade-plan-wrap .contact-info-wrap .contact-input {
  width: 100%;
  background-color: #ffffff;
  outline: none;
  border: none;
  padding: 1.5rem 1rem;
  border-radius: 0.8rem;
}
.upgrade-plan-wrap .contact-info-wrap .contact-input:focus {
  border: 0.08rem solid rgb(0, 75, 255);
}
.upgrade-plan-wrap .contact-info-wrap .contact-input:focus:not(:valid) {
  border: 0.08rem solid red;
}
.upgrade-plan-wrap .contact-info-wrap .contact-input-active {
  border: 0.1rem solid rgb(0, 75, 255);
}
.upgrade-plan-wrap .upgrade-btn {
  display: flex;
  justify-content: center;
  padding-bottom: 3rem;
}/*# sourceMappingURL=upgradePlan.css.map */