.common-link-approval-flow-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 1.5rem 0rem;
}
.common-link-approval-flow-wrap > *:not(:last-child) {
  margin-right: 3rem;
}
.common-link-approval-flow-wrap .item {
  display: flex;
  align-items: center;
  background-color: var(--bg-white-color);
  border: 0.1rem solid var(--border-color);
  border-radius: 1rem;
  padding: 1rem 1.5rem;
  cursor: pointer;
}
.common-link-approval-flow-wrap .item span {
  display: inline-block;
  width: 1.7rem;
  height: 1.7rem;
  position: relative;
  border-radius: 50%;
  border: 0.1rem solid var(--blue-color);
  background-color: var(--bg-white-color);
  margin-right: 1rem;
  transform: scale(0);
  display: none;
}
.common-link-approval-flow-wrap .item span::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 70%;
  border-radius: 50%;
  background-color: var(--blue-color);
}
.common-link-approval-flow-wrap .item .span-active {
  transform: scale(1);
  display: inline-block;
}
.common-link-approval-flow-wrap .item p {
  font-size: 1.25rem;
  font-weight: 500;
}
.common-link-approval-flow-wrap .item-active {
  background-color: #D7EDFF;
}
.common-link-approval-flow-wrap .item-active p {
  color: var(--blue-color);
}/*# sourceMappingURL=CommonLinksApprovalFlow.css.map */