.profile-setting-wrap {
  padding: 4rem;

  .subtitle-box {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    border-bottom: 0.1rem solid var(--border-color);
    width: 80%;
    padding-bottom: 1.5rem;

    & > *:not(:last-child) {
      margin-right: 0.5rem;
    }

    .text {
      cursor: pointer;
    }

    .active {
      font-weight: 600;
      color: var(--black-color);
    }
  }

  .title-text-box {
    width: 80%;  
    padding-bottom: 1.5rem;

    .title {
      font-weight: 700;
      font-size: 1.8rem;
      margin-bottom: 1rem;
      color: var(--black-color);
    }

    .text {
      font-size: 1.35rem;
      cursor: pointer;
    }
  }

  .form {
    .form-submit {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.7rem 3rem;
      border-radius: 0.8rem;
      background-color: #eceded;
      border: none;
      outline: none;
      transition: all 0.3s;
      color: #c3c7ce;
      font-weight: 600;
      margin-top: 4rem;
    }

    .form-submit-active {
      background-color: var(--blue-color);
      color: var(--white-color);
      cursor: pointer;

      &:hover {
        transform: translateY(-0.15rem);
      }
    }

    .form-submit-forget-password {
      margin-top: 2rem;
    }

    .image-display-box {
      // background-color: red;
      display: flex;
      flex-direction: column;

      .text {
        font-size: 1.25rem;
        color: #84919a;
      }

      .wrap {
        width: 20rem;
        height: 17rem;
        position: relative;
        margin-bottom: 2rem;
        // background-color: red;

        .img-display {
          position: absolute;
          top: 0;
          left: 0;
          height: 17rem;
          width: 17rem;
          border-radius: 50%;
          z-index: 1;

          .img {
            object-fit: contain;
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 50%;
          }
        }

        .input {
          display: none;
        }

        .label {
          position: absolute;
          //   background-color: red;
          bottom: -2rem;
          right: 1rem;
          z-index: 2;
          width: 6rem;
          height: 6rem;

          .img-box {
            width: 4rem;
            height: 4rem;
            // padding: unset;
            background-color: var(--blue-color);
            position: relative;
            border-radius: 50%;
            margin-left: 3rem;
            // box-shadow: 0.1rem 0.1rem 8rem 2rem rgba(0, 0, 0, 0.05);

            .img {
              transform: translateY(-1rem) translateX(-2rem);
              // object-fit: contain;
              cursor: pointer;
              width: 200%;
              height: 200%;
              display: block;
              // box-shadow: .1rem .1rem 8rem 2rem rgba(0,0,0, .05);
              // border-radius: 50%;
            }
          }
        }
      }
    }

    .form-group-wrap {
      display: flex;
      flex-direction: column;
      border-bottom: 0.1rem solid var(--border-color);
      padding-bottom: 1.5rem;
      margin-top: 2rem;
      width: 80%;

      .title {
        font-weight: 700;
        font-size: 1.45rem;
        margin-bottom: 1rem;
        color: var(--black-color);
      }

      .wrap {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, 1fr);
        grid-gap: 2rem;

        .form-group {
          display: flex;
          flex-direction: column;

          select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none; /* Remove default arrow */
            background-image: url("../../../assets/drop-down-icon.svg");
            background-repeat: no-repeat; /* Add custom arrow */
            background-position: 95% center;
            background-size: 2rem;
          }

          .select-class {
            background-color: var(--bg-white-color);
            color: #1d1d18;
            & > *:nth-child(3) {
              padding: 0.3rem;
              background-color: var(--bg-white-color);
              border: 0.1rem solid var(--border-color);

              & > *:nth-child(1) {
                & > *:nth-child(1) {
                  color: var(--black-color);
                }
              }
            }
          }

          .label {
            margin-bottom: 0.4rem;
            display: flex;
            align-items: center;
            color: #5b6871;

            span {
              margin-left: 0.4rem;
              color: #cc0905;
              display: inline-block;
              font-size: 1.7rem;
              transform: translateY(0.4rem);
            }
          }

          .input {
            border: 0.1rem solid var(--border-color);
            border-radius: 0.8rem;
            padding: 1.3rem 1rem;
            outline: none;
            width: 100%;
            font-size: 1.4rem;
            //   background-color: transparent;
            font-size: 1.35rem;
            color: var(--black-color);
            background-color: var(--bg-white-color);
            // color: #1d1d18;

            &::placeholder {
              font-size: 1.2rem;
              text-transform: capitalize;
            }

            &:focus {
              border: 0.1rem solid var(--blue-color);
              outline: none;
            }
          }

          .input-active {
            border: 0.1rem solid var(--blue-color);
          }
        }
      }
    }
  }
}

// organization styles  start---------
