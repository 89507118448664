.emp-linechart-container {
  display: block;
  margin-top: 3rem;
  .emp-line-chart {
    box-sizing: border-box;
    width: 100%;
    // height: 452px;
    background: var(--bg-white-color);
    border: 1px solid var(--border-color);
    // box-shadow: 0px 20px 30px var(--bg-white-color);
    border-radius: 6px;
    padding: 2rem;

    .emp-chart-head {
      font-weight: 500;
      font-size: 1.55rem;
      color: var(--black-color);
      margin-top: 5px;
      margin-bottom: 3rem;
    }
    .chart-top-wrap {
      display: flex;
      justify-content: space-between;
    }

    hr {
      margin-top: 3rem;
      border: 0.02px solid var(--border-color);
      // border: none;
    }

    .chart-bottom-wrap {
      display: flex;
      justify-content: space-around;
      margin-top: 1.5rem;

      .chart-pending-text {
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -0.0045em;
        color: #fca549;
      }
      .chart-bottom-digit {
        font-weight: 700;
        font-size: 2rem;
        // line-height: 24px;
        margin-top: 0.2rem;
        // letter-spacing: 1px;
        color: var(--black-color);
      }
      .chart-approve-text {
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -0.0045em;
        color: #07a287;
      }
    }
  }
}
