.sidebar_menu_layout {
  width: 25rem;
  //   height: 100%;
  // background-color: red;
  padding: 2rem 0rem;
  display: flex;
  flex-direction: column;
  //   padding-bottom: 5rem;

  .user-role-box {
    align-self: flex-start;
    margin-top: 1.5rem;
    width: max-content;
    padding: 1rem 1.2rem;
    border-radius: 4rem;
    background-color: rgba(255, 255, 255, 0.3);
    margin-left: 2rem;
    margin-bottom: -1.5rem;

    .text {
      font-size: .9rem;
      font-weight: 700;
      color: #ffffff;
    }
  }

  .logo-box {
    // background-color: red;
    padding: 0rem 2rem;

    .img-box {
      width: 11rem;
      height: 3rem;

      .img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        display: block;
      }
    }

    .img-box-small {
      width: 4rem;
      height: 4rem;
      margin-left: -0.5rem;
    }
  }

  .list-box {
    // background-color: red;
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    color: #ffffff;

    .item {
      margin-bottom: 0.5rem;

      .parent-box {
        display: flex;
        align-items: center;
        padding: 1.3rem 2rem;
        cursor: pointer;
        // border-radius: 0.7rem;
        margin-bottom: 1rem;

        p {
          margin-right: auto;
          color: #ffffff;
          font-size: 1.2rem;
        }
      }

      .children-box {
        max-height: 0;
        overflow-x: hidden;
        overflow-y: hidden;
        transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
        -webkit-transition: max-height 0.5s;
        -moz-transition: max-height 0.5s;
        width: 100%;

        & > *:not(:last-child) {
          margin-bottom: 1.5rem;
        }

        padding-right: 2rem;

        .child {
          display: flex;
          align-items: center;
          margin-left: 2rem;
          padding: 0.7rem 1rem;
          cursor: pointer;
          border-radius: 0.7rem;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 0.3rem;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.3);
            border-radius: 0.7rem;
            transform: scaleY(0);
            transform-origin: bottom;
            transition: transform 0.3s, width 0.3s 0.3s;
          }

          &:hover::after {
            transform: scaleY(1);
            width: 100%;
          }

          .img-box {
            width: 2.5rem;
            height: 2.5rem;
            margin-right: 1rem;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              display: block;
            }
          }

          .child-text {
            font-size: 1.3rem;
          }

          &:hover .child-text-reduce {
            transform: scale(1);
          }

          .child-text-reduce {
            position: absolute;
            bottom: 120%;
            left: -1rem;
            font-size: 0.9rem;
            background-color: rgba(255, 255, 255, 1);
            padding: 0.5rem;
            color: #1954a3;
            border-radius: 0.4rem;
            font-weight: 500;
            box-shadow: var(--box-shadow-two);
            transform: scale(0);
            transition: transform 0.5s;
            transform-origin: center bottom;
          }
        }

        .child-active {
          background-color: rgba(255, 255, 255, 0.3);

          &::after {
            display: none;
          }
          .child-text {
            font-weight: 700;
          }
        }

        .child-reduce {
          width: 4rem;
          padding: 0.6rem 0.4rem;
          display: grid;
          place-items: center;
          margin-left: 1.5rem;

          .img-box {
            margin-right: unset;
          }
        }
      }

      .children-box-show {
        max-height: 20rem;
        transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
      }
    }

    .item-active {
      .parent-box {
        background-color: rgba(255, 255, 255, 0.3);

        // p {
        //   // font-weight: 700;
        // }
      }
    }

    .item-reduce {
      .parent-box {
        padding: 1.2rem 0.5rem;
        p {
          font-size: 0.9rem;
          margin-right: 0.5rem;
        }
      }

      .drop-icon {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  .upgrade-box {
    margin-top: 3rem;
    padding: 2rem;
    align-self: center;
    border-radius: 0.7rem;
    background-color: rgba(255, 255, 255, 0.1);
    width: 80%;
    color: #ffffff;
    font-size: 1.25rem;
    display: flex;
    flex-direction: column;

    .text {
      font-size: 1.2rem;
    }

    .upgrade {
      color: #fca549;
      margin-top: 2rem;
      cursor: pointer;
      align-self: flex-start;
      font-weight: 500;
    }
  }

  .upgrade-wrap-reduce {
    position: relative;

    &:hover .text {
      transform: scale(1);
    }

    .text {
      position: absolute;
      bottom: 90%;
      left: 1%;
      font-size: 0.9rem;
      background-color: rgba(255, 255, 255, 1);
      padding: 0.5rem;
      color: #1954a3;
      border-radius: 0.4rem;
      font-weight: 500;
      box-shadow: var(--box-shadow-two);
      transform: scale(0);
      transition: transform 0.5s;
      transform-origin: center bottom;
    }

    .upgrade-box-reduce {
      width: 4rem;
      height: 4rem;
      color: #fca549;
      margin: 3rem 0rem 0rem 1.5rem;
      border-radius: 0.7rem;
      background-color: rgba(255, 255, 255, 0.3);
      padding: 1rem;
      cursor: pointer;
    }
  }

  .switch-btn {
    display: flex;
    align-items: center;
    align-self: center;
    background-color: var(--bg-white-color);
    padding: 0.6rem 1rem;
    color: #1954a3;
    border: none;
    outline: none;
    border-radius: 2rem;
    margin-top: 3rem;
    cursor: pointer;
    color: var(--black-color);

    &:hover .icon {
      transform: translateX(0.5rem);
    }

    .text {
      margin-right: 1rem;
      font-size: 1.2rem;
    }

    .icon {
      width: 1rem;
      height: 1rem;
      transition: all 0.3s;
    }
  }

  .switch-btn-wrap-reduce {
    position: relative;

    .switch-btn-reduce {
      margin-left: 2rem;
      width: 3rem;
      height: 3rem;
      color: #1954a3;
      margin-top: 3rem;
      cursor: pointer;
      background-color: var(--bg-white-color);
      padding: 0.6rem;
      border-radius: 0.8rem;
    }

    &:hover .text {
      transform: scale(1);
    }

    .text {
      position: absolute;
      bottom: 80%;
      left: 1.5%;
      font-size: 0.9rem;
      background-color: rgba(255, 255, 255, 1);
      padding: 0.5rem;
      color: #1954a3;
      border-radius: 0.4rem;
      font-weight: 500;
      box-shadow: var(--box-shadow-two);
      transform: scale(0);
      transition: transform 0.5s;
      transform-origin: center bottom;
      width: 5.5rem;
    }
  }
}
