
.auth-form-wrap {
  // background-color: red;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  min-width: 35rem;

  // .dna-wrapper{
  //   &>*{
  //     color: red !important;
  //     background-color: red !important;
  //   }
  // }

  & > *:not(:last-child) {
    margin-bottom: 3rem;
  }

  .two-form-group-box {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem , 1fr));
    grid-column-gap: 2rem;
  }

  .form-group {
    display: flex;
    align-items: center;
    position: relative;
    border: 0.1rem solid #dde2e4;
    border-radius: 0.8rem;
    padding: 0.5rem 1rem;



    .error{
      position: absolute;
      color: #CC0905; 
      font-size: 1.2rem;
      top: 100%;
      left: 0;
      text-transform: capitalize;
    }

    & > * {
      position: relative;
      z-index: 3;
    }

    .show-hide {
      display: grid;
      place-items: center;
      cursor: pointer;

      .icon {
        width: 2rem;
        height: 2rem;
        color: #b0babf;
      }
    }

    .status {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0.1rem solid var(--blue-color);
      border-radius: 0.8rem;
      z-index: 1;
      visibility: hidden;
      transition: visibility 0.3s;
    }

    .status-active {
      visibility: visible;
    }

    .placeholder {
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 10%;
      //   color: #a5b1bd;
      font-size: 1.35rem;
      text-transform: capitalize;
      background-color: var(--bg-white-color);
      padding: 0rem 1rem;
      transition: transform 0.5s;
    }

    .placeholder-active {
      visibility: visible;
      transform: translateY(-50%);
    }

    .form-label {
      margin-right: 0.2rem;
      flex: 0 0 7%;
      cursor: pointer;

      .img-box {
        width: 2.5rem;
        height: 2.5rem;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          display: block;
        }
      }
    }

    .form-input {
      border: none;
      flex: 1;
      padding: 1rem 1rem;
      outline: none;
      width: 100%;
      background-color: transparent;
      font-size: 1.35rem;
      color: var(--black-shade-color);
    //   height: 90%;
    //   background-color: red;

      //   &:-webkit-autofill{
      //     background-color: transparent;
      //   }

      &::placeholder {
        // color: #a5b1bd;
        font-size: 1.3rem;
        text-transform: capitalize;
        // transition: all .3s;
      }

      &:focus {
        &::placeholder {
          visibility: hidden;
        }
      }

      &:focus ~ .placeholder {
        visibility: visible;
        transform: translateY(-50%);
      }
    }

    .phone-input {
      // border: none;
      padding: 0.5rem;
      color: #a5b1bd;
    }

    .input-class {
      border: none;
      // background-color:red;
      color: #a5b1bd;

      @media only screen and (max-width: 38em) {
        width: 100%;
      }
    }

    .drop-down-class {
      background-color: transparent;
      border: unset;
    }

    //   pin field styles
    .pin-field {
      // background-color: var(--white-color);
      border-radius: .7rem;
      font-size: 2rem;
      height: 5rem;
      outline: none;
      text-align: center;
      transition-duration: 250ms;
      transition-property: background, color, border, box-shadow, transform;
      width: 5rem;
      border: 0.07rem solid #a5b1bd;

      @media only screen and (max-width: 24em) {
        height: 4rem;
      }
    }

    .pin-field:not(:last-child) {
      margin-right: 1.5rem;
    }

    .pin-field:focus {
      border-color: #0069ff;
      outline: none;
      transform: scale(1.05);
    }

    .pin-field:invalid {
      animation: shake 3 linear 75ms;
      border-color: rgb(220, 53, 69);
      box-shadow: 0 0 0.25rem rgba(220, 53, 69, 0.5);
    }

    .pin-field:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    .pin-field-complete {
      border: .2rem solid #0069ff !important;
    }
  }

  .form-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.7rem 3rem;
    border-radius: .8rem;
    background-color: #eceded;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
    color: #C3C7CE;
    font-weight: 600;
    margin-top: 4rem;

    &:hover {
      transform: translateY(-0.15rem);
    }
  }

  .form-submit-active{
    background-color: var(--blue-color);
    color: var(--white-color);
  }

  .form-submit-forget-password{
    margin-top: 2rem;
  }
}

// auto fill removal

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}