.zoho-redirect-wrap {
  //   background-color: #f8fafc;

  .zoho-redirect-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // place-items: center;
    background-color: #f8fafc;
    text-align: center;
    padding: 3rem;
    padding-bottom: 4rem;
    gap: 6rem;
  }
  .sync-text {
    margin-top: 3rem;
    font-size: 3.2rem;
    font-weight: 400;
  }
  .wait-text {
    font-size: 2.2rem;
    font-weight: 300;
    color: #5b6871;
  }
  .logo-img {
    display: flex;
    justify-content: center;
    gap: 5rem;
    place-items: center;
    img {
      width: 17rem;
    }
    .loading-dots {
      display: flex;
      place-items: center;
      justify-content: center;
      gap: 4rem;
      .dot {
        width: 20px;
        height: 20px;
        background-color: #cc0905;
        border-radius: 50%;
        margin: 0 5px;
        animation: zoomInOut 2s infinite alternate;
      }
      .dot:nth-child(1) {
        animation-duration: 2s;
        animation-delay: 0.2s;
      }

      .dot:nth-child(2) {
        animation-duration: 2s;
        animation-delay: 0.4s;
      }

      .dot:nth-child(3) {
        animation-duration: 2s;
        animation-delay: 0.6s;
      }

      .dot:nth-child(4) {
        animation-duration: 2s;
        animation-delay: 0.8s;
      }

      .dot:nth-child(5) {
        animation-duration: 2s;
        animation-delay: 1s;
      }
    }
  }
  .stock-img {
    display: flex;
    justify-content: center;
    gap: 3rem;
    img {
      width: 45rem;
    }
  }
}

@keyframes zoomInOut {
  0%,
  100% {
    transform: scale(0.2);
    background-color: var(--blue-color);
  }
  50% {
    transform: scale(1.2);
    background-color: #cc0905;
  }
}
