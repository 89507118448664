.admin-settings-profile-transfer-wrap {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem;

  @media only screen and (max-width: 75em) {
    width: 100%;
  }

  .subtitle-box {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    padding-bottom: 1.5rem;
    border-bottom: 0.1rem solid #dde2e4;

    & > *:not(:last-child) {
      margin-right: 0.5rem;
    }

    .text {
      cursor: pointer;
      color: var(--black-color);
    }

    .active {
      font-weight: 600;
      color: var(--black-color);
    }
  }

  .page-title-box {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .title {
      font-weight: 700;
      color: #1d1d18;
    }

    .text {
      color: #5b6871;
      font-size: 1.25rem;
    }
  }

  .two-grid-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    margin-top: 3rem;

  
  }

  .form-group {
    display: flex;
    flex-direction: column;

    .select-wrap {
      //  display: none;
      & > *:nth-child(3) {
        min-width: 9rem;
        background-color: var(--bg-white-color);
        border-radius: 0.7rem !important;
        border: 0.1rem solid var(--border-color);

        & > *:first-child {
          & > *:first-child {
            color: #6e7c87 !important;
          }
        }
      }
    }

    .select-wrap-active {
      & > *:nth-child(3) {
        // border: none !important;
        min-width: 9rem;
        background-color: var(--bg-white-color);
        border-radius: 0.7rem !important;
        border: 0.1rem solid var(--border-color);

        & > *:first-child {
          & > *:first-child {
            color: #1d1d18 !important;
          }
        }
      }
    }

    .select-wrap-multi {
      & > *:nth-child(3) {
        & > * {
          & > *:not(:last-child) {
            // color: #000000 !important;
            & > * {
              color: #ffffff !important;
            }
          }
        }
      }
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; /* Remove default arrow */
      background-image: url("../../../../assets/drop-down-icon.svg");
      background-repeat: no-repeat; /* Add custom arrow */
      background-position: 95% center;
      background-size: 2rem;
    }

    .label {
      margin-bottom: 0.4rem;
      display: flex;
      align-items: center;
      color: #5b6871;

      span {
        margin-left: 0.4rem;
        color: #cc0905;
        display: inline-block;
        font-size: 1.7rem;
        transform: translateY(0.4rem);
      }
    }

    .input {
      border: 0.1rem solid var(--border-color);
      border-radius: 0.8rem;
      padding: 1.3rem 1rem;
      outline: none;
      width: 100%;
      font-size: 1.4rem;
      // text-transform: capitalize;
      font-size: 1.35rem;
      color: var(--black-shade-color);
      color: var(--black-color);
      background-color: var(--bg-white-color);

      &::placeholder {
        font-size: 1.2rem;
        text-transform: capitalize;
      }

      &:focus {
        border: 0.1rem solid var(--blue-color);
        outline: none;
      }
    }

    .input-active {
      border: 0.1rem solid var(--blue-color);
    }


    .disable{
        background-color: #F8F9FF;
        border: .1rem solid #DDE2E4;
    }
  }

  .four-grid-box{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 2rem;
    margin-top: 3rem;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 0.1rem solid #dde2e4;
  }

  .text-btn-box{
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin: 4rem 0rem 2.5rem 0rem;

    &>*:last-child{
        border: .1rem solid var(--blue-color);
        display: flex;
        align-items: center;
        gap: .6rem;
        justify-content: center;
        
        .icon{
            fill: var(--blue-color);
        }

        span{
            color: var(--blue-color);
        }
    }
  }

  .form-group-hide{
    max-height: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
    -webkit-transition: max-height 0.5s;
    -moz-transition: max-height 0.5s;   
    width: 100%;
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
}

.form-group-show{
    overflow-x: visible;
    overflow-y: visible;
    max-height: 20rem;
    transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
}
}

.profile-transfer-confirm-modal-wrap{
    width: 55rem;
    min-height: 25rem;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 3rem;
    border-radius: 1rem;

    .title{
        font-size: 1.8rem;
        font-weight: 700;
        color: #1d1d18;
        margin-bottom: 3rem;
    }

    .text{
        color: #5b6871;
        font-size: 1.45rem;
        line-height: 1.95rem;

        span{
            font-weight: 700;
        }
    }

    .two-btn-box{
        margin-top: auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 2rem;

        &>*:first-child{
            border: .1rem solid #cc0905;
            color: #cc0905;
        }
    }

  
}

.success-transfer-profile-modal-wrap{
    height: 45rem;
    width: 40rem;
    background-color: #ffffff;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding: 3rem;

    .img-box{
        width: 15rem;
        height: 15rem;

        .img{
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    &>*:last-child{
        color: #cc0905;
    }

  
    .title{
        font-size: 1.8rem;
        font-weight: 700;
        color: #1d1d18;
        // margin-bottom: 3rem;
        text-align: center;
    }

    .text{
        color: #5b6871;
        font-size: 1.45rem;
        line-height: 1.95rem;
        text-align: center;

        span{
            font-weight: 700;
        }
    }
}
