.common-sort-box-wrap {
  display: grid;
  grid-template-columns: repeat(7, minmax(min-content, max-content));
  grid-column-gap: 1rem;
  margin-bottom: 1.5rem;
  align-items: center;
  justify-content: flex-end;

  .reset-text{
    color: var(--blue-color);
    display: inline-block;
    transform: translateY(50%);
    font-weight: 500;
    cursor: pointer;
  }

  .select-wrap {
    display: flex;
    flex-direction: column;

    .label {
      margin-bottom: 0.4rem;
      color: #84919a;
      font-size: 1.3rem;
    }

    .select {
      font-size: 1.2rem;
      // color: #B0BABF;
      min-width: 13rem;
      background-color: var(--bg-white-color);
      color: #1d1d18;

      & > *:nth-child(3) {
        background-color: var(--bg-white-colo);
        border-radius: 0.7rem;
        // color: ;

        & > *:nth-child(1) {
          & > *:nth-child(1) {
            color: #1d1d18;
          }
        }
      }
    }
    .input {
      border: 0.1rem solid var(--border-color);
      border-radius: 0.8rem;
      padding: 1.3rem 1rem;
      outline: none;
      width: 100%;
      font-size: 1.4rem;
      //   background-color: transparent;
      font-size: 1.35rem;
      color: var(--black-color);
      background-color: var(--bg-white-color);

      &::placeholder {
        font-size: 1.2rem;
        text-transform: capitalize;
      }

      &:focus {
        border: 0.1rem solid var(--blue-color);
        outline: none;
      }
    }

    .input-active {
      border: 0.1rem solid var(--blue-color);
    }
  }

  .select-wrap-input {
    align-self: flex-end;

    .search-input {
      border-radius: 0.7rem;
      border: 0.1rem solid #b0babf;
      padding: 1rem;
      color: var(--black-color);
      background-color: var(--bg-white-color);

      &:focus {
        outline: none;
        border: 0.1rem solid #b0babf;
      }

      &::placeholder {
        color: #b0babf;
      }
    }
  }
}
