.consolidate-right-box {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  align-items: center;
  margin-top: -4.7rem;
  margin-bottom: 3rem;
  .label {
    // color: #84919a;
    // font-size: 1.2rem;
    // margin-left: -3rem;
    font-size: 1.3rem;
    font-weight: 400;
  }
  .input {
    border: 0.1rem solid #dde2e4;
    border-radius: 0.8rem;
    padding: 1.3rem 1rem;
    outline: none;
    width: 100%;
    // height: 4rem;
    // font-size: 1.4rem;
    //   background-color: transparent;
    font-size: 1.35rem;
    color: var(--black-shade-color);

    &::placeholder {
      font-size: 1.2rem;
      text-transform: capitalize;
    }
  }
  .input-active {
    border: 0.1rem solid var(--blue-color);
  }
  .select-wrap {
    // transform: translateY(.1rem);
    // text-transform: capitalize;
    // display: flex;
    // align-items: center;
    // gap: 1rem;
    // justify-content: right;
    // margin-right: 1rem;
    // margin-bottom: -3rem;

    // width: 15rem;
    // color: #6e7c87;
    // position: relative;
    // z-index: 10;

    & > *:nth-child(3) {
      // background-color: #e5e5e5;
      border-radius: 0.9rem;
      border: 0.1rem solid var(--border-color);
    }
    & > * {
      font-size: 1.3rem;
      font-weight: 400;
    }

    & > *:nth-child(3) {
      // height: 4.3rem;
      padding: 0.3rem;
      border-radius: 0.7rem;

      & > * {
        & > *:not(:last-child) {
          // color: #000000 !important;
          & > * {
            color: #ffffff !important;
          }
        }
      }
    }
  }

  .select-wrap-active {
    & > *:nth-child(3) {
      border: 0.1rem solid var(--blue-color);

      & > * {
        & > *:not(:last-child) {
          // color: #000000 !important;
          & > * {
            color: #ffffff !important;
          }
        }
      }
    }
  }
}

.emp-sub-report {
  display: flex;
  align-items: center;
  margin-top: 3rem;
  .per-page-select {
    z-index: 10;

    margin-left: -1.2rem;
    font-weight: 400;
    font-size: 1.3rem;
  }
}
// .emp-consolidate-wrap {
// display: flex;
// // grid-template-columns: 3fr 1fr;
// // gap: 34rem;
// margin-bottom: ;

// .select {
//   font-size: 1.2rem;
//   width: 100%;
//   // color: #B0BABF;

//   & > * {
//     border: unset;
//     border-radius: 0.7rem !important;
//     border: 0.1rem solid #b0babf;

//   }
// }
// .select-active {
//   border: 0.1rem solid var(--blue-color);
// }

// }

.emp-export-btn {
  background-color: var(--white-color);
  border-radius: 6px;
  border: 1px solid var(--border-color);
  color: #84919a;
  padding: 1rem;
  width: 100%;
  cursor: pointer;

  .export-icon {
    color: #84919a;
    // position: relative;
    // top: 2px;
    // margin-right: 3px;
    transition: all 0.3s;
  }
  &:hover .export-icon {
    transform: translateY(-0.1rem);
  }
}
.sub-right-box {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  // margin-bottom: -3rem;

  .select {
    position: relative;
    z-index: 10;
  }

  .title {
    font-weight: 700;
    font-size: 1.8rem;
    // margin-bottom: 1rem;
    color: #1d1d18;
    margin-right: 3rem;
  }

  .emp-btn-wrap {
    margin-left: auto;
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-top: 3rem;
    .emp-search-box {
      width: 25rem;
      // align-items: center;
      background-color: var(--white-color);
      border-radius: 0.9rem;
      display: flex;
      // align-items: center;
      border: 0.1rem solid var(--border-color);
      // overflow: hidden;
      padding: 0rem 1rem;

      .input {
        border: none;
        flex: 1;
        padding: 1.2rem;

        &:focus {
          outline: none;
          border: none;
        }

        &::placeholder {
          color: #84919a;
        }
      }
      .label {
        flex: 0 0 8%;
        display: grid;
        place-items: center;
        .icon {
          color: #b0babf;
          position: relative;
          right: 1rem;
        }
      }
    }
  }

  .emp-print-btn {
    background-color: var(--white-color);
    border-radius: 6px;
    border: 2px solid var(--border-color);
    color: #84919a;
    padding: 2px;
    cursor: pointer;
    width: 100%;

    .print-icon {
      color: #84919a;
      position: relative;
      top: 3px;
      margin-left: 3px;
      transition: all 0.3s;
    }
    &:hover .print-icon {
      transform: translateY(0.1rem);
    }
  }
}
