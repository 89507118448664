.emp-cancel-wrap {
  // display: flex;
  // justify-content: right;
  // position: relative;
  // left: 4rem;
}

.employee-remove-btn {
  display: flex;
  justify-content: center;
  // position: relative;
  margin-top: 3.3rem;
  // bottom: 5rem;
  width: 2.5rem;
  margin-left: -3rem;
  height: 2.5rem;
  border-radius: 50%;
  place-items: center;
  border: 0.15rem solid #cc0905;
  cursor: pointer;

  .icon {
    color: #cc0905;
  }
}

// minus-btn-wrap
// .emp-minus-wrap {
//   margin-bottom: -11rem !important;
// }
.emp-add-months-wrap {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  // cursor: pointer;

  .icon {
  cursor: pointer;
  }

  .text {
  cursor: pointer;
    font-weight: 500;
    font-size: 1.3rem;
  }
}
