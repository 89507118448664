.no-table-content-wrap {
  height: 30rem;
  display: grid;
  place-items: center;
}
.no-table-content-wrap .content-box {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
}
.no-table-content-wrap .content-box .img-box {
  width: 12rem;
  height: 12rem;
  margin-bottom: 1rem;
}
.no-table-content-wrap .content-box .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
}
.no-table-content-wrap .content-box .text {
  font-size: 1.7rem;
  text-transform: capitalize;
  color: #84919A;
}/*# sourceMappingURL=NoTableContent.css.map */