.sub-top-items {
    // margin-top: 15rem;
    display: flex;
    justify-content: space-around;
  
    .item-group {
      display: flex;
      gap: 1rem;
      // padding: 0 12rem;
  
      .circle {
        background-color: var(--blue-color);
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 1.5rem;
        margin-top: 0.3rem;
      }
      .item-box {
        display: flex;
        flex-direction: column;
      }
      .title {
        font-weight: 500;
        margin-bottom: 0.4rem;
      }
    }
  }
  // .sub-pricing-content-wrap {
  //   //   margin-top: 5rem;
  // }
  