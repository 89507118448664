.dashboard-layout-wrap {
  width: 100vw;
  height: 100vh;
  // background-color: red;
  position: relative;

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 25rem;
    background-color: var(--blue-color);
    height: 100vh;
    overflow-y: scroll;
    padding-bottom: 6rem;
    // background-color: red;
    overflow-x: hidden;
    transition: width .5s;
    z-index: 3;


      //    &::-webkit-scrollbar {
      //       display: none;     
      //  } 

          
      -ms-overflow-style: none;
      scrollbar-width: none;  
      
      
  
      &::-webkit-scrollbar {
          width: .5rem;
      }
       
      &::-webkit-scrollbar-track {
          background-color: var(--bg-color);
          -webkit-border-radius: 10px;
          border-radius: 10px;
      }
      
      &::-webkit-scrollbar-thumb {
          -webkit-border-radius: 10px;
          border-radius: 10px;
          background:  #ccc; 
      }
  }

  .sidebar-reduce{
    // position: absolute;
    width: 7rem;
    // z-index: 3;
  }

  .header {
    position: fixed;
    top: 0;
    right: 0;
    width: calc(100% - 25rem);
    height: 6rem;
    background-color: var(--bg-white-color);
    box-shadow: .1rem .1rem 1rem rgba(0,0,0, .1);
    z-index: 3;
    transition: width .5s;
  }

  .header-reduce{
    width: calc(100% - 7rem);
    z-index: 2;
  }

  .main {
    position: absolute;
    top: 6rem;
    right: 0;
    width: calc(100% - 25rem);
    background-color: var(--bg-color);
    height: calc(100vh - 6rem);
    z-index: 1;
    overflow-y: scroll;
    transition: width .5s;

    &::-webkit-scrollbar:horizontal {
      display: none;
    }
  
    /* width */
  &::-webkit-scrollbar {
    width: .6rem;
  }
  
  /* Track */
  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: none !important;
    background-color: transparent !important;
  }
  
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--blue-color);
    border-radius: 30rem;
  }
  
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: var(--blue-color);
  }
  }

  .main-reduce{
    width: calc(100% - 7rem);
    z-index: 1;
  }
}
