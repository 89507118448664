.employee-draft-container {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
  text-align: center;
}

/* .employee-draft-container-two { */

  /* position: absolute;
  top: 20rem;
  left: 50%;
  transform: translateX(-50%); */
/* } */

.emp-draft-btn {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
  /* background-color: blue; */
}
