.session-time-out-modal-wrap {
  width: 45rem;
  background-color: #ffffff;
  display: grid;
  place-items: center;
  padding: 4rem 0rem;

  .wrap {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;

    .title {
      font-size: 2.2rem;
      font-weight: 600;
      color: rgba(50, 51, 72, 1);
      text-align: center;
    }

    .text {
      font-size: 1.35rem;
      color: rgba(94, 99, 102, 1);
      text-align: center;
    }
  }
}
