.employee-auth-password {
  .employee-password {
    // margin-right: 3rem;
    &::placeholder {
      // margin-left: 10rem !important ;
      position: relative;
      left: 0.2rem;
    }
  }
  .employee-lock-img {
    position: relative;
    top: 3.7rem;
    left: 1rem;
  }
  .password-label {
    position: relative;
    bottom: 4rem;
  }
  .employee-password-input {
    margin-bottom: 5rem;
    margin-top: -6rem;
  }

  .employee-input-container {
    position: relative;
    bottom: 6.8rem;

     .show-hide {
      // display: grid;  
      // place-items: center;
      cursor: pointer;

      .icon {
        width: 2rem;
        height: 2rem;
        color: #b0babf;
      }
    }
  }

  .icon {
    position: relative;
    // left: 50vh;
    // left: 37rem;
    left: 90%;
    bottom: 3rem;
    cursor: pointer;
  }

  .note-box {
    background-color: rgba(255, 192, 67, 0.1);
    padding: 1rem 2rem;
    border-radius: 0.6rem;
    margin-top: -5rem !important;
  }
  .form-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.7rem 3rem;
    border-radius: 0.8rem;
    background-color: #eceded;
    border: none;
    outline: none;
    transition: all 0.3s;
    color: #c3c7ce;
    font-weight: 600;
    margin-top: 4rem;
  }

  .form-submit-active {
    background-color: var(--blue-color);
    color: var(--white-color);
    cursor: pointer;

    &:hover {
      transform: translateY(-0.15rem);
    }
  }
  .error {
    position: absolute;
    color: #cc0905;
    font-size: 1.2rem;
    margin-top:-1rem;
    // margin-top:unset;
    top: 100%;
    left: 0;
    text-transform: capitalize;
  }
}
