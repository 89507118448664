.emp-top-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
  margin-top: 5rem;

  // grid-column: 1fr;
  .card-wrap {
    padding: 2rem;
    width: 100%;
    margin-top: 1rem;
    // height: 119px;
    background: var(--bg-white-color);
    // box-shadow: 10px 8px 50px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
  }
  .top-text-wrap {
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin-top: 2rem;
  }
  .approve-digit {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-top: 2px;
    // letter-spacing: 1px;
    color: #07a287;
  }
  .pending-digit {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-top: 2px;
    // letter-spacing: -0.014em;
    // letter-spacing: 1px;
    color: #fca549;
  }
}

// home sort box btn

.emp-homesort-btn {
  padding: 1.2rem 3rem;
  border: none;
  font-size: 1.3rem;
  font-size: 500;
  background-color: #fff;
  border: 0.1rem solid var(--blue-color);
  // color: #c3c7ce;
  color: var(--blue-color);
  border-radius: 0.6rem;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    transform: translateY(-0.1rem);
  }
}
