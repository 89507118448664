.budget-main-wrap {
  display: flex;
  height: 100%;
  position: relative;
}
.budget-main-wrap .subtitle-box {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
}
.budget-main-wrap .subtitle-box > *:not(:last-child) {
  margin-right: 0.5rem;
}
.budget-main-wrap .subtitle-box .text {
  cursor: pointer;
}
.budget-main-wrap .subtitle-box .active {
  font-weight: 600;
  color: var(--black-color);
}
.budget-main-wrap .side-wrap {
  flex: 0 0 20%;
  background-color: var(--bg-white-color);
  border-right: 0.1rem solid var(--border-color);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 4rem;
}
.budget-main-wrap .side-wrap .underline {
  height: 0.1rem;
  background-color: var(--border-color);
  width: 100%;
  margin-bottom: 4rem;
}
.budget-main-wrap .side-wrap .title {
  font-weight: 700;
  color: #464f54;
  margin-bottom: 3rem;
  padding-left: 3rem;
  cursor: pointer;
}
.budget-main-wrap .side-wrap .link {
  font-size: 1.45rem;
  margin-bottom: 2.5rem;
  padding-left: 3rem;
  cursor: pointer;
}
.budget-main-wrap .side-wrap .link-active {
  color: var(--blue-color);
}
.budget-main-wrap .main-wrap {
  flex: 1;
}

.common-link-expenditure-rev {
  padding: 4rem 0rem;
}
.common-link-expenditure-rev .main-title {
  margin-bottom: 3rem;
  font-weight: 700;
  font-size: 1.7rem;
  padding-left: 3rem;
}
.common-link-expenditure-rev .box {
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
  border-bottom: 0.1rem solid var(--border-color);
}
.common-link-expenditure-rev .box > *:not(:last-child) {
  margin-right: 3rem;
}
.common-link-expenditure-rev .box .text {
  color: var(--black-color);
  padding: 1rem 5rem;
  font-weight: 500;
  cursor: pointer;
}
.common-link-expenditure-rev .box .text-active {
  background-color: var(--bg-white-color);
  box-shadow: var(--box-shadow-two);
  position: relative;
}
.common-link-expenditure-rev .box .text-active::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 0.5rem;
  width: 70%;
  left: 50%;
  background-color: var(--blue-color);
  transform: translateX(-50%);
  border-radius: 5rem;
}

.table-box-budget {
  padding: 0rem 3rem;
}
.table-box-budget .btn-box-buget-template {
  display: flex;
  align-items: center;
  margin-top: 3rem;
}
.table-box-budget .btn-box-buget-template > *:first-child {
  background-color: var(--bg-white-color);
  color: var(--blue-color);
  border: 0.1rem solid var(--blue-color);
  margin-right: 2rem;
}
.table-box-budget .drap-drop-box {
  height: 25rem;
}
.table-box-budget .drap-drop-box > * {
  min-width: unset;
  max-width: unset;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center !important;
  border: 0.1rem dashed #b0babf;
  font-size: 2rem;
}
.table-box-budget .drap-drop-box > * > *:not(:first-child) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center !important;
}
.table-box-budget .drap-drop-box > * > *:nth-child(2) {
  transform: scale(2) translateY(1.4rem);
  filter: saturate(0%);
}
.table-box-budget .wrapper {
  margin-bottom: 4rem;
  border-bottom: 0.1rem solid var(--border-color);
  padding-bottom: 3rem;
}
.table-box-budget .wrapper .check-label-box {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  width: 100%;
}
.table-box-budget .wrapper .check-label-box .check {
  display: none;
}
.table-box-budget .wrapper .check-label-box .check:checked ~ .label span {
  background-color: var(--blue-color);
  border: unset;
}
.table-box-budget .wrapper .check-label-box .check:checked ~ .label span .icon {
  visibility: visible;
}
.table-box-budget .wrapper .check-label-box .label {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;
}
.table-box-budget .wrapper .check-label-box .label span {
  margin-right: 1rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 0.1rem solid var(--border-color);
  display: grid;
  place-items: center;
  transition: all 0.3s;
}
.table-box-budget .wrapper .check-label-box .label span .icon {
  width: 1rem;
  height: 1rem;
  color: var(--white-color);
  visibility: hidden;
}
.table-box-budget .wrapper .btn-set-up {
  align-self: flex-start;
  margin-top: 4rem;
  background-color: #eceded;
  padding: 1.2rem 4rem;
  color: #c3c7ce;
  text-align: center;
  cursor: pointer;
  border: 0.1rem solid var(--border-color);
  border-radius: 0.4rem;
  outline: none;
  transition: all 0.3s;
  font-weight: 500;
}
.table-box-budget .wrapper .btn-set-up:hover {
  transform: translateY(-0.1rem);
}
.table-box-budget .wrapper .btn-set-up-active {
  background-color: var(--blue-color);
  color: var(--white-color);
  border: none;
}
.table-box-budget .wrapper .box-title {
  color: var(--black-color);
  margin-bottom: 1rem;
  font-size: 1.5rem;
}
.table-box-budget .wrapper .small-text {
  font-size: 1.3rem;
}
.table-box-budget .wrapper .form-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 3rem;
}
.table-box-budget .wrapper .form-wrap .form-group {
  display: flex;
  flex-direction: column;
}
.table-box-budget .wrapper .form-wrap .form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  background-image: url("../../../assets/drop-down-icon.svg");
  background-repeat: no-repeat; /* Add custom arrow */
  background-position: 95% center;
  background-size: 2rem;
}
.table-box-budget .wrapper .form-wrap .form-group .label {
  margin-bottom: 0.4rem;
  display: flex;
  align-items: center;
  color: #5b6871;
}
.table-box-budget .wrapper .form-wrap .form-group .label span {
  margin-left: 0.4rem;
  color: #cc0905;
  display: inline-block;
  font-size: 1.7rem;
  transform: translateY(0.4rem);
}
.table-box-budget .wrapper .form-wrap .form-group .input {
  border: 0.1rem solid var(--border-color);
  border-radius: 0.8rem;
  padding: 1.3rem 1rem;
  outline: none;
  width: 100%;
  font-size: 1.4rem;
  font-size: 1.35rem;
  color: var(--black-color);
  background-color: var(--bg-white-color);
}
.table-box-budget .wrapper .form-wrap .form-group .input::-moz-placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.table-box-budget .wrapper .form-wrap .form-group .input::placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.table-box-budget .wrapper .form-wrap .form-group .input:focus {
  border: 0.1rem solid var(--blue-color);
  outline: none;
}
.table-box-budget .wrapper .form-wrap .form-group .input-active {
  border: 0.1rem solid var(--blue-color);
}
.table-box-budget .wrapper .form-wrap-six {
  grid-template-rows: repeat(3, 1fr);
}/*# sourceMappingURL=BudgetSetting.css.map */