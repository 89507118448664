


.common-link-approval-flow-wrap{
width: 100%;
display: flex;
align-items: center;
margin: 1.5rem 0rem;

&>*:not(:last-child){
    margin-right: 3rem;
}

.item{
    display: flex;
    align-items: center;
    background-color: var(--bg-white-color);
    border: .1rem solid var(--border-color);
    border-radius: 1rem;
    padding: 1rem 1.5rem;
    cursor: pointer;

    span{
        display: inline-block;
        width: 1.7rem;
        height: 1.7rem;
        position: relative;
        border-radius: 50%;
        border: .1rem solid var(--blue-color);
        background-color: var(--bg-white-color);
        margin-right: 1rem;
        transform: scale(0);
        display: none;


        &::after{
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 70%;
            height: 70%;
            border-radius: 50%;
            background-color: var(--blue-color);
        }
    }

    .span-active{
        transform: scale(1);
        display: inline-block;
    }

    p{
        font-size: 1.25rem;
        font-weight: 500;
    }
}

.item-active{
    background-color: #D7EDFF;

    p{
        color: var(--blue-color);
    }
}
}