.auth-form-wrap {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  min-width: 35rem;
}
.auth-form-wrap > *:not(:last-child) {
  margin-bottom: 3rem;
}
.auth-form-wrap .two-form-group-box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-column-gap: 2rem;
}
.auth-form-wrap .form-group {
  display: flex;
  align-items: center;
  position: relative;
  border: 0.1rem solid #dde2e4;
  border-radius: 0.8rem;
  padding: 0.5rem 1rem;
}
.auth-form-wrap .form-group .error {
  position: absolute;
  color: #CC0905;
  font-size: 1.2rem;
  top: 100%;
  left: 0;
  text-transform: capitalize;
}
.auth-form-wrap .form-group > * {
  position: relative;
  z-index: 3;
}
.auth-form-wrap .form-group .show-hide {
  display: grid;
  place-items: center;
  cursor: pointer;
}
.auth-form-wrap .form-group .show-hide .icon {
  width: 2rem;
  height: 2rem;
  color: #b0babf;
}
.auth-form-wrap .form-group .status {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0.1rem solid var(--blue-color);
  border-radius: 0.8rem;
  z-index: 1;
  visibility: hidden;
  transition: visibility 0.3s;
}
.auth-form-wrap .form-group .status-active {
  visibility: visible;
}
.auth-form-wrap .form-group .placeholder {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 10%;
  font-size: 1.35rem;
  text-transform: capitalize;
  background-color: var(--bg-white-color);
  padding: 0rem 1rem;
  transition: transform 0.5s;
}
.auth-form-wrap .form-group .placeholder-active {
  visibility: visible;
  transform: translateY(-50%);
}
.auth-form-wrap .form-group .form-label {
  margin-right: 0.2rem;
  flex: 0 0 7%;
  cursor: pointer;
}
.auth-form-wrap .form-group .form-label .img-box {
  width: 2.5rem;
  height: 2.5rem;
}
.auth-form-wrap .form-group .form-label .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.auth-form-wrap .form-group .form-input {
  border: none;
  flex: 1;
  padding: 1rem 1rem;
  outline: none;
  width: 100%;
  background-color: transparent;
  font-size: 1.35rem;
  color: var(--black-shade-color);
}
.auth-form-wrap .form-group .form-input::-moz-placeholder {
  font-size: 1.3rem;
  text-transform: capitalize;
}
.auth-form-wrap .form-group .form-input::placeholder {
  font-size: 1.3rem;
  text-transform: capitalize;
}
.auth-form-wrap .form-group .form-input:focus::-moz-placeholder {
  visibility: hidden;
}
.auth-form-wrap .form-group .form-input:focus::placeholder {
  visibility: hidden;
}
.auth-form-wrap .form-group .form-input:focus ~ .placeholder {
  visibility: visible;
  transform: translateY(-50%);
}
.auth-form-wrap .form-group .phone-input {
  padding: 0.5rem;
  color: #a5b1bd;
}
.auth-form-wrap .form-group .input-class {
  border: none;
  color: #a5b1bd;
}
@media only screen and (max-width: 38em) {
  .auth-form-wrap .form-group .input-class {
    width: 100%;
  }
}
.auth-form-wrap .form-group .drop-down-class {
  background-color: transparent;
  border: unset;
}
.auth-form-wrap .form-group .pin-field {
  border-radius: 0.7rem;
  font-size: 2rem;
  height: 5rem;
  outline: none;
  text-align: center;
  transition-duration: 250ms;
  transition-property: background, color, border, box-shadow, transform;
  width: 5rem;
  border: 0.07rem solid #a5b1bd;
}
@media only screen and (max-width: 24em) {
  .auth-form-wrap .form-group .pin-field {
    height: 4rem;
  }
}
.auth-form-wrap .form-group .pin-field:not(:last-child) {
  margin-right: 1.5rem;
}
.auth-form-wrap .form-group .pin-field:focus {
  border-color: #0069ff;
  outline: none;
  transform: scale(1.05);
}
.auth-form-wrap .form-group .pin-field:invalid {
  animation: shake 3 linear 75ms;
  border-color: rgb(220, 53, 69);
  box-shadow: 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.auth-form-wrap .form-group .pin-field:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.auth-form-wrap .form-group .pin-field-complete {
  border: 0.2rem solid #0069ff !important;
}
.auth-form-wrap .form-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.7rem 3rem;
  border-radius: 0.8rem;
  background-color: #eceded;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  color: #C3C7CE;
  font-weight: 600;
  margin-top: 4rem;
}
.auth-form-wrap .form-submit:hover {
  transform: translateY(-0.15rem);
}
.auth-form-wrap .form-submit-active {
  background-color: var(--blue-color);
  color: var(--white-color);
}
.auth-form-wrap .form-submit-forget-password {
  margin-top: 2rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}/*# sourceMappingURL=AuthForm.css.map */