

.approval-flow-wrap{
    width: 100%;
    padding: 3rem 4rem;
    display: flex;
    flex-direction: column;

    .title {
        font-weight: 700;
        font-size: 1.8rem;
        margin-bottom: 1rem;
        color: var(--black-color);
      }

      .img-text-wrap{
        height: 50rem;
        display: grid;
        place-items: center;

        .content-box{
            display: flex;
            flex-direction: column;
            align-items: center;
           
            .img-box{
                width: 15rem;
                height: 15rem;

                .img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    display: block;
                }
            }

            .text{
                color: #84919A;
                font-size: 1.6rem;
            }
        }
      }

      .text-button-box{
        // background-color: red;
        margin-top: 5rem;
        align-self: center;
        display: flex;
        align-items: center;


        .btn{
            background-color: var(--blue-color);
            padding: .8rem 2rem;
            border: none;
            outline: none;
            cursor: pointer;
            color: var(--white-color);
            margin-left: 2rem;
            border-radius: .5rem;
            font-size: 1.2rem;
            transition: all .3s;
            font-weight: 600;

            &:hover{
                transform: translateY(-.1rem);
            }
        }

        .text{
            font-size: 1.3rem;
        }
      }
}