.admin-approval-settings-wrap {
  padding: 4rem;
}
.admin-approval-settings-wrap .subtitle-box {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
}
.admin-approval-settings-wrap .subtitle-box > *:not(:last-child) {
  margin-right: 0.5rem;
}
.admin-approval-settings-wrap .subtitle-box .text {
  cursor: pointer;
}
.admin-approval-settings-wrap .subtitle-box .active {
  font-weight: 600;
  color: var(--black-color);
}
.admin-approval-settings-wrap .title-text-box {
  margin-bottom: 2rem;
  border-bottom: 0.1rem solid var(--border-color);
  width: 80%;
  padding-bottom: 1.5rem;
}
.admin-approval-settings-wrap .title-text-box .title {
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: var(--black-color);
}
.admin-approval-settings-wrap .title-text-box .text {
  font-size: 1.35rem;
  cursor: pointer;
}
.admin-approval-settings-wrap .title-btn-box {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  border-bottom: 0.1rem solid var(--border-color);
  padding-bottom: 1.5rem;
  margin-top: 5rem;
}
.admin-approval-settings-wrap .title-btn-box .title-text-box {
  margin-bottom: 0rem;
  border-bottom: unset;
  padding-bottom: unset;
}
.admin-approval-settings-wrap .title-btn-box .title-text-box .title {
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: var(--black-color);
}
.admin-approval-settings-wrap .title-btn-box .title-text-box .text {
  font-size: 1.35rem;
}
.admin-approval-settings-wrap .title-btn-box .btn-download-box {
  flex: 0 0 30%;
  align-self: flex-start;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.admin-approval-settings-wrap .title-btn-box .btn-download-box .btn {
  padding: 0.8rem 2rem;
  border: none;
  outline: none;
  border-radius: 0.6rem;
  background-color: var(--bg-white-color);
  border: 0.1rem solid var(--blue-color);
  color: var(--blue-color);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  justify-content: center;
  text-transform: capitalize;
  font-size: 1.25rem;
}
.admin-approval-settings-wrap .title-btn-box .btn-download-box .btn:hover {
  transform: translateY(-0.15rem);
}
.admin-approval-settings-wrap .title-btn-box .btn-download-box .btn-save {
  background-color: #eceded;
  color: #c3c7ce;
  border: 0.1rem solid var(--border-color);
  margin-left: 3rem;
}
.admin-approval-settings-wrap .title-btn-box .btn-download-box .btn-save-active {
  background-color: var(--blue-color);
  color: var(--white-color);
}
.admin-approval-settings-wrap .list-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5rem;
  margin: 5rem 0rem 4rem 0rem;
}
.admin-approval-settings-wrap .list-wrap .item {
  height: 17rem;
  position: relative;
  background-color: var(--bg-white-color);
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow: var(--box-shadow-one);
  border-radius: 1rem;
  cursor: pointer;
}
.admin-approval-settings-wrap .list-wrap .item .title {
  font-size: 2rem;
  font-weight: 600;
}
.admin-approval-settings-wrap .list-wrap .item .text {
  font-size: 1.35rem;
}
.admin-approval-settings-wrap .list-wrap .item .check-box {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  border: 0.1rem solid var(--border-color);
}
.admin-approval-settings-wrap .list-wrap .item .check-box .icon {
  color: var(--white-color);
  width: 1rem;
  height: 1rem;
}
.admin-approval-settings-wrap .list-wrap .item-active {
  background-color: #f4faff;
  position: relative;
}
.admin-approval-settings-wrap .list-wrap .item-active > * {
  position: relative;
  z-index: 3;
}
.admin-approval-settings-wrap .list-wrap .item-active::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  border: 0.1rem solid var(--blue-color);
  border-radius: 1rem;
}
.admin-approval-settings-wrap .list-wrap .item-active .check-box {
  border: unset;
  background-color: var(--blue-color);
}
.admin-approval-settings-wrap .list-wrap .item-active .check-box .icon {
  color: var(--white-color);
}
.admin-approval-settings-wrap .form-wrap .form-title {
  font-weight: 700;
  font-size: 1.45rem;
  margin-bottom: 1rem;
  color: var(--black-color);
}
.admin-approval-settings-wrap .form-wrap .form .wrapper {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  border-bottom: 0.1rem solid var(--border-color);
  padding-bottom: 3rem;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .btn-set-up {
  align-self: flex-start;
  margin-top: 4rem;
  background-color: #eceded;
  padding: 1.2rem 4rem;
  color: #c3c7ce;
  text-align: center;
  cursor: pointer;
  border: 0.1rem solid var(--border-color);
  border-radius: 0.4rem;
  outline: none;
  transition: all 0.3s;
  font-weight: 500;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .btn-set-up:hover {
  transform: translateY(-0.1rem);
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .btn-set-up-active {
  background-color: var(--blue-color);
  color: var(--white-color);
  border: none;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .box-title {
  color: var(--black-color);
  margin-bottom: 1rem;
  font-size: 1.5rem;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .small-text {
  font-size: 1.3rem;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .form-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .form-wrap .form-group {
  display: flex;
  flex-direction: column;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .form-wrap .form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  background-image: url("../../../assets/drop-down-icon.svg");
  background-repeat: no-repeat; /* Add custom arrow */
  background-position: 95% center;
  background-size: 2rem;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .form-wrap .form-group .label {
  margin-bottom: 0.4rem;
  display: flex;
  align-items: center;
  color: #5b6871;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .form-wrap .form-group .label span {
  margin-left: 0.4rem;
  color: #cc0905;
  display: inline-block;
  font-size: 1.7rem;
  transform: translateY(0.4rem);
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .form-wrap .form-group .input {
  border: 0.1rem solid var(--border-color);
  border-radius: 0.8rem;
  padding: 1.3rem 1rem;
  outline: none;
  width: 100%;
  font-size: 1.4rem;
  font-size: 1.35rem;
  color: var(--black-color);
  background-color: var(--bg-white-color);
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .form-wrap .form-group .input::-moz-placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .form-wrap .form-group .input::placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .form-wrap .form-group .input:focus {
  border: 0.1rem solid var(--blue-color);
  outline: none;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .form-wrap .form-group .input-active {
  border: 0.1rem solid var(--blue-color);
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .step-assign-box {
  display: flex;
  flex-direction: column;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .step-assign-box .step-assign-line {
  display: grid;
  grid-template-columns: min-content 1fr 1fr;
  align-items: center;
  border-bottom: 0.1rem solid var(--border-color);
  padding-bottom: 1rem;
  grid-column-gap: 3rem;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .step-assign-box .step-assign-line .text {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  text-transform: capitalize;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .removable-wrap {
  margin-top: 2rem;
  padding-top: 2rem;
  margin-bottom: 3rem;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .removable-wrap > *:not(:first-child) {
  margin-top: 3rem;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .removable-wrap .remove-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .removable-wrap .remove-group > * {
  position: relative;
  z-index: 3;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .removable-wrap .remove-group::after {
  content: "";
  position: absolute;
  height: 110%;
  width: 0.05rem;
  background-color: #cccccc;
  top: 50%;
  left: 1.2rem;
  transform: translateY(-25%);
  z-index: 2;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .removable-wrap .remove-group .top-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  z-index: 11;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .removable-wrap .remove-group .top-wrap > * {
  width: 40%;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .removable-wrap .remove-group .top-wrap .notify-user-box {
  margin-left: 2rem;
  display: flex;
  align-items: center;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .removable-wrap .remove-group .top-wrap .notify-user-box .notify-check {
  display: none;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .removable-wrap .remove-group .top-wrap .notify-user-box .notify-check:checked ~ .notify-label .circle::after {
  display: block;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .removable-wrap .remove-group .top-wrap .notify-user-box .notify-label {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .removable-wrap .remove-group .top-wrap .notify-user-box .notify-label .circle {
  position: relative;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  border: 0.1rem solid var(--blue-color);
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .removable-wrap .remove-group .top-wrap .notify-user-box .notify-label .circle::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 60%;
  border-radius: 50%;
  background-color: var(--blue-color);
  display: none;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .removable-wrap .remove-group .bottom-wrap {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr min-content repeat(2, 1fr);
  align-items: center;
  gap: 1.5rem;
  transform: translateX(4rem);
  position: relative;
  z-index: 10;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .removable-wrap .remove-group .bottom-wrap > * {
  width: 100%;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .removable-wrap .remove-group .step {
  background-color: var(--blue-color);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  color: var(--white-color);
  display: grid;
  place-items: center;
  font-size: 1.2rem;
  font-weight: 600;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .removable-wrap .remove-group .cancel-box {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  justify-self: flex-start;
  display: grid;
  place-items: center;
  border: 0.15rem solid #cc0905;
  cursor: pointer;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .removable-wrap .remove-group .cancel-box .icon {
  color: #cc0905;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .removable-wrap .remove-group-no-border::after {
  display: none;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .add-approval-box {
  align-self: flex-start;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--blue-color);
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .add-approval-box .text {
  font-size: 1.3rem;
  font-weight: 500;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .add-approval-box .icon {
  margin-right: 0.7rem;
  width: 1.3rem;
  height: 1.3rem;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .content {
  margin-top: 2rem;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .content > *:not(:last-child) {
  margin-bottom: 2rem;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .content .check-label-box {
  display: flex;
  align-items: center;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .content .check-label-box .check {
  display: none;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .content .check-label-box .check:checked ~ .label span {
  background-color: var(--blue-color);
  border: unset;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .content .check-label-box .check:checked ~ .label span .icon {
  visibility: visible;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .content .check-label-box .label {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  cursor: pointer;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .content .check-label-box .label span {
  margin-right: 1.5rem;
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 0.3rem;
  border: 0.1rem solid var(--border-color);
  display: grid;
  place-items: center;
  transition: all 0.3s;
}
.admin-approval-settings-wrap .form-wrap .form .wrapper .content .check-label-box .label span .icon {
  width: 1rem;
  height: 1rem;
  color: var(--white-color);
  visibility: hidden;
}/*# sourceMappingURL=AdminSettingApproval.css.map */