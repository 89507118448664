.emp-reinburstment-container {
  margin-top: 4rem;

  .chart-top {
    display: flex;
    justify-content: space-between;

    .arrow-btn {
      cursor: pointer;
    }
  }

  .emp-reinburstment-wrap {
    margin-top: 2rem;
    // box-sizing:  border-box;
    width: 100%;
    // height: 3vh;
    // height: 20%;
    // height: 35rem;
    background: var(--bg-white-color);
    border: 1px solid var(--border-color);
    // box-shadow: 0px 20px 30px var(--bg-white-color);
    border-radius: 6px;
    padding: 2rem;
  }
  .emp-reinbursement-box {
    display: grid;
    grid-template-columns: 3.5fr 1fr;
    .reinbursement-head {
      font-weight: 500;
      font-size: 1.55rem;
      color: var(--black-color);
      margin-top: 5px;
    }
  }

  .select {
    & > * {
      // background-color: var(--bg-white-color);
      color: #1d1d18;
      text-align: left;
      &:nth-child(3) {
        // border: none !important;
        min-width: 9rem;
        background-color: var(--bg-white-color);
        // border-radius: 5rem !important;

        & > *:nth-child(1) {
          & > *:nth-child(1) {
            color: var(--black-color);
          }
        }
      }
    }
  }

  .right-content {
    text-align: center;
    border: 1px solid var(--border-color);
    border-right: none;
    border-top: none;
    border-bottom: none;

    .select-wrap {
      margin-right: 3rem;
      margin-left: 3rem;
      margin-bottom: 3rem;
    }
    .pending-text {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.0045em;
      color: #fca549;
    }
    .chart-digit {
      font-weight: 700;
      font-size: 2rem;
      // line-height: 24px;
      margin-top: 2px;
      // letter-spacing: 1px;
      color: var(--black-color);
      margin-bottom: 3rem;
    }
    .active-text {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.0045em;
      color: #07a287;
    }
    .bottom-text {
      margin-top: 7rem;
      display: grid;
      gap: 1rem;
    }
    .icon {
      position: relative;
      margin-right: 4px;
      top: 2px;
      //    top:3rem;
      //    right:3rem;
      //    padding:1rem;
    }
  }
}
