.admin-setting-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 3rem 4rem;
  padding-bottom: 10rem;
  margin-top: 4rem;
  margin-bottom: 10rem;
}
.admin-setting-wrap .left-box {
  flex: 0 0 35%;
  display: flex;
  justify-content: center;
  align-self: flex-start;
  margin-right: 4rem;
}
.admin-setting-wrap .left-box .content-box {
  background-color: var(--bg-white-color);
  box-shadow: var(--box-shadow-one);
  border-radius: 1rem;
  width: 30rem;
  padding: 3rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-transform: capitalize;
}
.admin-setting-wrap .left-box .content-box > *:not(:last-child) {
  margin-bottom: 2rem;
}
.admin-setting-wrap .left-box .content-box .title {
  color: var(--black-color);
  font-size: 1.8rem;
  font-weight: 600;
}
.admin-setting-wrap .left-box .content-box .link {
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--black-color);
}
.admin-setting-wrap .right-box {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;
  margin-right: 4rem;
}
.admin-setting-wrap .right-box .content-box {
  background-color: var(--bg-white-color);
  box-shadow: var(--box-shadow-one);
  border-radius: 1rem;
  width: 30rem;
  padding: 3rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-transform: capitalize;
}
.admin-setting-wrap .right-box .content-box > *:not(:last-child) {
  margin-bottom: 2rem;
}
.admin-setting-wrap .right-box .content-box .title {
  color: var(--black-color);
  font-size: 1.8rem;
  font-weight: 600;
}
.admin-setting-wrap .right-box .content-box .link {
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--black-color);
}

.admin-org-settings-wrap {
  padding: 4rem;
  display: flex;
  flex-direction: column;
}
.admin-org-settings-wrap .subtitle-box {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
}
.admin-org-settings-wrap .subtitle-box > *:not(:last-child) {
  margin-right: 0.5rem;
}
.admin-org-settings-wrap .subtitle-box .text {
  cursor: pointer;
  color: var(--black-color);
}
.admin-org-settings-wrap .subtitle-box .active {
  font-weight: 600;
  color: var(--black-color);
}
.admin-org-settings-wrap .title-text-box {
  margin-bottom: 2rem;
  border-bottom: 0.1rem solid var(--border-color);
  width: 80%;
  padding-bottom: 1.5rem;
}
.admin-org-settings-wrap .title-text-box .title {
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: var(--black-color);
}
.admin-org-settings-wrap .title-text-box .text {
  font-size: 1.35rem;
  cursor: pointer;
  color: var(--black-color);
}
.admin-org-settings-wrap .title-btn-box {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  border-bottom: 0.1rem solid var(--border-color);
  padding-bottom: 1.5rem;
}
.admin-org-settings-wrap .title-btn-box .title-text-box {
  margin-bottom: 0rem;
  border-bottom: unset;
  padding-bottom: unset;
}
.admin-org-settings-wrap .title-btn-box .title-text-box .title {
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: var(--black-color);
}
.admin-org-settings-wrap .title-btn-box .title-text-box .text {
  font-size: 1.35rem;
  color: var(--black-color);
}
.admin-org-settings-wrap .title-btn-box .btn-download-box {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.admin-org-settings-wrap .title-btn-box .btn-download-box .btn-bulk {
  padding: 1.1rem 3rem;
  border: none;
  outline: none;
  border-radius: 0.6rem;
  background-color: var(--blue-color);
  color: var(--white-color);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  justify-content: center;
  text-transform: capitalize;
  font-size: 1.25rem;
}
.admin-org-settings-wrap .title-btn-box .btn-download-box .btn-bulk .icon {
  margin-right: 1rem;
}
.admin-org-settings-wrap .title-btn-box .btn-download-box .btn-bulk:hover {
  transform: translateY(-0.15rem);
}
.admin-org-settings-wrap .title-btn-box .btn-download-box .btn-bulk-inactive {
  background-color: #eceded;
  color: #c3c7ce;
  border: 0.1rem solid var(--border-color);
}
.admin-org-settings-wrap .title-btn-box .btn-download-box .text {
  margin-top: 1rem;
  font-size: 1.2rem;
  color: var(--blue-color);
  cursor: pointer;
}
.admin-org-settings-wrap .form-box {
  width: 50rem;
}
.admin-org-settings-wrap .form-box .form-title {
  font-weight: 700;
  font-size: 1.45rem;
  margin-bottom: 1rem;
  color: var(--black-color);
}
.admin-org-settings-wrap .form-box .form .wrapper {
  margin-top: 3rem;
  margin-bottom: 4rem;
  border-bottom: 0.1rem solid var(--border-color);
  padding-bottom: 3rem;
}
.admin-org-settings-wrap .form-box .form .wrapper .btn-set-up {
  align-self: flex-start;
  margin-top: 4rem;
  background-color: #eceded;
  padding: 1.2rem 4rem;
  color: #c3c7ce;
  text-align: center;
  cursor: pointer;
  border: 0.1rem solid var(--border-color);
  border-radius: 0.4rem;
  outline: none;
  transition: all 0.3s;
  font-weight: 500;
}
.admin-org-settings-wrap .form-box .form .wrapper .btn-set-up:hover {
  transform: translateY(-0.1rem);
}
.admin-org-settings-wrap .form-box .form .wrapper .btn-set-up-active {
  background-color: var(--blue-color);
  color: var(--white-color);
  border: none;
}
.admin-org-settings-wrap .form-box .form .wrapper .box-title {
  color: #000000;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}
.admin-org-settings-wrap .form-box .form .wrapper .small-text {
  font-size: 1.3rem;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 3rem;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .color-group-box {
  grid-column: 1/-1;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .color-group-box .color-title {
  font-size: 1.35rem;
  font-weight: 500;
  color: var(--black-color);
  margin-bottom: 1.5rem;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .color-group-box .wrap {
  display: flex;
  flex-direction: column;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .color-group-box .wrap .text-box {
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 4rem;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .color-group-box .wrap .text-box .color-text {
  font-size: 1.35rem;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .color-group-box .wrap .text-box > *:last-child {
  font-size: 1.25rem;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .color-group-box .wrap .color-box {
  flex: 0 0 50%;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .color-group-box .wrap .color-box .form-group {
  display: flex;
  border: 0.1rem solid var(--border-color);
  border-radius: 0.4rem;
  overflow: hidden;
  background-color: var(--bg-white-color);
  flex-direction: row !important;
  width: 30rem;
  margin: 2rem 0rem;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .color-group-box .wrap .color-box .form-group .input {
  padding: 1.3rem 1rem;
  background-color: transparent;
  flex: 0 0 75%;
  outline: none;
  border: none;
  color: var(--black-color);
  background-color: var(--bg-white-color);
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .color-group-box .wrap .color-box .form-group .input:focus {
  border: none;
  outline: none;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .color-group-box .wrap .color-box .form-group .display-color {
  flex: 0 0 25%;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .form-group {
  display: flex;
  flex-direction: column;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .form-group .select-wrap {
  text-transform: capitalize;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .form-group .select-wrap > * {
  background-color: var(--bg-white-color);
  color: #1d1d18;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .form-group .select-wrap > *:nth-child(3) {
  min-width: 9rem;
  background-color: var(--bg-white-color);
  border-radius: 0.7rem !important;
  border: 0.1rem solid var(--border-color);
  color: #1d1d18;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .form-group .select-wrap > *:nth-child(3) > *:nth-child(1) > *:nth-child(1) {
  color: #1d1d18;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .form-group .disable-select {
  cursor: not-allowed;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .form-group .disable-select > *:nth-child(3) {
  background-color: #eceded !important;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .form-group .disable-select > *:nth-child(3) > * {
  background-color: #eceded !important;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .form-group .select-wrap-active > * {
  background-color: var(--bg-white-color);
  color: #1d1d18 !important;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .form-group .select-wrap-active > *:nth-child(3) {
  min-width: 9rem;
  background-color: var(--bg-white-color);
  border-radius: 0.7rem !important;
  border: 0.1rem solid var(--blue-color);
  color: #1d1d18;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .form-group .select-wrap-active > *:nth-child(3) > *:nth-child(1) > *:nth-child(1) {
  color: #1d1d18 !important;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .form-group .select-wrap-multi > *:nth-child(3) > * > *:not(:last-child) > * {
  color: #ffffff !important;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  background-image: url("../../../assets/drop-down-icon.svg");
  background-repeat: no-repeat; /* Add custom arrow */
  background-position: 95% center;
  background-size: 2rem;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .form-group .label {
  margin-bottom: 0.4rem;
  display: flex;
  align-items: center;
  color: #5b6871;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .form-group .label span {
  margin-left: 0.4rem;
  color: #cc0905;
  display: inline-block;
  font-size: 1.7rem;
  transform: translateY(0.4rem);
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .form-group .input {
  border: 0.1rem solid var(--border-color);
  border-radius: 0.8rem;
  padding: 1.3rem 1rem;
  outline: none;
  width: 100%;
  font-size: 1.4rem;
  font-size: 1.35rem;
  color: var(--black-shade-color);
  color: var(--black-color);
  background-color: var(--bg-white-color);
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .form-group .input::-moz-placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .form-group .input::placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .form-group .input:focus {
  border: 0.1rem solid var(--blue-color);
  outline: none;
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap .form-group .input-active {
  border: 0.1rem solid var(--blue-color);
}
.admin-org-settings-wrap .form-box .form .wrapper .form-wrap-six {
  grid-template-rows: repeat(3, 1fr);
}
.admin-org-settings-wrap .form-box .form .input-email {
  width: 90%;
  padding: 1.2rem 2rem;
  border: 0.1rem solid var(--border-color);
  border-radius: 0.5rem;
  margin-bottom: 2rem;
  outline: none;
}
.admin-org-settings-wrap .form-box .form .input-email:focus {
  outline: none;
  border: 0.1rem solid var(--border-color);
}
.admin-org-settings-wrap .form-box .form .input-email::-moz-placeholder {
  color: #5b6871;
}
.admin-org-settings-wrap .form-box .form .input-email::placeholder {
  color: #5b6871;
}
.admin-org-settings-wrap .form-box .form .form-check-box {
  margin-top: 2rem;
  background-color: #f6f8f9;
  padding: 2rem 3rem;
  border: 0.1rem solid var(--border-color);
  border-radius: 1rem;
}
.admin-org-settings-wrap .form-box .form .form-check-box > *:not(:last-child) {
  margin-bottom: 2rem;
}
.admin-org-settings-wrap .form-box .form .form-check-box .check-group {
  display: flex;
  align-items: center;
}
.admin-org-settings-wrap .form-box .form .form-check-box .check-group .check-input {
  display: none;
}
.admin-org-settings-wrap .form-box .form .form-check-box .check-group .check-input:checked ~ .check-label .check-circle {
  background-color: var(--blue-color);
}
.admin-org-settings-wrap .form-box .form .form-check-box .check-group .check-input:checked ~ .check-label .check-circle .icon {
  color: var(--white-color);
}
.admin-org-settings-wrap .form-box .form .form-check-box .check-group .check-label {
  display: grid;
  align-items: center;
  grid-template-columns: min-content max-content 1rem max-content;
  grid-column-gap: 1rem;
  cursor: pointer;
}
.admin-org-settings-wrap .form-box .form .form-check-box .check-group .check-label span {
  display: inline-block;
}
.admin-org-settings-wrap .form-box .form .form-check-box .check-group .check-label .check-circle {
  width: 2.5rem;
  height: 2.5rem;
  border: 0.1rem solid var(--border-color);
  border-radius: 50%;
  margin-right: 1.5rem;
  display: grid;
  place-items: center;
}
.admin-org-settings-wrap .form-box .form .form-check-box .check-group .check-label .check-circle .icon {
  color: #f6f8f9;
  width: 1.1rem;
  height: 1.1rem;
}
.admin-org-settings-wrap .form-box .form .form-check-box .check-group .check-label .text-one {
  font-size: 1.3rem;
}
.admin-org-settings-wrap .form-box .form .form-check-box .check-group .check-label .text-two {
  color: #84919a;
  font-size: 1.25rem;
}
.admin-org-settings-wrap .form-box .form .form-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.3rem 3rem;
  border-radius: 0.8rem;
  background-color: #eceded;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  color: #c3c7ce;
  font-weight: 600;
  margin-top: 4rem;
  font-size: 1.3rem;
  margin-top: 0rem;
}
.admin-org-settings-wrap .form-box .form .form-submit:hover {
  transform: translateY(-0.15rem);
}
.admin-org-settings-wrap .form-box .form .form-submit-active {
  background-color: var(--blue-color);
  color: var(--white-color);
}
.admin-org-settings-wrap .form-box .note-img-wrap {
  color: var(--black-color);
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
  font-weight: 500;
  margin-top: 2.5rem;
}
.admin-org-settings-wrap .form-box .note-img-wrap .img-box {
  transform: translateY(0.3rem);
  margin-left: 0.5rem;
}
.admin-org-settings-wrap .form-box .note-box {
  background-color: rgba(255, 192, 67, 0.1);
  padding: 1rem 2rem;
  border-radius: 0.6rem;
}
.admin-org-settings-wrap .form-box .note-box p {
  font-size: 1.2rem;
  color: #596080;
}
.admin-org-settings-wrap .form-box .note-box p span {
  font-weight: 700;
  color: var(--black-color);
  margin-right: 0.8rem;
  text-transform: capitalize;
}
.admin-org-settings-wrap .form-box .note-box > * {
  display: flex;
  align-items: center;
}
.admin-org-settings-wrap .form-box .note-box > * .img-box {
  margin-right: 1rem;
  transform: translateY(0.1rem);
}
.admin-org-settings-wrap .form-box .note-box > * p {
  color: #596080;
  font-size: 1.1rem;
}
.admin-org-settings-wrap .form-box .btn-box-wrap {
  margin-top: 4rem;
}
.admin-org-settings-wrap .form-box .btn-box-wrap .btn-reuse {
  padding: 1.2rem 3rem;
  border: none;
  font-size: 1.3rem;
  font-size: 500;
  background-color: #eceded;
  border: 0.1rem solid var(--border-color);
  color: #c3c7ce;
  border-radius: 0.6rem;
  cursor: pointer;
  transition: all 0.3s;
  margin-right: 3rem;
}
.admin-org-settings-wrap .form-box .btn-box-wrap .dna-wrapper-box {
  background-color: unset;
  padding: unset;
  border: unset;
}
.admin-org-settings-wrap .form-box .btn-box-wrap .btn-reuse:hover {
  transform: translateY(-0.1rem);
}
.admin-org-settings-wrap .form-box .btn-box-wrap .btn-reuse {
  margin-right: 2rem;
  border-radius: 0.6rem;
  border: 0.1rem solid var(--blue-color);
  color: var(--blue-color);
  background-color: var(--bg-white-color);
}
.admin-org-settings-wrap .form-box .btn-box-wrap .active-btn {
  color: var(--white-color);
  background-color: var(--blue-color);
}
.admin-org-settings-wrap .note-wrap {
  margin-top: 7rem;
  width: 70%;
}
.admin-org-settings-wrap .note-wrap .note-img-wrap {
  color: var(--black-color);
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
  font-weight: 500;
  margin-top: 2.5rem;
}
.admin-org-settings-wrap .note-wrap .note-img-wrap .img-box {
  transform: translateY(0.3rem);
  margin-left: 0.5rem;
}
.admin-org-settings-wrap .note-wrap .note-box {
  background-color: rgba(255, 192, 67, 0.1);
  padding: 1rem 2rem;
  border-radius: 0.6rem;
}
.admin-org-settings-wrap .note-wrap .note-box p {
  font-size: 1.2rem;
  color: #596080;
}
.admin-org-settings-wrap .note-wrap .note-box p span {
  font-weight: 700;
  color: var(--black-color);
  margin-right: 0.8rem;
  text-transform: capitalize;
}
.admin-org-settings-wrap .note-wrap .note-box > * {
  display: flex;
  align-items: center;
}
.admin-org-settings-wrap .note-wrap .note-box > * .img-box {
  margin-right: 1rem;
  transform: translateY(0.1rem);
}
.admin-org-settings-wrap .note-wrap .note-box > * p {
  color: #596080;
  font-size: 1.1rem;
}
.admin-org-settings-wrap .btn-box-wrap {
  margin-top: 4rem;
  display: flex;
  align-items: center;
}
.admin-org-settings-wrap .btn-box-wrap .btn-reuse {
  padding: 1.2rem 3rem;
  border: none;
  font-size: 1.3rem;
  font-size: 500;
  background-color: #eceded;
  border: 0.1rem solid var(--border-color);
  color: #c3c7ce;
  border-radius: 0.6rem;
  cursor: pointer;
  transition: all 0.3s;
  margin-right: 3rem;
}
.admin-org-settings-wrap .btn-box-wrap .dna-wrapper-box {
  background-color: unset;
  padding: unset;
  border: unset;
}
.admin-org-settings-wrap .btn-box-wrap .form-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.7rem 3rem;
  border-radius: 0.8rem;
  background-color: #eceded;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  color: #c3c7ce;
  font-weight: 600;
}
.admin-org-settings-wrap .btn-box-wrap .form-submit:hover {
  transform: translateY(-0.15rem);
}
.admin-org-settings-wrap .btn-box-wrap .form-submit-active {
  background-color: var(--blue-color);
  color: var(--white-color);
}
.admin-org-settings-wrap .btn-box-wrap .btn-reuse:hover {
  transform: translateY(-0.1rem);
}
.admin-org-settings-wrap .btn-box-wrap .btn-reuse {
  margin-right: 4rem;
  border-radius: 0.6rem;
  border: 0.1rem solid var(--blue-color);
  color: var(--blue-color);
  background-color: var(--bg-white-color);
}
.admin-org-settings-wrap .btn-box-wrap .active-btn {
  color: var(--white-color);
  background-color: var(--blue-color);
}
.admin-org-settings-wrap .skip-box {
  margin-top: 3rem;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
}
.admin-org-settings-wrap .skip-box .btn {
  margin-left: 2rem;
  padding: 0.8rem 2rem;
  border: none;
  font-size: 1.3rem;
  font-size: 500;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 0.6rem;
  border: 0.1rem solid var(--blue-color);
  color: var(--blue-color);
  background-color: var(--bg-white-color);
}
.admin-org-settings-wrap .skip-box .btn:hover {
  transform: translateY(-0.1rem);
}
.admin-org-settings-wrap .removable-wrap {
  margin-top: 2rem;
  padding-top: 2rem;
  margin-bottom: 3rem;
}
.admin-org-settings-wrap .removable-wrap > *:not(:first-child) {
  margin-top: 3rem;
}
.admin-org-settings-wrap .removable-wrap .form-group {
  display: flex;
  flex-direction: column;
}
.admin-org-settings-wrap .removable-wrap .form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  background-image: url("../../../assets/drop-down-icon.svg");
  background-repeat: no-repeat; /* Add custom arrow */
  background-position: 95% center;
  background-size: 2rem;
}
.admin-org-settings-wrap .removable-wrap .form-group .label {
  display: flex;
  align-items: center;
  color: #5b6871;
}
.admin-org-settings-wrap .removable-wrap .form-group .label span {
  margin-left: 0.4rem;
  color: #cc0905;
  display: inline-block;
  font-size: 1.7rem;
  transform: translateY(0.4rem);
}
.admin-org-settings-wrap .removable-wrap .form-group .input {
  border: 0.1rem solid var(--border-color);
  border-radius: 0.8rem;
  padding: 1rem 1rem;
  outline: none;
  width: 100%;
  font-size: 1.4rem;
  font-size: 1.35rem;
  color: var(--black-shade-color);
  color: var(--black-color);
  background-color: var(--bg-white-color);
}
.admin-org-settings-wrap .removable-wrap .form-group .input::-moz-placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.admin-org-settings-wrap .removable-wrap .form-group .input::placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.admin-org-settings-wrap .removable-wrap .form-group .input:focus {
  border: 0.1rem solid var(--blue-color);
  outline: none;
}
.admin-org-settings-wrap .removable-wrap .form-group .input-active {
  border: 0.1rem solid var(--blue-color);
}
.admin-org-settings-wrap .removable-wrap .remove-group {
  width: 100%;
  display: grid;
  grid-template-columns: min-content 1fr 1fr 5rem;
  align-items: center;
  grid-column-gap: 3rem;
}
.admin-org-settings-wrap .removable-wrap .remove-group .step {
  background-color: var(--blue-color);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  color: var(--white-color);
  display: grid;
  place-items: center;
  font-size: 1.2rem;
  font-weight: 600;
}
.admin-org-settings-wrap .removable-wrap .remove-group .cancel-box {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  justify-self: flex-start;
  display: grid;
  place-items: center;
  border: 0.15rem solid #cc0905;
  cursor: pointer;
}
.admin-org-settings-wrap .removable-wrap .remove-group .cancel-box .icon {
  color: #cc0905;
}
.admin-org-settings-wrap .add-approval-box {
  align-self: flex-start;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--blue-color);
}
.admin-org-settings-wrap .add-approval-box .text {
  font-size: 1.3rem;
  font-weight: 500;
}
.admin-org-settings-wrap .add-approval-box .icon {
  margin-right: 0.7rem;
  width: 1.3rem;
  height: 1.3rem;
}
.admin-org-settings-wrap .btn-box-save {
  transform: translateY(-110%);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-end;
}
.admin-org-settings-wrap .btn-box-save > *:first-child {
  margin-right: 2rem;
  background-color: var(--bg-white-color);
  color: var(--blue-color);
  border: 0.1rem solid var(--blue-color);
}
.admin-org-settings-wrap .btn-box-save .button-blue-wrap {
  margin-bottom: 0rem;
}
.admin-org-settings-wrap .role-information-box {
  background-color: var(--bg-white-color);
  box-shadow: var(--box-shadow-two);
  margin-top: -3.5rem;
  padding-bottom: 2rem;
}
.admin-org-settings-wrap .role-information-box .title {
  padding: 2rem 0rem 0rem 3rem;
  color: var(--black-color);
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 2rem;
}
.admin-org-settings-wrap .two-group-box {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0rem 3rem;
  grid-gap: 3rem;
  width: 80%;
}
.admin-org-settings-wrap .two-group-box .form-group {
  display: flex;
  flex-direction: column;
}
.admin-org-settings-wrap .two-group-box .form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  background-image: url("../../../assets/drop-down-icon.svg");
  background-repeat: no-repeat; /* Add custom arrow */
  background-position: 95% center;
  background-size: 2rem;
}
.admin-org-settings-wrap .two-group-box .form-group .label {
  margin-bottom: 0.4rem;
  display: flex;
  align-items: center;
  color: #5b6871;
  text-transform: capitalize;
}
.admin-org-settings-wrap .two-group-box .form-group .label span {
  margin-left: 0.4rem;
  color: #cc0905;
  display: inline-block;
  font-size: 1.7rem;
  transform: translateY(0.4rem);
}
.admin-org-settings-wrap .two-group-box .form-group .input {
  border: 0.1rem solid var(--border-color);
  border-radius: 0.8rem;
  padding: 1.3rem 1rem;
  outline: none;
  width: 100%;
  font-size: 1.4rem;
  font-size: 1.35rem;
  color: var(--black-shade-color);
  color: var(--black-color);
  background-color: var(--bg-white-color);
}
.admin-org-settings-wrap .two-group-box .form-group .input::-moz-placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.admin-org-settings-wrap .two-group-box .form-group .input::placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.admin-org-settings-wrap .two-group-box .form-group .input:focus {
  border: 0.1rem solid var(--blue-color);
  outline: none;
}
.admin-org-settings-wrap .two-group-box .form-group .input-active {
  border: 0.1rem solid var(--blue-color);
}
.admin-org-settings-wrap .underline {
  background-color: var(--border-color);
  height: 0.1rem;
  width: 100%;
  margin: 2rem 0rem 2rem 0rem;
}
.admin-org-settings-wrap .three-box {
  padding: 1.5rem 4rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.admin-org-settings-wrap .three-box .box {
  font-size: 1.25rem;
  display: flex;
  align-items: center;
}
.admin-org-settings-wrap .three-box .box .img-box {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  border-radius: 50%;
  overflow: hidden;
}
.admin-org-settings-wrap .three-box .box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.admin-org-settings-wrap .three-box .box .transfer {
  padding: 0.4rem 1.5rem;
  border-radius: 1rem;
  color: #0e73f6;
  background-color: #d7edff;
}
.admin-org-settings-wrap .three-box .last-box {
  justify-self: center;
  cursor: pointer;
  transition: all 0.3s;
}
.admin-org-settings-wrap .three-box .last-box:hover {
  transform: translateY(-0.1rem);
}
.admin-org-settings-wrap .three-box .text-title {
  color: #5b6871;
  font-size: 1.25rem;
}
.admin-org-settings-wrap .three-box-content {
  width: 100%;
}
.admin-org-settings-wrap .three-box-content .form-group {
  grid-column: 1/-1;
  width: 100%;
}
.admin-org-settings-wrap .three-box-content .form-group .select-wrap {
  margin-top: 0.4rem;
  color: #1d1d18 !important;
}
.admin-org-settings-wrap .three-box-content .form-group .select-wrap > *:nth-child(3) {
  padding: 0.3rem;
  border: 0.1rem solid var(--border-color);
  border-radius: 0.8rem;
  color: #1d1d18;
}
.admin-org-settings-wrap .three-box-content .form-group .select-wrap > *:nth-child(3) > *:nth-child(1) > *:nth-child(1) {
  color: #1d1d18;
}
.admin-org-settings-wrap .three-box-content .form-group .select-wrap-multi > *:nth-child(3) > * > *:not(:last-child) > * {
  color: #ffffff !important;
}
.admin-org-settings-wrap .title-three-box {
  background-color: #f6f8f9;
  padding: 1rem 4rem;
}
.admin-org-settings-wrap .card-box-billing-wrap {
  margin: 3rem 0rem;
  display: flex;
}
.admin-org-settings-wrap .card-box-billing-wrap .left-box {
  flex: 0 0 50%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.admin-org-settings-wrap .card-box-billing-wrap .left-box .plan-upgrade-box {
  background-color: var(--blue-color);
  border-radius: 1rem;
  padding: 2rem;
  width: 38rem;
  color: var(--white-color);
  position: relative;
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow-two);
}
.admin-org-settings-wrap .card-box-billing-wrap .left-box .plan-upgrade-box .time {
  font-size: 1rem;
  color: var(--border-color);
}
.admin-org-settings-wrap .card-box-billing-wrap .left-box .plan-upgrade-box .amount {
  margin: 0.6rem 0rem;
  font-weight: 500;
}
.admin-org-settings-wrap .card-box-billing-wrap .left-box .plan-upgrade-box .plan {
  font-size: 1.6rem;
}
.admin-org-settings-wrap .card-box-billing-wrap .left-box .plan-upgrade-box > * {
  position: relative;
  z-index: 3;
}
.admin-org-settings-wrap .card-box-billing-wrap .left-box .plan-upgrade-box::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/slash-plan-detail-img.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.admin-org-settings-wrap .card-box-billing-wrap .left-box .plan-upgrade-box .text-btn-box {
  display: flex;
  align-items: center;
  margin-bottom: auto;
}
.admin-org-settings-wrap .card-box-billing-wrap .left-box .plan-upgrade-box .text-btn-box .text {
  font-size: 2rem;
  font-weight: 500;
}
.admin-org-settings-wrap .card-box-billing-wrap .left-box .plan-upgrade-box .text-btn-box .btn-blue-wrap {
  margin-left: auto;
  margin-bottom: 0rem;
  margin-top: 0rem;
  background-color: var(--bg-white-color);
  color: var(--blue-color);
  padding: 0.9rem 2rem;
}
.admin-org-settings-wrap .card-box-billing-wrap .right-box {
  flex: 1;
}
.admin-org-settings-wrap .card-box-billing-wrap .right-box .payment-info-wrap {
  background-color: var(--bg-white-color);
  box-shadow: var(--box-shadow-two);
  border-radius: 1rem;
  padding: 2rem;
  width: 38rem;
  position: relative;
  min-height: 20rem;
  display: flex;
  flex-direction: column;
}
.admin-org-settings-wrap .card-box-billing-wrap .right-box .payment-info-wrap .top-box {
  margin-bottom: auto;
  display: flex;
  align-items: center;
}
.admin-org-settings-wrap .card-box-billing-wrap .right-box .payment-info-wrap .top-box .text {
  margin-right: auto;
  font-size: 2rem;
  font-weight: 500;
  color: var(--blue-color);
}
.admin-org-settings-wrap .card-box-billing-wrap .right-box .payment-info-wrap .top-box .img-box {
  margin: 0rem 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
}
.admin-org-settings-wrap .card-box-billing-wrap .right-box .payment-info-wrap .top-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
  filter: saturate(0%);
}
.admin-org-settings-wrap .card-box-billing-wrap .right-box .payment-info-wrap .top-box .input-switch {
  display: none;
}
.admin-org-settings-wrap .card-box-billing-wrap .right-box .payment-info-wrap .top-box .input-switch:checked ~ .label-switch {
  background-color: var(--blue-color);
}
.admin-org-settings-wrap .card-box-billing-wrap .right-box .payment-info-wrap .top-box .input-switch:checked ~ .label-switch > *:first-child {
  background-color: var(--blue-color);
}
.admin-org-settings-wrap .card-box-billing-wrap .right-box .payment-info-wrap .top-box .input-switch:checked ~ .label-switch > *:last-child {
  background-color: var(--bg-white-color);
}
.admin-org-settings-wrap .card-box-billing-wrap .right-box .payment-info-wrap .top-box .label-switch {
  cursor: pointer;
  width: 5rem;
  height: 2.5rem;
  background-color: #eceded;
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  box-shadow: var(--box-shadow-one);
}
.admin-org-settings-wrap .card-box-billing-wrap .right-box .payment-info-wrap .top-box .label-switch span {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-color: #eceded;
  border-radius: 50%;
  transition: all 0.3s;
}
.admin-org-settings-wrap .card-box-billing-wrap .right-box .payment-info-wrap .top-box .label-switch > *:first-child {
  margin-right: 0.5rem;
  background-color: var(--bg-white-color);
}
.admin-org-settings-wrap .card-box-billing-wrap .right-box .payment-info-wrap .top-box .auto {
  font-size: 1.45rem;
}
.admin-org-settings-wrap .card-box-billing-wrap .right-box .payment-info-wrap .name {
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.admin-org-settings-wrap .card-box-billing-wrap .right-box .payment-info-wrap .address {
  font-size: 1.15rem;
}
.admin-org-settings-wrap .card-box-billing-wrap .right-box .payment-info-wrap .num {
  margin: 0.5rem 0rem;
}
.admin-org-settings-wrap .group-budget-wrap {
  height: 40rem;
  display: grid;
  place-items: center;
}
.admin-org-settings-wrap .group-budget-wrap .content-index-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.admin-org-settings-wrap .group-budget-wrap .content-index-wrap .img-box {
  width: 12rem;
  height: 12rem;
}
.admin-org-settings-wrap .group-budget-wrap .content-index-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.admin-org-settings-wrap .group-budget-wrap .content-index-wrap .text-box {
  margin: 2rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.admin-org-settings-wrap .group-budget-wrap .content-index-wrap .text-box .text {
  font-size: 1.3rem;
}
.admin-org-settings-wrap .group-budget-wrap .content-index-wrap .btn-blue-wrap > *:last-child {
  margin-left: 0.7rem;
}
.admin-org-settings-wrap .check-label-box {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}
.admin-org-settings-wrap .check-label-box .check {
  display: none;
}
.admin-org-settings-wrap .check-label-box .check:checked ~ .label span {
  background-color: var(--blue-color);
  border: unset;
}
.admin-org-settings-wrap .check-label-box .check:checked ~ .label span .icon {
  visibility: visible;
}
.admin-org-settings-wrap .check-label-box .label {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  cursor: pointer;
}
.admin-org-settings-wrap .check-label-box .label span {
  margin-right: 1.5rem;
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 0.3rem;
  border: 0.1rem solid var(--border-color);
  display: grid;
  place-items: center;
  transition: all 0.3s;
}
.admin-org-settings-wrap .check-label-box .label span .icon {
  width: 1rem;
  height: 1rem;
  color: var(--white-color);
  visibility: hidden;
}
.admin-org-settings-wrap .select > * {
  background-color: var(--bg-white-color);
  color: #1d1d18;
}
.admin-org-settings-wrap .select > *:nth-child(3) {
  min-width: 9rem;
  background-color: var(--bg-white-color);
  border-radius: 0.7rem !important;
  border: 0.1rem solid var(--border-color);
  color: #1d1d18;
}
.admin-org-settings-wrap .select > *:nth-child(3) > *:nth-child(1) > *:nth-child(1) {
  color: #1d1d18;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}/*# sourceMappingURL=AdminSettings.css.map */