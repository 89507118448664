

.ownership-transfer-modal-wrap{
    width: 45rem;
    // min-height: 35rem;
    background-color: var(--bg-white-color);
    border-radius: 1rem;
    position: relative;
    padding:3rem;

    .cancel-box {
        position: absolute;
        top: 1rem;
        right: 1rem;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        justify-self: flex-start;
        display: grid;
        place-items: center;
        border: 0.15rem solid #cc0905;
        cursor: pointer;

        .icon {
          color: #cc0905;
        }
      }

      .wrap{
        display: flex;
        flex-direction: column;
        gap: 2.5rem;

        .heading{
            font-size: 2rem;
            font-weight: 600;
            color: #020202;
        }

        .select{
            &>*:nth-child(3){
                padding: .3rem;
            }
        }


        &>*:last-child{
            align-self: flex-end;
            
        }
      }
}