.final-reimbursement-modal-wrap {
  display: block;
  text-align: center;
  width: 600px;
  height: 278px;
  padding: 3rem;
  margin-bottom: 3rem;
  line-height: 3rem;
  // justify-content: center;

  .title {
    font-weight: 600;
    font-size: 2rem;
    color: #1d1d18;
  }
  .text {
    font-size: 1.45rem;
    line-height: 2.5rem;
    margin-top: 2rem;
  }
  .close-btn {
    background: #ffefeb;
    color: #cc0905;
    cursor: pointer;
    padding: 4px 12px;
    border-radius: 6px;
    margin-top: 3rem;
    border: none;
    transition: all 0.3s;
    &:hover {
      transform: translateY(-0.1rem);
    }
  }
}
