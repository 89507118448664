.actual-table-wrap {
  display: flex;
  flex-direction: column;
  position: relative;
}
.actual-table-wrap .title-btn-box {
  display: flex;
  align-items: center;
  border-bottom: unset;
  padding-bottom: unset;
  margin: 1rem 0rem 2rem 0rem;
}
.actual-table-wrap .title-btn-box .title {
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--black-color);
  text-transform: capitalize;
  margin-right: auto;
  display: flex;
  align-items: center;
}
.actual-table-wrap .title-btn-box .title span {
  font-weight: 300;
  color: #84919a;
}
.actual-table-wrap .title-btn-box .title .type-select-box {
  margin-left: 1rem;
  position: relative;
  z-index: 10;
}
.actual-table-wrap .title-btn-box .title .type-select-box .select {
  background-color: var(--bg-white-color);
  color: #1d1d18;
}
.actual-table-wrap .title-btn-box .title .type-select-box .select > *:nth-child(3) {
  height: 1rem;
  padding: 0rem 0.5rem;
  font-size: 1.3rem;
  border: 0.1rem solid var(--border-color);
  background-color: var(--bg-white-color);
}
.actual-table-wrap .title-btn-box .title .type-select-box .select > *:nth-child(3) > *:nth-child(1) > *:nth-child(1) {
  color: var(--black-color);
}
.actual-table-wrap .title-btn-box .title .type-select-box .select > * {
  font-size: 1.3rem;
  font-weight: 400;
}
.actual-table-wrap .title-btn-box .btn-input-box {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.actual-table-wrap .title-btn-box .btn-input-box > *:not(:last-child) {
  margin-right: 3rem;
}
.actual-table-wrap .title-btn-box .btn-input-box .search-box {
  background-color: var(--bg-white-color);
  border-radius: 0.9rem;
  display: flex;
  align-items: center;
  border: 0.1rem solid var(--border-color);
  overflow: hidden;
  padding: 0rem 1rem;
}
.actual-table-wrap .title-btn-box .btn-input-box .search-box .input {
  border: none;
  flex: 1;
  padding: 1.2rem;
  background-color: var(--bg-white-color);
  color: var(--black-color);
}
.actual-table-wrap .title-btn-box .btn-input-box .search-box .input:focus {
  outline: none;
  border: none;
}
.actual-table-wrap .title-btn-box .btn-input-box .search-box .input::-moz-placeholder {
  color: #84919a;
}
.actual-table-wrap .title-btn-box .btn-input-box .search-box .input::placeholder {
  color: #84919a;
}
.actual-table-wrap .title-btn-box .btn-input-box .search-box .label {
  flex: 0 0 8%;
  display: grid;
  place-items: center;
}
.actual-table-wrap .title-btn-box .btn-input-box .search-box .label .icon {
  color: #b0babf;
}
.actual-table-wrap .title-btn-box .btn-input-box .select {
  background-color: var(--dark-fade-color);
  border-radius: 0.9rem;
  display: flex;
  align-items: center;
  border: 0.1rem solid var(--border-color);
  overflow: hidden;
  padding: 1.2rem 1rem;
  width: 15rem;
  color: #6e7c87;
  outline: none;
  border: none;
}
.actual-table-wrap .title-btn-box .btn-input-box .select:focus {
  outline: none;
  border: none;
}
.actual-table-wrap .title-btn-box .btn-input-box .select-filter-by {
  width: 15rem;
  color: #6e7c87;
  position: relative;
  z-index: 10;
  background-color: var(--bg-white-color);
  color: #1d1d18;
}
.actual-table-wrap .title-btn-box .btn-input-box .select-filter-by > *:nth-child(3) {
  background-color: var(--dark-fade-color);
  border-radius: 0.9rem;
  border: 0.1rem solid var(--border-color);
}
.actual-table-wrap .title-btn-box .btn-input-box .select-filter-by > *:nth-child(3) > *:nth-child(1) > *:nth-child(1) {
  color: var(--black-color);
}
.actual-table-wrap .title-btn-box .btn-input-box .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: var(--blue-color);
  border: none;
  outline: none;
  cursor: pointer;
  padding: 1.2rem 3rem;
  transition: all 0.3s;
  font-weight: 500;
  font-size: 1.2rem;
  border-radius: 0.6rem;
  text-transform: capitalize;
}
.actual-table-wrap .title-btn-box .btn-input-box .btn:hover {
  transform: translateY(-0.1rem);
}
.actual-table-wrap .title-btn-box .btn-input-box .btn .icon {
  margin-right: 1rem;
  color: currentColor;
}
.actual-table-wrap .title-btn-box .btn-input-box .btn-bulk-upload {
  background-color: var(--bg-white-color);
  border: 0.1rem solid var(--blue-color);
  color: var(--blue-color);
}
.actual-table-wrap .table {
  table-layout: auto;
  width: 100%;
  border: none;
}
.actual-table-wrap .table thead tr {
  background-color: var(--bg-white-color);
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: var(--box-shadow-two);
}
.actual-table-wrap .table thead tr .action-td {
  text-align: right;
}
.actual-table-wrap .table thead tr th {
  padding: 1rem 2rem;
  font-size: 1.2rem;
  text-align: left;
  text-transform: capitalize;
  color: var(--black-color);
}
.actual-table-wrap .table thead tr th .table-check-wrap-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.actual-table-wrap .table thead tr th .table-check-wrap-box .table-check-input-row {
  display: none;
}
.actual-table-wrap .table thead tr th .table-check-wrap-box .table-check-input-row:checked ~ .table-check-label-input-row {
  background-color: var(--blue-color);
  border: 0.1rem solid var(--blue-color);
}
.actual-table-wrap .table thead tr th .table-check-wrap-box .table-check-input-row:checked ~ .table-check-label-input-row .icon {
  visibility: visible;
}
.actual-table-wrap .table thead tr th .table-check-wrap-box .table-check-label-input-row {
  width: 1.5rem;
  height: 1.5rem;
  display: grid;
  place-items: center;
  cursor: pointer;
  transform: translateY(0.7rem);
  background-color: #f6f8f9;
  border-radius: 0.3rem;
  border: 0.1rem solid var(--border-color);
}
.actual-table-wrap .table thead tr th .table-check-wrap-box .table-check-label-input-row .icon {
  width: 0.75rem;
  height: 0.75rem;
  fill: #ffffff;
  visibility: hidden;
}
.actual-table-wrap .table thead tr > *:first-child {
  width: 2rem;
  display: grid;
  place-items: center;
  padding-left: 2rem;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  margin-right: -2.2rem;
}
.actual-table-wrap .table thead tr > *:first-child span {
  background-color: #f6f8f9;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.3rem;
  border: 0.1rem solid var(--border-color);
  display: inline-block;
}
.actual-table-wrap .table tbody tr {
  position: relative;
}
.actual-table-wrap .table tbody tr > *:not(:first-child) {
  border-bottom: 0.1rem solid var(--border-color);
}
.actual-table-wrap .table tbody tr td {
  padding: 1.5rem 2rem;
  font-size: 1.2rem;
  text-align: left;
  font-size: 1.3rem;
  color: #84919a;
  text-transform: capitalize;
}
.actual-table-wrap .table tbody tr td .table-check-wrap-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.actual-table-wrap .table tbody tr td .table-check-wrap-box .table-check-input-row {
  display: none;
}
.actual-table-wrap .table tbody tr td .table-check-wrap-box .table-check-input-row:checked ~ .table-check-label-input-row {
  background-color: var(--blue-color);
  border: 0.1rem solid var(--blue-color);
}
.actual-table-wrap .table tbody tr td .table-check-wrap-box .table-check-input-row:checked ~ .table-check-label-input-row .icon {
  visibility: visible;
}
.actual-table-wrap .table tbody tr td .table-check-wrap-box .table-check-label-input-row {
  width: 1.5rem;
  height: 1.5rem;
  display: grid;
  place-items: center;
  cursor: pointer;
  transform: translateY(0.7rem);
  background-color: #f6f8f9;
  border-radius: 0.3rem;
  border: 0.1rem solid var(--border-color);
}
.actual-table-wrap .table tbody tr td .table-check-wrap-box .table-check-label-input-row .icon {
  width: 0.75rem;
  height: 0.75rem;
  fill: #ffffff;
  visibility: hidden;
}
.actual-table-wrap .table tbody tr td .img-data-name {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  background-color: var(--blue-color);
  align-items: center;
}
.actual-table-wrap .table tbody tr td .img-data-name > *:first-child {
  margin-right: 0.1rem;
}
.actual-table-wrap .table tbody tr td .img-data-name span {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--white-color);
}
.actual-table-wrap .table tbody tr td .user-box {
  display: flex;
  align-items: center;
}
.actual-table-wrap .table tbody tr td .user-box .img-box {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.8rem;
}
.actual-table-wrap .table tbody tr td .user-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.actual-table-wrap .table tbody tr td .drop-wrap {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.actual-table-wrap .table tbody tr td .drop-wrap .drop-icon {
  color: #b0babf;
  width: 1rem;
  height: 1.5rem;
  cursor: pointer;
}
.actual-table-wrap .table tbody tr td .drop-wrap .drop-box {
  position: absolute;
  top: 110%;
  right: 1.5rem;
  width: 17rem;
  border-radius: 0.8rem;
  box-shadow: var(--box-shadow-one);
  border: 0.1rem solid var(--border-color);
  transition: all 0.2s linear;
  background-color: var(--bg-white-color);
  padding: 2rem 3rem;
  transform: scale(0);
  transform-origin: top right;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.actual-table-wrap .table tbody tr td .drop-wrap .drop-box > *:not(:last-child) {
  margin-bottom: 1.5rem;
}
.actual-table-wrap .table tbody tr td .drop-wrap .drop-box .text {
  color: var(--black-color);
  font-size: 1.3rem;
  cursor: pointer;
  text-transform: capitalize;
}
.actual-table-wrap .table tbody tr td .drop-wrap .drop-box-show {
  transform: scale(1);
}
.actual-table-wrap .table tbody tr td .progress-text-wrap .text {
  margin-top: 0.5rem;
}
.actual-table-wrap .table tbody tr td .progress-text-wrap .progress-wrap {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1rem, 1fr));
  grid-column-gap: 0.2rem;
  justify-content: center;
  align-items: center;
}
.actual-table-wrap .table tbody tr td .progress-text-wrap .progress-wrap .status-progress-box {
  position: relative;
  cursor: pointer;
}
.actual-table-wrap .table tbody tr td .progress-text-wrap .progress-wrap .status-progress-box:hover .name-box {
  visibility: visible;
}
.actual-table-wrap .table tbody tr td .progress-text-wrap .progress-wrap .status-progress-box .name-box {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(1rem);
  background-color: #ffffff;
  border-radius: 0.7rem;
  padding: 1rem;
  box-shadow: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.05);
  visibility: hidden;
  transition: visibility 0.1s ease-in-out;
}
.actual-table-wrap .table tbody tr td .progress-text-wrap .progress-wrap .status-progress-box .name-box::after {
  content: "";
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-100%, -50%);
  width: 1.5rem;
  height: 1.5rem;
  background-color: #ffffff;
  box-shadow: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.05);
  -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
          clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
.actual-table-wrap .table tbody tr td .progress-text-wrap .progress-wrap .status-progress-box .name-box .name {
  color: var("--blue-color") !important;
  font-size: 1.2rem;
  text-transform: capitalize;
  white-space: nowrap;
  font-weight: 600;
}
.actual-table-wrap .table tbody tr > *:first-child {
  width: 2rem;
  display: grid;
  place-items: center;
  padding-left: 2rem;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  margin-right: 0.5rem;
  padding-right: 0rem;
}
.actual-table-wrap .table tbody tr > *:first-child span {
  background-color: #f6f8f9;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.3rem;
  border: 0.1rem solid var(--border-color);
  display: inline-block;
}
.actual-table-wrap .table tbody tr p {
  display: flex;
  align-items: center;
}
.actual-table-wrap .table tbody tr p span {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-color: currentColor;
  border-radius: 50%;
  margin-right: 0.6rem;
}
.actual-table-wrap .table tbody tr .img-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.actual-table-wrap .table tbody tr .img-wrap > *:not(:first-child) {
  margin-left: -1rem;
}
.actual-table-wrap .table tbody tr .img-wrap .img-data-name-two {
  background-color: #2a242b;
}
.actual-table-wrap .table tbody tr .img-wrap .img-box {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 1rem;
  overflow: hidden;
  border: 0.15rem solid var(--border-color);
}
.actual-table-wrap .table tbody tr .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.actual-table-wrap .table tbody tr .img-wrap .other {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 1rem;
  overflow: hidden;
  border: 0.15rem solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blue-color);
  font-size: 1.2rem;
  background-color: var(--bg-white-color);
  font-weight: 600;
}
.actual-table-wrap .table tbody tr .img-wrap .other .icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.3rem;
}
.actual-table-wrap .pagination-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 3rem;
}
.actual-table-wrap .pagination-box > *:not(:last-child) {
  margin-right: 0.7rem;
}
.actual-table-wrap .pagination-box .next-prev-box {
  margin-left: 2rem;
  margin-bottom: 0rem;
  align-items: center;
  display: flex;
  gap: 0.8rem;
}
.actual-table-wrap .pagination-box .next-prev-box .icon {
  color: var(--white-color);
  width: 2.5rem;
  height: 2.5rem;
  background-color: #b0babf;
  border-radius: 0.5rem;
  display: grid;
  place-items: center;
  padding: 0.5rem;
  transition: all 0.3s;
}
.actual-table-wrap .pagination-box .next-prev-box .icon-active {
  background-color: #b0babf;
  cursor: pointer;
  color: #ffffff;
}
.actual-table-wrap .pagination-box .next-prev-box .icon-active:hover {
  transform: scale(1.03) translateX(0.1rem);
  background-color: var(--blue-color);
}
.actual-table-wrap .pagination-box .next-prev-box .icon-active > *:first-child:hover {
  transform: scale(1.03) translateX(-0.1rem);
}
.actual-table-wrap .pagination-box .next-prev-box .current {
  background-color: #b0babf;
  cursor: default;
}
.actual-table-wrap .pagination-box .next-prev-box .current:hover {
  transform: unset;
  background-color: #b0babf;
}
.actual-table-wrap .pagination-box .next-prev-box .current-chi {
  background-color: var(--blue-color);
}
.actual-table-wrap .pagination-box .next-prev-box .current-chi:hover {
  background-color: var(--blue-color);
}/*# sourceMappingURL=ActualReuseableTable.css.map */