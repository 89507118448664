.overview-card-modal-wrap {
  min-width: 40rem;
  min-height: 60rem;
  max-height: 80vh;
  background-color: var(--bg-white-color);
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  overflow-y: scroll;
  padding-bottom: 3rem;

  .top-box {
    background-color: #ffffff;
    height: 6rem;
    border-bottom: 0.1rem solid var(--border-color);
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    // display: none;

    .title-box {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      margin-right: auto;

      .title {
        font-size: 1.35rem;
        font-weight: 600;
        color: var(--black-color);
        margin-right: auto;
        cursor: pointer;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          height: 0.25rem;
          background-color: var(--blue-color);
          border-radius: 1rem;
          visibility: hidden;
          transform: translateY(1rem);
        }
      }

      .title-active::after {
        visibility: visible;
      }
    }

    .ellipse-box {
      margin: 0rem 2.5rem;
      position: relative;

      .icon {
        cursor: pointer;
      }

      .action-box {
        position: absolute;
        top: 100%;
        right: 0;
        height: 12rem;
        width: 15rem;
        border-radius: 1rem;
        background-color: var(--bg-white-color);
        box-shadow: var(--box-shadow-two);
        display: grid;
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: 1fr;
        padding: 0.5rem 1.5rem;
        font-size: 1.3rem;
        align-self: center;
        transform: scale(0);
        transition: all 0.3s;
        transform-origin: top right;

        & > *:first-child {
          color: #cc0905;
        }

        & > *:not(:last-child) {
          border-bottom: 0.1rem solid var(--border-color);
        }

        & > * {
          cursor: pointer;
          display: flex;
          align-items: center;

          span {
            display: inline-block;
          }
        }
      }

      .action-box-active {
        transform: scale(1);
      }
    }

    .btn-blue-wrap {
      padding: 0.9rem 2rem;
    }

    .cancel-box {
      background-color: #ffefeb;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      display: grid;
      place-items: center;
      cursor: pointer;

      .icon {
        color: #cc0905;
      }
    }
  }

  .bottom-box {
    // display: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0rem 2rem;
    border-top: 0.1rem solid var(--border-color);
    padding-top: 1.5rem;
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    // flex: 1;
    // overflow-y: scroll;

    & > *:first-child {
      margin-right: 2rem;
      background-color: var(--bg-white-color);
      border: 0.1rem solid var(--blue-color);
      color: var(--blue-color);
    }
  }

  .bottom-box-two {
    background-color: var(--bg-white-color);
    border-top: 0.1rem solid var(--border-color);
    padding-top: 1.5rem;
    padding: 2rem;
    flex: 1;
    margin-top: -2rem;
    display: flex;
    flex-direction: column;
    max-height: 45rem;
    overflow-y: scroll;
    padding-top: 3rem;
    // display: none;
    overflow-y: scroll;
    & > *:not(:last-child) {
      margin-bottom: 2rem;
    }
    &::-webkit-scrollbar {
      width: 0.5rem;
    }

    &::-webkit-scrollbar-track {
      background-color: #ffffff;
      -webkit-border-radius: 10px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: var(--blue-color);
    }

    .comment-wrap {
      width: 100%;
      background-color: var(--bg-white-color);
      border-radius: 0.8rem;
      padding: 2rem 2.5rem;
      display: flex;
      box-shadow: var(--box-shadow-one);

      .img-box {
        width: 3rem;
        height: 3rem;

        .img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }

      .box-wrap {
        margin-left: 1rem;
        display: flex;
        flex-direction: column;
        flex: 1;

        .top-box-wrap {
          display: flex;
          // align-items: center;
          .box {
            font-size: 1.3rem;
            margin-right: auto;

            .text {
              color: #5b6871;
              margin-bottom: 0.8rem;
            }

            .value {
              color: var(--black-color);
              font-weight: 600;
            }
          }

          .reject-box {
            background-color: #ffefeb;
            color: #cc0905;
            padding: 0.7rem 2.5rem;
            border-radius: 0.8rem;
            // cursor: pointer;
            align-self: center;
            margin-right: 3rem;
            p {
              text-transform: capitalize;
              font-weight: 500;
            }
          }

          .pending {
            background-color: rgba(252, 165, 73, 0.1);

            color: #fca549;
          }

          .approved {
            background-color: rgba(7, 162, 135, 0.1);

            color: #07a287;
          }

          .delete-icon {
            display: inline-block;
            cursor: pointer;
            align-self: center;
          }
        }

        .comment {
          margin-top: 2rem;
          color: #5b6871;
        }
      }
    }
  }

  .content-box {
    background-color: var(--bg-white-color);
    flex: 1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    max-width: 45rem;
    // background-color: red;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0.5rem;
    }

    &::-webkit-scrollbar-track {
      background-color: #ffffff;
      -webkit-border-radius: 10px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: var(--blue-color);
    }
    .activity-log-wrap-box {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      // background-color: red;
      flex: 1;
      overflow-y: scroll;

      // &::-webkit-scrollbar {
      //   display: none;
      // }
      &::-webkit-scrollbar {
        width: 0.5rem;
      }

      &::-webkit-scrollbar-track {
        background-color: #ffffff;
        -webkit-border-radius: 10px;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: var(--blue-color);
      }

      .log-box {
        display: flex;
        align-items: flex-start;

        .num {
          font-weight: 800;
          color: #5b6871;
          font-size: 1.45rem;
          transform: translateY(1rem);
        }

        .img-wrap {
          margin-left: 3rem;
          display: grid;
          place-items: center;

          .img-box {
            width: 3.5rem;
            height: 3.5rem;
            border-radius: 50%;

            .img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              object-fit: contain;
            }
          }
        }

        .dash {
          width: 3rem;
          height: 0.15rem;
          background-color: #dde2e4;
          transform: translateY(1.8rem);
          margin: 0rem 0.8rem;
        }

        .log-detail-wrap {
          display: flex;
          flex-direction: column;
          gap: 0.7rem;
          color: #000000;
          transform: translateY(1rem);

          .title {
            font-weight: 600;
            font-size: 1.6rem;
            text-transform: capitalize;
          }

          .initiator {
            font-size: 1.3rem;
            font-weight: 300;
            opacity: 0.6;
          }

          .time {
            font-size: 1.3rem;
            font-weight: 300;
            opacity: 0.6;
          }

          .date {
            font-size: 1.3rem;
            font-weight: 300;
            opacity: 0.6;
          }
        }
      }

      .logo-box-border {
        position: relative;

        & > * {
          position: relative;
          z-index: 2;
        }

        &::after {
          content: "";
          position: absolute;
          height: 100%;
          z-index: 1;
          top: 50%;
          transform: translateY(-12%);
          left: 15%;
          width: 0.1rem;
          background-color: #dde2e4;
        }
      }
    }

    .img-box {
      width: 3rem;
      height: 3rem;

      .img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    .btn-box {
      display: flex;
      justify-content: flex-end;
    }

    .img-input-wrap {
      display: flex;

      .input-text {
        padding: 2rem;
        border: 0.1rem solid var(--border-color);
        border-radius: 0.5rem;
        margin-left: 1.5rem;
        color: #84919a;
        flex: 1;

        &::placeholder {
          color: #84919a;
        }

        &:focus {
          border: 0.1rem solid var(--border-color);
          outline: none;
        }
      }
    }

    .image-wrap-box {
      min-height: 10rem;
      display: grid;
      grid-template-columns: repeat(3, min-content);
      grid-column-gap: 1rem;
      background-color: var(--bg-white-color);
      padding: 1rem;

      .box-img {
        height: 10rem;
        overflow-y: hidden;
        cursor: pointer;

        .img-box {
          width: 13rem;
          height: 13rem;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }

    .title-drop-control-box {
      display: flex;
      align-items: center;
      cursor: pointer;

      .title {
        color: var(--blue-color);
        font-size: 1.3rem;
      }

      .icon-box {
        margin-left: auto;
        display: grid;
        place-items: center;

        .icon {
          width: 2rem;
          height: 2rem;
          color: #b0babf;
        }
      }
    }

    .content-wrap {
      margin-top: 2rem;
      // border-bottom: 0.1rem solid var(--border-color);
      padding-bottom: 1.5rem;

      & > *:not(:last-child) {
        margin-bottom: 2rem;
        border: none;
      }

      .three-box {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }

      .two-box {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }

      .box {
        font-size: 1.3rem;

        .text {
          color: #5b6871;
          margin-bottom: 0.8rem;
        }

        .value {
          color: var(--black-color);
          font-weight: 600;
        }
      }
    }
  }
}

.box-file {
  background-color: var(--bg-white-color);
  box-shadow: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: 1.3rem;
  padding: 1rem 2rem;

  .type {
    span {
      font-weight: 800;
      text-transform: uppercase;
    }
  }

  .name {
    span {
      font-weight: 800;
    }
  }
}

.overview-card-modal-wrap-single {
  width: 80%;
  max-width: unset;
  margin: 2rem auto;
  height: unset;
  max-height: unset;

  .content-box{
    // display: none !important;
    width: 60%;
    // background-color: red;
    max-width: unset;
    align-self: center;
  }
}

// activity log style update
.top-box-title {
  display: flex;
  gap: 3rem;
  cursor: pointer;
}
.top-box-title-active {
  background-color: var(--bg-white-color);
  box-shadow: var(--box-shadow-two);
  position: relative;
  padding: 1rem;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 0.5rem;
    width: 70%;
    left: 50%;
    background-color: var(--blue-color);
    transform: translateX(-50%);
    border-radius: 5rem;
  }
}

// approve modal
.emp-approval-modal {
  min-height: 30rem;
  width: 52rem;
  position: relative;
  overflow: hidden;
  .title {
    font-weight: 600;
    font-size: 1.5rem;
  }

  .cancel-box {
    position: absolute;
    right: 0;
    margin-right: 2rem;
  }
  .bottom-box-two {
    margin-top: 2rem;
  }
}

.emp-activity-wrap {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-self: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  position: relative;
  .hr-line {
    border-left: 0.1rem solid var(--border-color);
    height: 10rem;
    position: absolute;
    right: 19.5rem;
    top: 3rem;
    // background-color: red;
  }
  .vr-line {
    border-top: 0.1rem solid var(--border-color);
    width: 2rem;
    position: absolute;
    right: 15.5rem;
    top: 1rem;
    // background-color: red;
  }
  .activ-num {
    font-weight: 400;
    display: flex;
    position: absolute;
    right: 23rem;
    // top:8rem
  }
  .activ-img {
    position: absolute;
    right: 18rem;
    bottom: 7rem;
  }

  .activ-title {
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--black-color);
  }
}
