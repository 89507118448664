.integration-wrap {
  padding: 4rem;
  // display: flex;
  // flex-direction: column;
  .title {
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--black-color);
    margin-bottom: 2rem;
    text-transform: capitalize;
  }
  .wrapper {
    border-bottom: 0.1rem solid var(--border-color);
    padding-bottom: 1rem;
    display: flex;
    margin-top: 2rem;
    align-items: center;
  }
  .tools-wrap {
    display: flex;
    gap: 3rem;
    // grid-template-columns: 0.1fr 0.1fr 1fr;
    padding: 3rem;

    .tools {
      cursor: pointer;
      padding: 1.5rem;
      background-color: var(--white-color);
      border-radius: 5px;
      box-shadow: 10px 8px 50px rgba(0, 0, 0, 0.04);
      transition: all 0.3s;
      border: 0.1rem solid var(--white-color);

      &:hover {
        border: 0.1rem solid var(--blue-color);
        // transform: scale(0.8);
      }
    }
  }
  .zoho-box {
    display: flex;
    justify-content: space-between;
    .zoho-left-box {
      display: flex;
      gap: 0.5rem;

      .text {
        cursor: pointer;
      }

      .icon {
        margin-top: 0.3rem;
      }
      .active-text {
        font-weight: 600;
        color: var(--black-color);
      }
    }
    .zoho-right-box {
      display: flex;
      gap: 1.5rem;
      .cancel-btn {
        padding: 4px 12px;
        outline: none;
        border: 0.1rem solid var(--blue-color);
        background-color: var(--white-color);
        border-radius: 0.5rem;
        color: var(--blue-color);
        transition: all 0.3s;
        cursor: pointer;
        &:hover {
          transform: translateY(-0.1rem);
        }
      }
      .connect-btn {
        padding: 4px 12px;
        outline: none;
        border: 0.1rem solid var(--blue-color);
        background-color: var(--blue-color);
        border-radius: 0.5rem;
        color: var(--blue-color);
        color: #ffff;
        transition: all 0.3s;
        cursor: pointer;
        &:hover {
          transform: translateY(-0.1rem);
        }
      }
    }
  }
  .connected-platform {
    margin-top: 3rem;
    .zoho-left-box {
      display: flex;
      justify-content: space-between;
    }
    .connected-title {
      font-weight: 600;
      font-size: 2rem;
    }
    .hover-wrap {
      display: flex;
      flex-direction: column;
      margin-top: -3rem;

      .hover-box {
        position: absolute;
        right: 4rem;
        padding-top: 1.3rem;
        margin-right: 10rem;
        background-color: #ffffff;
        box-shadow: 30px 30px 55px rgba(0, 0, 0, 0.04);
        width: 20rem;
        height: 4rem;
        padding-left: 1.3rem;
        font-size: 1.3rem;
        margin-top: 0rem;
      }
    }

    .hover-clip {
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      background-color: #ffffff;
      box-shadow: 30px 15px 55px rgba(0, 0, 0, 0.04);
      width: 2rem;
      height: 2rem;
      position: absolute;
      right: 23.5rem;
      top: 33.3rem;
      // margin-top:15rem;
      transform: rotate(180deg);
    }

    .zoho-wrap {
      background-color: #ffff;
      box-shadow: 10px 8px 50px rgba(0, 0, 0, 0.04);
      margin-top: 1rem;
      padding: 2rem;
      border-radius: 1rem;
      // .clip {
      //   position: absolute;
      //   bottom: 48%;
      //   right: 57;
      //   top: 43%;

      //   // padding:1rem;
      // }
      // .support-text-wrap {
      //   box-shadow: 10px 8px 50px rgba(0, 0, 0, 0.04);
      //   background-color: #ffffff;
      //   width: 20rem;
      //   height: 4rem;
      //   place-content: center;
      //   display: flex;
      //   padding-top: 0.9rem;
      //   position: absolute;
      //   top: 39.5%;
      //   right: 10%;

      //   font-size: 1.3rem;
      // }

      .help-img {
        margin-right: 3.5rem;
        margin-top: 0.5rem;
      }
    }
  }
}
