.employee-support-index-wrap {
  padding: 4rem;

  .support-header {
    font-weight: 600;
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
    color: var(--black-color);
  }
  .support-section-one {
    display: block;
    justify-content: center;
    text-align: center;

    .header-one {
      font-weight: 600;
      font-size: 3.5rem;
      margin-top: 3.5rem;
      color: var(--black-color);
    }
    input {
      width: 50%;
      overflow: hidden;
      border: none;
      background-color: var(--bg-white-color);
      border-radius: 0.9rem;
      border: 0.1rem solid var(--border-color);
      padding: 1.1rem 1rem;
      padding-right: 3.3rem;
      margin-top: 2rem;

      &:focus {
        outline: none;
        // border: none;
      }

      &::placeholder {
        color: #84919a;
      }
    }
    label {
      .icon {
        color: #b0babf;
        position: relative;
        right: 2.1em;
      }
    }
  }
  .employee-support-article-wrap {
    display: flex;
    gap: 7rem;
    justify-content: center;
    margin-top: 7rem;
  }
  .employee-support-article {
    gap: 1rem;
    background-color: var(--bg-white-color);
    box-shadow: var(--box-shadow-two);
    padding: 2rem;
    width: 40%;
    height: 40%;
    border-radius: 4px;

    .article-title {
      font-weight: 500;
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
      color: var(--black-color);
    }
  }
  .employee-support-footer-wrap {
    margin-top: 22rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 5rem;
    width: 100%;
    padding-left: 8rem;
    justify-content: center;

    .support-details {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
    .detail-title {
      font-weight: 500;
      font-size: 1.5rem;
      color: var(--black-color);
    }
  }
  .support-copyright-wrap {
    display: grid;
    padding-left: 8rem;
    margin-top: 5rem;
    grid-template-columns: 3fr 1fr;
  }
}
.emp-support-get-help-box {
  position: fixed;
  background-color: var(--blue-color);
  padding: 1.5rem 2rem;
  border-radius: 5rem;
  justify-content: right;
  top: 58rem;
  right: 5rem;
  left: auto;
  display: flex;
  gap: 1rem;
  align-items: center;
  color: var(--white-color);
  cursor: pointer;
  margin-top: auto;
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-0.2rem);
  }

  .img-box {
    width: 2rem;
    height: 2rem;

    .img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
    }
  }

  .help-text {
    margin-right: 1rem;
    font-weight: 600;
  }
}

// support sidebar dropdown
.emp-support-dropdown-wrap {
  position: fixed;
  background-color: var(--bg-white-color);
  box-shadow: var(--box-shadow-two);
  // transition: transform 0.3s linear;
  width: 18rem;
  height: 18rem;
  border-radius: 1.5rem;
  display: grid;
  gap: 2rem;
  top: 33%;
  cursor: pointer;
  left: 27rem;
  transition: all 0.3s linear;
  transform: scale(0);
  transform-origin: bottom left;
  grid-template-rows: repeat(4, 1fr);

  p {
    border-bottom: 1px solid #dde2e4;
    margin-top: 1rem;
    padding-left: 3rem;
    padding-bottom: 2rem;
    gap: 3rem;
    &:nth-child(3) {
      border-bottom: none;
    }
  }

  .emp-arrow-box {
    position: absolute;
    width: 2rem;
    height: 1.8rem;
    background-color: var(--bg-white-color);
    transform: rotate(-90deg);
    box-shadow: var(--box-shadow-two);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    bottom: 25%;
    right: 17.7rem;
  }
}

.support-dropdown-show {
  transform: scale(1);
  // transition: transform 0.3s linear;
  // background-color: red;
}

.emp-support-dropdown-wrap-collapse{
  left: 10rem;
  top: 30%;
}
