.approval-setting-modal-wrap {
  width: 45rem;
  min-height: 35rem;
  border-radius: 1rem;
  background-color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5rem 4rem;
  position: relative;
}
.approval-setting-modal-wrap .cancel-box {
  width: 3rem;
  height: 3rem;
  background-color: #dde2e4;
  position: absolute;
  top: 2rem;
  right: 2rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.3s;
}
.approval-setting-modal-wrap .cancel-box:hover {
  transform: scale(1.05);
}
.approval-setting-modal-wrap .cancel-box .icon {
  width: 2rem;
  height: 2rem;
  color: #1D1D18;
}
.approval-setting-modal-wrap .img-box {
  width: 20rem;
  height: 10rem;
}
.approval-setting-modal-wrap .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
}
.approval-setting-modal-wrap .title {
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 1.5rem;
}
.approval-setting-modal-wrap .text {
  font-size: 1.3rem;
}
.approval-setting-modal-wrap .button-blue-wrap {
  width: 50%;
  margin-top: 3rem;
}
.approval-setting-modal-wrap .btn-box {
  display: flex;
  align-items: center;
  margin-top: 3rem;
}
.approval-setting-modal-wrap .btn-box .btn-white {
  margin-right: 2rem;
  border: 0.1rem solid var(--blue-color);
  border-radius: 0.4rem;
  padding: 1rem 2rem;
  background-color: var(--white-color);
  color: var(--blue-color);
  transition: all 0.3s;
  font-weight: 600;
  font-size: 1.2rem;
  border-radius: 0.6rem;
  text-transform: capitalize;
  cursor: pointer;
}
.approval-setting-modal-wrap .btn-box .btn-white:hover {
  transform: translateY(-0.1rem);
}
.approval-setting-modal-wrap .btn-box .button-blue-wrap {
  width: unset;
  margin-top: 0rem;
  margin-bottom: unset;
}/*# sourceMappingURL=ApprovalSettingModal.css.map */