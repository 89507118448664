


.delete-shared-modal-wrap{
    width: 55rem;
    background-color: var(--bg-white-color);
    padding: 3rem ;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    // align-items: center;
    justify-content: center;

    &>*:not(:last-child){
        margin-bottom: 2rem;
    }

    .title{
        font-weight: 600;
        font-size: 2rem;
        color: var(--black-color);
    }

    .text{
        font-size: 1.45rem;
    }

    .text-two{
        margin-top: -1rem;
    }


    .btn-box{
        // margin-top: 3rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &>*:first-child{
            margin-right: 2rem;
            background-color: var(--bg-white-color);
            border: .1rem solid var(--blue-color);
            color: var(--blue-color);
        }

    }


    
}