.emp-columns-wrap {
  display: grid;
  grid-template-columns: 6fr 5fr 1fr;
  gap: 3rem;

  .sub-title {
    font-weight: 500;
    font-size: 1.7rem;
    margin-bottom: 1rem;
    color: var(--black-color);
  }

  .select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; /* Remove default arrow */
    background-image: url("../../../../assets/drop-down-icon.svg");
    background-repeat: no-repeat; /* Add custom arrow */
    background-position: 95% center;
    background-size: 2rem;
  }
  .input {
    border: 0.1rem solid var(--border-color);
    border-radius: 0.8rem;
    padding: 1.3rem 1rem;
    outline: none;
    width: 100%;
    font-size: 1.4rem;
    //   background-color: transparent;
    font-size: 1.35rem;
    color: var(--black-shade-color);

    &::placeholder {
      font-size: 1.2rem;
      text-transform: capitalize;
    }
  }
  .emp-projection-wrap {
    //   height: 100%;
    width: 100%;
    border-radius: 6px;
    // margin-top: 4rem;
    background-color: var(--bg-white-color);
    box-shadow: var(--box-shadow-two);

    .list-text {
      margin-top: -4px;
      padding: 1rem;
      padding-left: 4rem;
      cursor: pointer;

      &:hover {
        background-color: var(--blue-color);
        color: var(--white-color);
        width: 100%;
        border-radius: 3px;
      }
    }
  }

  .emp-delete-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
}
