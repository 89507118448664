.header-nav-wrap {
  width: 100%;
  height: 100%;
  // background-color: red;
  display: flex;
  align-items: center;
  position: relative;
  padding: 1rem 4rem;

  .sidebar-toggle-icon {
    position: absolute;
    top: 50%;
    left: -2rem;
    transform: translate(-100%, -50%);
    transition: all 0.3s;
    // transform: unset;
    // margin-right: 2rem;
    // background-color: red;
    .img-box {
      width: 3rem;
      height: 3rem;
      cursor: pointer;
      // filter: brightness(0%);

      .img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        display: block;
      }
    }
  }

  .sidebar-toggle-icon-reduce {
    left: 0;
    transform: translate(0%, -50%);

    .img-box {
      filter: brightness(0%);
      transform: rotate(180deg);
    }
  }

  .sidebar-toggle-icon-reduce-dark {
    // left: 0;
    // transform: translate(0%, -50%);

    .img-box {
      filter: brightness(1000%);
      // transform: rotate(180deg);
    }
  }

  .back-box {
    background-color: var(--back-bg-color);
    width: 4.5rem;
    height: 3.5rem;
    border-radius: 1.5rem;
    // padding: .5rem;
    display: grid;
    place-items: center;
    cursor: pointer;
    box-shadow: var(--box-shadow-one);
    margin-right: 2rem;

    &:hover .img-box {
      transform: translateX(-0.2rem);
    }

    .img-box {
      width: 3.5rem;
      height: 1.5rem;
      transition: all 0.3s;

      .img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }

  .page-title-box {
    color: var(--black-color);
    font-weight: 700;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    text-transform: capitalize;

    span {
      color: var(--blue-color);
      margin-left: 1.5rem;
      font-size: 1.5rem;
      font-weight: 600;
      display: inline-block;
    }
  }

  .currency-select-box {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding: 0rem 1rem;
    border: 0.15rem solid var(--border-color);
    border-radius: 2rem;
    margin-right: 2.5rem;

    .img-box {
      margin-right: 1rem;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      overflow: hidden;

      .img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    .select-input {
      border: none;
      outline: none;
      color: var(--blue-color);
      width: 7rem;
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; /* Remove default arrow */
      background-image: url("../../assets//drop-down-icon.svg");
      background-repeat: no-repeat; /* Add custom arrow */
      background-position: 95% center;
      background-size: 2rem;
      font-size: 1.4rem;
      font-family: "Outfit", sans-serif;

      option {
        font-weight: 500;
        font-size: 1.7rem;
      }
    }

    .select-base-currency {
      & > * {
        background-color: var(--bg-white-color);
        color: #1d1d18;
        &:nth-child(3) {
          border: none !important;
          min-width: 9rem;
          background-color: var(--bg-white-color);
          // border: 0.1rem solid var(--border-color);
          border-radius: 5rem !important;

          & > *:nth-child(1) {
            & > *:nth-child(1) {
              color: var(--black-color);
            }
          }
        }

        &:nth-child(4){
          right: 0;
          left: unset;
          width: max-content;
        }
      }
    }
  }

  .type-select-box {
    // padding: 0.7rem 2rem;
    // border: 0.15rem solid var(--blue-color);
    // border-radius: 1.2rem;
    margin-right: 2.5rem;
    // color: var(--blue-color);

    .select {
      // border: 0.15rem solid var(--blue-color);
      font-size: 1.4rem;
      border: none;
      outline: none;
      // color: var(--blue-color);
      width: 17rem;

      & > * {
        background-color: var(--bg-white-color);

        color: #1d1d18;
        &:nth-child(3) {
          border-radius: 1.2rem;
          background-color: var(--bg-white-color);
          border: 0.1rem solid var(--blue-color);

          & > *:nth-child(1) {
            & > *:nth-child(1) {
              color: var(--black-color);
            }
          }
        }
      }

      option {
        font-weight: 500;
        font-size: 1.5rem;
      }
    }
  }

  .notification-box {
    margin-right: 2.5rem;
    width: 3.5rem;
    height: 3.5rem;
    display: grid;
    place-items: center;
    border-radius: 50%;
    border: 0.1rem solid var(--border-color);
    position: relative;
    cursor: pointer;

    span {
      position: absolute;
      top: 0.35rem;
      right: 0.65rem;
      width: 0.8rem;
      height: 0.8rem;
      background-color: #fa3f3f;
      border-radius: 50%;
    }

    .img-box {
      width: 2rem;
      height: 2rem;

      .img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        display: block;
        // filter: brightness(1000%);
      }
    }
  }

  .settings-box {
    margin-right: 2.5rem;

    .img-box {
      width: 3rem;
      height: 3rem;
      cursor: pointer;

      .img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }

  .profile-box {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    .img-box {
      width: 3rem;
      height: 3rem;
      margin-right: 1rem;
      overflow: hidden;
      border-radius: 50%;

      .img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    .name {
      text-transform: capitalize;
      color: var(--black-color);
      margin-right: 1rem;
      font-size: 1.5rem;
      font-weight: 500;
    }

    .icon {
      color: #b0babf;
      width: 1.5rem;
      height: 1.5rem;
      transform: translateY(0.2rem);
    }

    .profile-drop-box {
      position: absolute;
      top: 150%;
      right: 0;
      width: 22rem;
      height: 25rem;
      background-color: var(--bg-white-color);
      box-shadow: var(--box-shadow-two);
      transition: transform 0.3s linear;
      border-radius: 1.5rem;
      display: grid;
      grid-template-rows: repeat(4, 1fr);
      transform: scale(0);
      transform-origin: top right;

      .arrow-box {
        position: absolute;
        bottom: 100%;
        right: 1rem;
        width: 1.3rem;
        height: 1.3rem;
        background-color: var(--bg-white-color);
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        box-shadow: var(--box-shadow-two);
      }

      & > *:not(:first-child) {
        padding: 0rem 2.5rem;
        display: flex;
        align-items: center;
        text-transform: capitalize;
      color: var(--black-color);
        //   margin-right: 1rem;
        font-size: 1.5rem;
        font-weight: 500;
        border-top: 0.05rem solid var(--border-color);
        // align-self: center;
        // background-color: red;

        .link {
          text-decoration: none;
          color: var(--black-color);
        }
      }

      //   &>*:not(:last-child){
      //     border-bottom: .1rem solid #DDE2E4;
      //   }

      .img-text-box {
        background-color: #f6f8f9;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        display: flex;
        align-items: center;
        border-top: unset;

        .img-box {
          width: 3.5rem;
          height: 3.5rem;
          margin-right: 1rem;
          overflow: hidden;
          border-radius: 50%;

          .img {
            object-fit: contain;
            width: 100%;
            height: 100%;
            display: block;
          }
        }

        .name {
          text-transform: capitalize;
          color: #5b6871;
          //   margin-right: 1rem;
          font-size: 1.5rem;
          font-weight: 500;
        }
      }
    }

    .profile-drop-box-show {
      transform: scale(1);
    }
  }
}
