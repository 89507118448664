.ownership-transfer-modal-wrap {
  width: 45rem;
  background-color: var(--bg-white-color);
  border-radius: 1rem;
  position: relative;
  padding: 3rem;
}
.ownership-transfer-modal-wrap .cancel-box {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  justify-self: flex-start;
  display: grid;
  place-items: center;
  border: 0.15rem solid #cc0905;
  cursor: pointer;
}
.ownership-transfer-modal-wrap .cancel-box .icon {
  color: #cc0905;
}
.ownership-transfer-modal-wrap .wrap {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}
.ownership-transfer-modal-wrap .wrap .heading {
  font-size: 2rem;
  font-weight: 600;
  color: #020202;
}
.ownership-transfer-modal-wrap .wrap .select > *:nth-child(3) {
  padding: 0.3rem;
}
.ownership-transfer-modal-wrap .wrap > *:last-child {
  align-self: flex-end;
}/*# sourceMappingURL=OwnerShipTransferModal.css.map */