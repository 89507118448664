.subscription-end-general-modal {
  width: 45rem;
  min-height: 40rem;
  background-color: #ffffff;
  display: grid;
  place-items: center;
  position: relative;


  
  .cancel-box {
    margin-left: auto;
    background-color: #ffefeb;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: .3rem;
    display: grid;
    place-items: center;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1rem;

    .icon {
      color: #cc0905; 
    }
  }

  .wrap {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    text-align: center;
    align-items: center;

    .img-wrap {
        display: grid;
        place-items: center;
      .img-box {
        width: 17rem;
        height: 17rem;

        .img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
    }

    .title {
      font-weight: 600;
      font-size: 2.5rem;
    }

    .text {
      color: rgba(94, 99, 102, 1);
    }
  }
}
