.partial-reimburse-wrap {
  display: flex;
  flex-direction: column;
  width: 65rem;
  margin-top: 2rem;
}
.partial-reimburse-wrap .title {
  padding: 1rem 3rem;
  font-weight: 600;
  font-size: 2rem;
}
.partial-reimburse-wrap .text {
  padding: 1rem 3rem;
  margin-bottom: 1rem;
}
.partial-reimburse-wrap .par-form-wrap {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.partial-reimburse-wrap .par-form-wrap .par-form-group {
  display: flex;
  width: 100%;
  background-color: #f8fafc;
  padding: 2rem 3rem;
  justify-content: space-between;
  align-items: center;
}
.partial-reimburse-wrap .par-form-wrap .par-form-group .label {
  display: flex;
  gap: 2rem;
  cursor: pointer;
}
.partial-reimburse-wrap .par-form-wrap .par-form-group .label-active {
  font-weight: 400;
}
.partial-reimburse-wrap .par-form-wrap input[type=text] {
  border: 0.1rem solid var(--border-color);
  border-radius: 0.3rem;
  outline: none;
  padding: 1.5rem 1rem;
  width: 30rem;
}
.partial-reimburse-wrap .par-form-wrap input[type=radio] {
  display: none;
}
.partial-reimburse-wrap .par-form-wrap input:-moz-read-only {
  cursor: default;
}
.partial-reimburse-wrap .par-form-wrap input:read-only {
  cursor: default;
}
.partial-reimburse-wrap .par-form-wrap .field-active {
  border: 0.1rem solid var(--blue-color) !important;
  transition: 0.5s;
}
.partial-reimburse-wrap .par-form-wrap .radio-icon {
  color: var(--border-color);
  font-size: 2rem;
  cursor: pointer;
}
.partial-reimburse-wrap .par-form-wrap .radio-icon-active {
  color: var(--blue-color);
  transition: 0.5s;
}
.partial-reimburse-wrap .btn {
  display: flex;
  justify-content: right;
  gap: 2rem;
  padding: 3rem;
}/*# sourceMappingURL=PartialReimbursement.css.map */