.admin-settings-profile-transfer-wrap {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem;
}
@media only screen and (max-width: 75em) {
  .admin-settings-profile-transfer-wrap {
    width: 100%;
  }
}
.admin-settings-profile-transfer-wrap .subtitle-box {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  padding-bottom: 1.5rem;
  border-bottom: 0.1rem solid #dde2e4;
}
.admin-settings-profile-transfer-wrap .subtitle-box > *:not(:last-child) {
  margin-right: 0.5rem;
}
.admin-settings-profile-transfer-wrap .subtitle-box .text {
  cursor: pointer;
  color: var(--black-color);
}
.admin-settings-profile-transfer-wrap .subtitle-box .active {
  font-weight: 600;
  color: var(--black-color);
}
.admin-settings-profile-transfer-wrap .page-title-box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.admin-settings-profile-transfer-wrap .page-title-box .title {
  font-weight: 700;
  color: #1d1d18;
}
.admin-settings-profile-transfer-wrap .page-title-box .text {
  color: #5b6871;
  font-size: 1.25rem;
}
.admin-settings-profile-transfer-wrap .two-grid-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  margin-top: 3rem;
}
.admin-settings-profile-transfer-wrap .form-group {
  display: flex;
  flex-direction: column;
}
.admin-settings-profile-transfer-wrap .form-group .select-wrap > *:nth-child(3) {
  min-width: 9rem;
  background-color: var(--bg-white-color);
  border-radius: 0.7rem !important;
  border: 0.1rem solid var(--border-color);
}
.admin-settings-profile-transfer-wrap .form-group .select-wrap > *:nth-child(3) > *:first-child > *:first-child {
  color: #6e7c87 !important;
}
.admin-settings-profile-transfer-wrap .form-group .select-wrap-active > *:nth-child(3) {
  min-width: 9rem;
  background-color: var(--bg-white-color);
  border-radius: 0.7rem !important;
  border: 0.1rem solid var(--border-color);
}
.admin-settings-profile-transfer-wrap .form-group .select-wrap-active > *:nth-child(3) > *:first-child > *:first-child {
  color: #1d1d18 !important;
}
.admin-settings-profile-transfer-wrap .form-group .select-wrap-multi > *:nth-child(3) > * > *:not(:last-child) > * {
  color: #ffffff !important;
}
.admin-settings-profile-transfer-wrap .form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  background-image: url("../../../../assets/drop-down-icon.svg");
  background-repeat: no-repeat; /* Add custom arrow */
  background-position: 95% center;
  background-size: 2rem;
}
.admin-settings-profile-transfer-wrap .form-group .label {
  margin-bottom: 0.4rem;
  display: flex;
  align-items: center;
  color: #5b6871;
}
.admin-settings-profile-transfer-wrap .form-group .label span {
  margin-left: 0.4rem;
  color: #cc0905;
  display: inline-block;
  font-size: 1.7rem;
  transform: translateY(0.4rem);
}
.admin-settings-profile-transfer-wrap .form-group .input {
  border: 0.1rem solid var(--border-color);
  border-radius: 0.8rem;
  padding: 1.3rem 1rem;
  outline: none;
  width: 100%;
  font-size: 1.4rem;
  font-size: 1.35rem;
  color: var(--black-shade-color);
  color: var(--black-color);
  background-color: var(--bg-white-color);
}
.admin-settings-profile-transfer-wrap .form-group .input::-moz-placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.admin-settings-profile-transfer-wrap .form-group .input::placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.admin-settings-profile-transfer-wrap .form-group .input:focus {
  border: 0.1rem solid var(--blue-color);
  outline: none;
}
.admin-settings-profile-transfer-wrap .form-group .input-active {
  border: 0.1rem solid var(--blue-color);
}
.admin-settings-profile-transfer-wrap .form-group .disable {
  background-color: #F8F9FF;
  border: 0.1rem solid #DDE2E4;
}
.admin-settings-profile-transfer-wrap .four-grid-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 2rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 0.1rem solid #dde2e4;
}
.admin-settings-profile-transfer-wrap .text-btn-box {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin: 4rem 0rem 2.5rem 0rem;
}
.admin-settings-profile-transfer-wrap .text-btn-box > *:last-child {
  border: 0.1rem solid var(--blue-color);
  display: flex;
  align-items: center;
  gap: 0.6rem;
  justify-content: center;
}
.admin-settings-profile-transfer-wrap .text-btn-box > *:last-child .icon {
  fill: var(--blue-color);
}
.admin-settings-profile-transfer-wrap .text-btn-box > *:last-child span {
  color: var(--blue-color);
}
.admin-settings-profile-transfer-wrap .form-group-hide {
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  width: 100%;
  grid-row: 1/span 1;
  grid-column: 1/span 1;
}
.admin-settings-profile-transfer-wrap .form-group-show {
  overflow-x: visible;
  overflow-y: visible;
  max-height: 20rem;
  transition: max-height 0.5s linear-bezier(0, 1.05, 0, 1);
}

.profile-transfer-confirm-modal-wrap {
  width: 55rem;
  min-height: 25rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 3rem;
  border-radius: 1rem;
}
.profile-transfer-confirm-modal-wrap .title {
  font-size: 1.8rem;
  font-weight: 700;
  color: #1d1d18;
  margin-bottom: 3rem;
}
.profile-transfer-confirm-modal-wrap .text {
  color: #5b6871;
  font-size: 1.45rem;
  line-height: 1.95rem;
}
.profile-transfer-confirm-modal-wrap .text span {
  font-weight: 700;
}
.profile-transfer-confirm-modal-wrap .two-btn-box {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;
}
.profile-transfer-confirm-modal-wrap .two-btn-box > *:first-child {
  border: 0.1rem solid #cc0905;
  color: #cc0905;
}

.success-transfer-profile-modal-wrap {
  height: 45rem;
  width: 40rem;
  background-color: #ffffff;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  padding: 3rem;
}
.success-transfer-profile-modal-wrap .img-box {
  width: 15rem;
  height: 15rem;
}
.success-transfer-profile-modal-wrap .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.success-transfer-profile-modal-wrap > *:last-child {
  color: #cc0905;
}
.success-transfer-profile-modal-wrap .title {
  font-size: 1.8rem;
  font-weight: 700;
  color: #1d1d18;
  text-align: center;
}
.success-transfer-profile-modal-wrap .text {
  color: #5b6871;
  font-size: 1.45rem;
  line-height: 1.95rem;
  text-align: center;
}
.success-transfer-profile-modal-wrap .text span {
  font-weight: 700;
}/*# sourceMappingURL=ProfileTransfer.css.map */