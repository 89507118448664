

.no-table-content-wrap{
    height: 30rem;
    // background-color: red;
    display: grid;
    place-items: center;

    .content-box{
        display: flex;
        flex-direction: column;
        cursor: pointer;
        align-items: center;

        .img-box{
            width: 12rem;
            height: 12rem;
            margin-bottom: 1rem;

            .img{
                object-fit: contain;
                width: 100%;
                height: 100%;
                display: block;
            }
        }

        .text{
            font-size: 1.7rem;
            text-transform: capitalize;
            color: #84919A;
        }
    }
}