.delete-shared-modal-wrap {
  width: 55rem;
  background-color: var(--bg-white-color);
  padding: 3rem;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  justify-content: center;
}
.delete-shared-modal-wrap > *:not(:last-child) {
  margin-bottom: 2rem;
}
.delete-shared-modal-wrap .title {
  font-weight: 600;
  font-size: 2rem;
  color: var(--black-color);
}
.delete-shared-modal-wrap .text {
  font-size: 1.45rem;
}
.delete-shared-modal-wrap .text-two {
  margin-top: -1rem;
}
.delete-shared-modal-wrap .btn-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.delete-shared-modal-wrap .btn-box > *:first-child {
  margin-right: 2rem;
  background-color: var(--bg-white-color);
  border: 0.1rem solid var(--blue-color);
  color: var(--blue-color);
}/*# sourceMappingURL=DeleteSharedmodal.css.map */