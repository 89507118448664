.dashboard-home-wrap {
  padding: 3rem 4rem;
}
.dashboard-home-wrap .complete-checklist-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dashboard-home-wrap .complete-checklist-box .wrap {
  display: flex;
  align-items: center;
  background-color: var(--bg-white-color);
  padding: 1rem 2.5rem;
  cursor: pointer;
  box-shadow: var(--box-shadow-one);
  border-radius: 0.5rem;
}
.dashboard-home-wrap .complete-checklist-box .wrap:hover .icon {
  transform: translateX(0.2rem);
}
.dashboard-home-wrap .complete-checklist-box .wrap .text {
  color: var(--blue-color);
  margin-right: 1rem;
  font-weight: 500;
}
.dashboard-home-wrap .complete-checklist-box .wrap .icon {
  color: var(--blue-color);
  width: 2rem;
  height: 2rem;
  border-radius: 0.4rem;
  border: 0.1rem solid var(--blue-color);
  padding: 0.3rem;
  transition: all 0.45s;
}

.budget-assumption-modal-btn {
  padding: 1rem 2rem;
  margin-top: -2rem;
  outline: none;
  border: 0.1rem solid var(--blue-color);
  background-color: var(--blue-color);
  border-radius: 0.5rem;
  color: white;
  transition: all 0.3s;
  cursor: pointer;
}
.budget-assumption-modal-btn:hover {
  transform: translateY(-0.1rem);
}/*# sourceMappingURL=DashboardHome.css.map */