.budget-assumption-modal-home-page {
  margin-top: auto;
  width: 100%;
  overflow-x: scroll;
  padding: 2rem 2rem;
  height: 15rem;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.budget-assumption-modal-home-page .box-file {
  box-shadow: 0.1rem 0.1rem 8rem rgba(0, 0, 0, 0.05) !important;
}
.budget-assumption-modal-home-page::-webkit-scrollbar:vertical {
  display: none;
}
.budget-assumption-modal-home-page::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.5rem;
}
.budget-assumption-modal-home-page::-webkit-scrollbar-track {
  box-shadow: none;
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.budget-assumption-modal-home-page::-webkit-scrollbar-thumb {
  background: var(--blue-color);
  border-radius: 30rem;
}
.budget-assumption-modal-home-page::-webkit-scrollbar-thumb:hover {
  background: var(--blue-color);
}
.budget-assumption-modal-home-page .wrap {
  display: grid;
  grid-template-rows: 1fr;
  justify-items: flex-start;
  width: 100%;
  flex: 1;
  padding-bottom: 1.5rem;
}
.budget-assumption-modal-home-page .wrap > *:not(:last-child) {
  margin-right: 2rem;
}
.budget-assumption-modal-home-page .wrap .box {
  width: 25rem;
  height: 13rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.budget-assumption-modal-home-page .wrap .box-file {
  background-color: var(--bg-color);
  box-shadow: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: 1.3rem;
  padding: 1rem 2rem;
}
.budget-assumption-modal-home-page .wrap .box-file .type span {
  font-weight: 800;
  text-transform: uppercase;
}
.budget-assumption-modal-home-page .wrap .box-file .name span {
  font-weight: 800;
}

.show-big-budget-assumption-modal-homne {
  width: 80vw;
  height: 90vh;
  background-color: var(--bg-white-color);
  display: grid;
  place-items: center;
  position: relative;
  border-radius: 1rem;
}
.show-big-budget-assumption-modal-homne .cancel-box {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  justify-self: flex-start;
  display: grid;
  place-items: center;
  border: 0.15rem solid #cc0905;
  cursor: pointer;
}
.show-big-budget-assumption-modal-homne .cancel-box .icon {
  color: #cc0905;
}
.show-big-budget-assumption-modal-homne .wrap {
  width: 90%;
  height: 90%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}/*# sourceMappingURL=BudgetAssumptionModal.css.map */