.comparison-top-title {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 3rem;
}

.upgrade-wrap {
  margin-top: 3rem;
  .plan-list-wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10rem;
    text-align: center;
    .plan-list-box {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 3rem;
      //   &:nth-child(1) {
      //     // display: none;
      //   }
      .plan-title {
        font-size: 1.5rem;
      }
      .plan-value {
        font-size: 2rem;
        font-weight: 500;
      }
      .comparison-btn {
        display: flex;
        justify-content: center;
        place-content: center;
      }
    }
  }

  // @mixin grid-box($columns, $rows) {
  // display: grid;
  // grid-template-columns: repeat(#{$columns}, 1fr);
  // grid-template-rows: repeat(#{$rows}, 2fr);
  // }

  // .table-plan-list-wrap {
  //   // display: grid;
  //   // text-align: center;
  //   // min-width: 100%;
  //   // @include grid-box(5, 2);
  //   display: flex;
  //   text-transform: capitalize;
  // }

  // .table-plan-list-box > * {
  //   border: 0.1rem solid #d1dcde;
  //   padding: 3rem 1rem;
  //   width: 34.5rem;

  //   &:nth-child(1) {
  //     font-weight: 500;
  //     color: #000000;
  //   }
  // }
  // .details {
  //   &:nth-child(1) {
  //     font-weight: 400;
  //   }
  //   &:not(:first-child) {
  //     color: var(--blue-color);
  //   }
  // }

  .table-feature-wrap {
    width: 100%;
    border-collapse: collapse;
    text-transform: capitalize;

    .table-row-head {
      text-align: center;
      font-weight: 500;
      display: flex;
      .table-row-title {
        padding: 3rem;
        // text-align: center;
        border: 0.1rem solid #cccc;
        width: 100%;
      }
    }
    .table-col-head {
      text-align: center;
      // font-weight: 500;
      display: grid;
      // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-columns: repeat(5, 1fr);
      .table-col-title {
        // width: 22.7%;
        // width: 100%;
        padding: 3rem;
        text-align: center;
        border: 0.1rem solid #cccc;
        font-weight: 500;
      }
      .table-col-detail {
        color: var(--blue-color);
        // color: #119c2b;
        font-weight: 400;
        // width: 100%;
        // width: max-content;
        .icon {
          font-weight: 500;
          color: #119c2b;
        }
        .times-icon {
          color: rgb(158, 57, 57);
        }
      }
    }
  }

  td {
    padding: 3rem;
    text-align: center;
    border: 0.1rem solid #cccc;
  }
}
