.employee-submit-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  // align-items: flex-start;
  padding: 20px;
  margin-top: 3rem;
  margin-bottom: 5rem;
  gap: 23px;
  // width: 1016px;
  height: 105px;
  left: 269px;
  top: 172px;

  .employee-submit-card {
    width: 248px;
    height: 105px;
    background: var(--bg-white-color);
    box-shadow: 10px 8px 50px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .expense-text {
    width: 20vh;
    height: 19px;
    padding: 10px;
    margin-left: 1rem;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: -0.00454m;
    color: var(--blue-color);
  }
  .expense-value {
    width: 63px;
    height: 24px;
    font-weight: 700;
    padding: 2px;
    padding-top: 3rem;
    // padding: 20px;
    margin-left: 1.5rem;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: var(--black-color);
  }
  .approved-text {
    color: #07a287;
  }
  .pending-text {
    color: #FCA549;
  }
  .rejected-text {
    color: #CC0905;
  }
}
