.organization-wrap {
  padding: 4rem;
  display: flex;
  flex-direction: column;
}
.organization-wrap .title {
  margin-bottom: 0.5rem;
  font-weight: 700;
  font-size: 2.4rem;
  color: var(--black-color);
}
.organization-wrap .text {
  color: #5b6871;
  font-size: 1.45rem;
  margin-bottom: 4rem;
}
.organization-wrap .select-org-wrap {
  display: flex;
  flex-direction: column;
}
.organization-wrap .select-org-wrap .btn-set-up {
  align-self: flex-start;
  margin-top: 4rem;
  background-color: #eceded;
  padding: 1.2rem 4rem;
  color: #c3c7ce;
  text-align: center;
  cursor: pointer;
  border: 0.1rem solid var(--border-color);
  border-radius: 0.4rem;
  outline: none;
  transition: all 0.3s;
  font-weight: 500;
}
.organization-wrap .select-org-wrap .btn-set-up:hover {
  transform: translateY(-0.1rem);
}
.organization-wrap .select-org-wrap .btn-set-up-active {
  background-color: var(--blue-color);
  color: var(--white-color);
  border: none;
}
.organization-wrap .select-org-wrap .title {
  margin-bottom: 3rem;
  font-weight: 500;
  font-size: 1.8rem;
  color: var(--black-color);
  margin-top: 0rem;
}
.organization-wrap .select-org-wrap .child {
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: var(--bg-white-color);
  margin-bottom: 3.5rem;
  box-shadow: var(--box-shadow-one);
  width: 65%;
  transition: all 0.3s;
  padding: 4rem;
}
.organization-wrap .select-org-wrap .child .text-box {
  margin-left: 2rem;
}
.organization-wrap .select-org-wrap .child .text-box .name {
  margin-bottom: 0.3rem;
  color: #5b6871;
  font-size: 1.6rem;
  font-weight: 500;
}
.organization-wrap .select-org-wrap .child .text-box .text {
  margin-bottom: 0rem;
  font-size: 1.4rem;
  color: #84919a;
}
.organization-wrap .select-org-wrap .child .check-box {
  margin-left: auto;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  border: 0.1rem solid var(--border-color);
}
.organization-wrap .select-org-wrap .child .check-box .icon {
  color: var(--white-color);
  width: 1rem;
  height: 1rem;
}
.organization-wrap .select-org-wrap .child-active {
  background-color: #f4faff;
  position: relative;
}
.organization-wrap .select-org-wrap .child-active > * {
  position: relative;
  z-index: 3;
}
.organization-wrap .select-org-wrap .child-active::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  border: 0.1rem solid var(--blue-color);
}
.organization-wrap .select-org-wrap .child-active .check-box {
  border: unset;
  background-color: var(--blue-color);
}
.organization-wrap .select-org-wrap .child-active .check-box .icon {
  color: #ffffff;
}
.organization-wrap .subtitle-box {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  border-bottom: 0.1rem solid var(--border-color);
  width: 80%;
  padding-bottom: 1.5rem;
}
.organization-wrap .subtitle-box > *:not(:last-child) {
  margin-right: 0.5rem;
}
.organization-wrap .subtitle-box .text {
  cursor: pointer;
  margin-bottom: unset;
}
.organization-wrap .subtitle-box .active {
  font-weight: 600;
  color: var(--black-color);
}
.organization-wrap .wrapper {
  width: 80%;
  margin-bottom: 4rem;
  border-bottom: 0.1rem solid var(--border-color);
  padding-bottom: 3rem;
}
.organization-wrap .wrapper .btn-set-up {
  align-self: flex-start;
  margin-top: 4rem;
  background-color: #eceded;
  padding: 1.2rem 4rem;
  color: #c3c7ce;
  text-align: center;
  cursor: pointer;
  border: 0.1rem solid var(--border-color);
  border-radius: 0.4rem;
  outline: none;
  transition: all 0.3s;
  font-weight: 500;
}
.organization-wrap .wrapper .btn-set-up:hover {
  transform: translateY(-0.1rem);
}
.organization-wrap .wrapper .btn-set-up-active {
  background-color: var(--blue-color);
  color: var(--white-color);
  border: none;
}
.organization-wrap .wrapper .box-title {
  color: var(--black-color);
  margin-bottom: 1rem;
  font-size: 1.5rem;
}
.organization-wrap .wrapper .small-text {
  font-size: 1.3rem;
}
.organization-wrap .wrapper .form-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 3rem;
}
.organization-wrap .wrapper .form-wrap .form-group {
  display: flex;
  flex-direction: column;
}
.organization-wrap .wrapper .form-wrap .form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  background-image: url("../../../assets/drop-down-icon.svg");
  background-repeat: no-repeat; /* Add custom arrow */
  background-position: 95% center;
  background-size: 2rem;
}
.organization-wrap .wrapper .form-wrap .form-group .label {
  margin-bottom: 0.4rem;
  display: flex;
  align-items: center;
  color: #5b6871;
}
.organization-wrap .wrapper .form-wrap .form-group .label span {
  margin-left: 0.4rem;
  color: #cc0905;
  display: inline-block;
  font-size: 1.7rem;
  transform: translateY(0.4rem);
}
.organization-wrap .wrapper .form-wrap .form-group .input {
  border: 0.1rem solid var(--border-color);
  border-radius: 0.8rem;
  padding: 1.3rem 1rem;
  outline: none;
  width: 100%;
  font-size: 1.4rem;
  font-size: 1.35rem;
  color: var(--black-shade-color);
  color: var(--black-color);
  background-color: var(--bg-white-color);
}
.organization-wrap .wrapper .form-wrap .form-group .input::-moz-placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.organization-wrap .wrapper .form-wrap .form-group .input::placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.organization-wrap .wrapper .form-wrap .form-group .input:focus {
  border: 0.1rem solid var(--blue-color);
  outline: none;
}
.organization-wrap .wrapper .form-wrap .form-group .input-active {
  border: 0.1rem solid var(--blue-color);
}
.organization-wrap .wrapper .note-box {
  background-color: rgba(255, 192, 67, 0.1);
  padding: 1rem 2rem;
  border-radius: 0.6rem;
  margin-top: 2.5rem;
}
.organization-wrap .wrapper .note-box p {
  font-size: 1.2rem;
  color: #596080;
}
.organization-wrap .wrapper .note-box p span {
  font-weight: 700;
  color: var(--black-color);
  margin-right: 0.8rem;
  text-transform: capitalize;
}
.organization-wrap .wrapper .note-box > * {
  display: flex;
  align-items: center;
}
.organization-wrap .wrapper .note-box > * .img-box {
  margin-right: 1rem;
  transform: translateY(0.1rem);
}
.organization-wrap .wrapper .note-box > * p {
  color: #596080;
  font-size: 1.1rem;
}
.organization-wrap .wrapper .logo-box {
  margin-top: 2rem;
}
.organization-wrap .wrapper .logo-box .logo-title {
  font-size: 1.35rem;
  font-weight: 500;
  color: var(--black-color);
  margin-bottom: 1.5rem;
}
.organization-wrap .wrapper .logo-box .form-group {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}
.organization-wrap .wrapper .logo-box .form-group .box {
  border: 0.1rem solid var(--border-color);
  border-radius: 0.8rem;
  padding: 1.1rem 1rem;
  width: 23rem;
  background-color: var(--bg-white-color);
}
.organization-wrap .wrapper .logo-box .form-group .box .label {
  color: #5b6871;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
}
.organization-wrap .wrapper .logo-box .form-group .box .label .img {
  margin-right: 1rem;
}
.organization-wrap .wrapper .logo-box .form-group .box .label span {
  font-size: 1.4rem;
}
.organization-wrap .wrapper .logo-box .form-group .box #imageUpload {
  display: none;
}
.organization-wrap .wrapper .logo-box .form-group .logo-display {
  margin-left: 2rem;
  color: var(--blue-color);
}
.organization-wrap .wrapper .logo-box .logo-text {
  font-size: 1.3rem;
  color: #84919a;
}
.organization-wrap .wrapper .color-group-box {
  margin-top: 2rem;
}
.organization-wrap .wrapper .color-group-box .color-title {
  font-size: 1.35rem;
  font-weight: 500;
  color: var(--black-color);
  margin-bottom: 1.5rem;
}
.organization-wrap .wrapper .color-group-box .wrap {
  display: flex;
  flex-direction: column;
}
.organization-wrap .wrapper .color-group-box .wrap .text-box {
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 4rem;
}
.organization-wrap .wrapper .color-group-box .wrap .text-box .color-text {
  font-size: 1.35rem;
}
.organization-wrap .wrapper .color-group-box .wrap .text-box > *:last-child {
  font-size: 1.25rem;
}
.organization-wrap .wrapper .color-group-box .wrap .color-box .form-group {
  display: flex;
  border: 0.1rem solid var(--border-color);
  border-radius: 0.4rem;
  overflow: hidden;
  background-color: var(--bg-white-color);
  width: 30rem;
  margin: 2rem 0rem;
}
.organization-wrap .wrapper .color-group-box .wrap .color-box .form-group .input {
  padding: 1.3rem 1rem;
  background-color: transparent;
  flex: 0 0 75%;
  outline: none;
  border: none;
  color: var(--black-color);
  background-color: var(--bg-white-color);
}
.organization-wrap .wrapper .color-group-box .wrap .color-box .form-group .input:focus {
  border: none;
  outline: none;
}
.organization-wrap .wrapper .color-group-box .wrap .color-box .form-group .display-color {
  flex: 0 0 25%;
}
.organization-wrap .wrapper .financial-title {
  font-size: 1.35rem;
  font-weight: 500;
  color: var(--black-color);
  margin-bottom: 1.5rem;
}
.organization-wrap .wrapper .form-wrap-financial {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-gap: 3rem;
  grid-template-rows: unset;
}
.organization-wrap .form-input-calendar {
  border: 0.1rem solid var(--border-color);
  border-radius: 0.8rem;
  padding: 1.3rem 1rem;
  background-color: var(--bg-white-color);
}/*# sourceMappingURL=OrganizationSettings.css.map */