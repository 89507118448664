.preference-setting-wrap {
  padding: 4rem;

  .subtitle-box {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    border-bottom: 0.1rem solid var(--border-color);
    width: 80%;
    padding-bottom: 1.5rem;

    & > *:not(:last-child) {
      margin-right: 0.5rem;
    }

    .text {
      cursor: pointer;
    }

    .active {
      font-weight: 600;
      color: var(--black-color);
    }
  }

  .title-text-box {
    width: 80%;
    padding-bottom: 1.5rem;

    .title {
      font-weight: 700;
      font-size: 1.8rem;
      margin-bottom: 1rem;
      color: var(--black-color);
    }

    .text {
      font-size: 1.35rem;
      cursor: pointer;
    }
  }
}

.form-box-preference {
  display: flex;
  flex-direction: column;
  // background-color: red;;
  margin-bottom: 3rem;

  .title {
    font-weight: 600;
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
    color: var(--black-color);
  }

  .wrap {
    background-color: var(--bg-white-color);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    box-shadow: var(--box-shadow-two);

    .select{
      // display: none;
      margin-top: 1rem;

      & > * {
        background-color: var(--bg-white-color);
        color: #1d1d18;
        &:nth-child(3) {
          border-radius: .5rem;
          background-color: var(--bg-white-color);
          border: 0.1rem solid var(--border-color);

          & > *:nth-child(1) {
            & > *:nth-child(1) {
              color: var(--black-color);
            }
          }
        }
      }
    }

    .delete-btn {
      width: 40%;
      padding: 1.2rem;
      border: 0.1rem solid #cc0905;
      color: #cc0905;
      border-radius: 0.7rem;
      cursor: pointer;
      transition: all 0.3s;
      text-align: center;
      font-weight: 500;

      &:hover {
        transform: translateY(-0.13rem);
      }
    }

    .box {
      width: 40%;
    }

    .subtitle {
      font-weight: 500;
      font-size: 1.4rem;
      margin-bottom: -1rem;
      color: var(--black-color);
    }

    // .sub-two{
    //     // margin-top: 3rem;
    // }

    .underline {
      height: 0.1rem;
      width: 100%;
      background-color: var(--border-color);
      margin: 2rem 0rem;
    }

    .small-text {
      font-size: 1.25rem;
      margin-bottom: 2rem;
    }

    .check-label-box {
      // background-color: red;
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;

      .check {
        display: none;

        &:checked ~ .label span {
          background-color: var(--blue-color);
          border: unset;

          .icon {
            visibility: visible;
          }
        }
      }

      .label {
        display: flex;
        align-items: center;
        font-size: 1.3rem;
        cursor: pointer;

        span {
          margin-right: 1.5rem;
          width: 1.7rem;
          height: 1.7rem;
          border-radius: 0.3rem;
          border: 0.1rem solid var(--border-color);
          display: grid;
          place-items: center;
          transition: all 0.3s;

          .icon {
            width: 1rem;
            height: 1rem;
            color: #ffffff;
            visibility: hidden;
          }
        }
      }
    }

    .form-submit {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.3rem 3rem;
      border-radius: 0.8rem;
      background-color: #eceded;
      border: none;
      outline: none;
      cursor: pointer;
      transition: all 0.3s;
      color: #c3c7ce;
      font-weight: 600;
      margin-top: 4rem;
      font-size: 1.3rem;
      margin-top: 0rem;
      // margin-left: 2rem;

      &:hover {
        transform: translateY(-0.15rem);
      }
    }

    .form-submit-active {
      background-color: var(--blue-color);
      color: var(--white-color);
    }

    .form-group {
      display: flex;
      align-items: center;
      position: relative;
      border: 0.1rem solid var(--border-color);
      border-radius: 0.8rem;
      padding: 0.5rem 1rem;
      // overflow-x: hidden;
      width: 40%;

      & > * {
        position: relative;
        z-index: 3;
      }

      .show-hide {
        display: grid;
        place-items: center;
        cursor: pointer;

        .icon {
          width: 2rem;
          height: 2rem;
          color: #b0babf;
        }
      }

      .status {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0.1rem solid var(--blue-color);
        border-radius: 0.8rem;
        z-index: 1;
        visibility: hidden;
        transition: visibility 0.3s;
      }

      .status-active {
        visibility: visible;
      }

      .placeholder {
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 10%;
        //   color: #a5b1bd;
        font-size: 1.35rem;
        text-transform: capitalize;
        background-color: var(--bg-white-color);
        padding: 0rem 1rem;
        transition: transform 0.5s;
      }

      .placeholder-active {
        visibility: visible;
        transform: translateY(-50%);
      }

      .form-label {
        margin-right: 0.2rem;
        flex: 0 0 7%;
        cursor: pointer;

        .img-box {
          width: 2.5rem;
          height: 2.5rem;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: block;
          }
        }
      }

      .form-input {
        border: none;
        flex: 1;
        padding: 1rem 1rem;
        outline: none;
        width: 100%;
        background-color: transparent;
        font-size: 1.35rem;
        color: var(--black-shade-color);
        //   height: 90%;
        //   background-color: red;

        //   &:-webkit-autofill{
        //     background-color: transparent;
        //   }

        &::placeholder {
          // color: #a5b1bd;
          font-size: 1.3rem;
          text-transform: capitalize;
          // transition: all .3s;
        }

        &:focus {
          &::placeholder {
            visibility: hidden;
          }
        }

        &:focus ~ .placeholder {
          visibility: visible;
          transform: translateY(-50%);
        }
      }

      .phone-input {
        // border: none;
        padding: 0.5rem;
        color: #a5b1bd;
      }

      .input-class {
        border: none;
        // background-color:red;
        color: #a5b1bd;

        @media only screen and (max-width: 38em) {
          width: 100%;
        }
      }

      .drop-down-class {
        background-color: transparent;
        border: unset;
      }
    }

    .update-text {
      color: var(--blue-color);
      margin: 2rem 0rem 2rem 0rem;
      cursor: pointer;
      align-self: flex-start;
      // background-color: red;
    }
  }
}

.update-password-wrap {
  background-color: var(--bg-white-color);
  width: 40rem;
  border-radius: 1rem;
  min-height: 35rem;
  display: flex;
  flex-direction: column;

  .title-box {
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    border-bottom: 0.1rem solid var(--border-color);

    .title {
      font-size: 1.7rem;
      font-weight: 600;
      color: var(--black-color);
    }

    .cancel-box {
      margin-left: auto;
      background-color: #ffefeb;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      display: grid;
      place-items: center;
      cursor: pointer;

      .icon {
        color: #cc0905;
      }
    }
  }

  .content-box {
    flex: 1;
    padding: 1rem 2rem 3rem 2rem;
    background-color: var(--bg-color);

    .text {
      font-size: 1.3rem;
      margin: 1rem 0rem 0rem 0rem;
      line-height: 1.6rem;
    }

    & > *:not(:last-child) {
      margin-bottom: 2rem;
    }

    .form-submit {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.3rem 3rem;
      border-radius: 0.8rem;
      background-color: #eceded;
      border: none;
      outline: none;
      cursor: pointer;
      transition: all 0.3s;
      color: #c3c7ce;
      font-weight: 600;
      margin-top: 4rem;
      font-size: 1.3rem;
      margin-top: 0rem;
      // margin-left: 2rem;

      &:hover {
        transform: translateY(-0.15rem);
      }
    }

    .form-submit-active {
      background-color: var(--blue-color);
      color: var(--white-color);
    }

    .form-group {
      display: flex;
      align-items: center;
      position: relative;
      border: 0.1rem solid var(--border-color);
      border-radius: 0.8rem;
      padding: 0.5rem 1rem;
      // overflow-x: hidden;
      width: 100%;

      & > * {
        position: relative;
        z-index: 3;
      }

      .show-hide {
        display: grid;
        place-items: center;
        cursor: pointer;

        .icon {
          width: 2rem;
          height: 2rem;
          color: #b0babf;
        }
      }

      .status {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0.1rem solid var(--blue-color);
        border-radius: 0.8rem;
        z-index: 1;
        visibility: hidden;
        transition: visibility 0.3s;
      }

      .status-active {
        visibility: visible;
      }

      .placeholder {
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 10%;
        //   color: #a5b1bd;
        font-size: 1.35rem;
        text-transform: capitalize;
        background-color: var(--bg-color);
        padding: 0rem 1rem;
        transition: transform 0.5s;
      }

      .placeholder-active {
        visibility: visible;
        transform: translateY(-50%);
      }

      .form-label {
        margin-right: 0.2rem;
        flex: 0 0 7%;
        cursor: pointer;

        .img-box {
          width: 2.5rem;
          height: 2.5rem;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: block;
          }
        }
      }

      .form-input {
        border: none;
        flex: 1;
        padding: 1rem 1rem;
        outline: none;
        width: 100%;
        background-color: transparent;
        font-size: 1.35rem;
        color: var(--black-shade-color);

        &::placeholder {
          font-size: 1.3rem;
          text-transform: capitalize;
        }

        &:focus {
          &::placeholder {
            visibility: hidden;
          }
        }

        &:focus ~ .placeholder {
          visibility: visible;
          transform: translateY(-50%);
        }
      }

      .phone-input {
        // border: none;
        padding: 0.5rem;
        color: #a5b1bd;
      }

      .input-class {
        border: none;
        // background-color:red;
        color: #a5b1bd;

        @media only screen and (max-width: 38em) {
          width: 100%;
        }
      }

      .drop-down-class {
        background-color: transparent;
        border: unset;
      }
    }
  }

  .btn-box {
    padding: 1.5rem 2rem;

    .form-submit {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.3rem 3rem;
      border-radius: 0.8rem;
      background-color: #eceded;
      border: none;
      outline: none;
      transition: all 0.3s;
      color: #c3c7ce;
      font-weight: 600;
      margin-top: 4rem;
      font-size: 1.3rem;
      margin-top: 0rem;
      // margin-left: 2rem;
    }

    .form-submit-active {
      background-color: var(--blue-color);
      color: var(--white-color);
      cursor: pointer;

      &:hover {
        transform: translateY(-0.15rem);
      }
    }
  }
}

// currency styles =---------

.form-group-currency-modal {
  display: flex;
  flex-direction: column;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; /* Remove default arrow */
    background-image: url("../../../assets/drop-down-icon.svg");
    background-repeat: no-repeat; /* Add custom arrow */
    background-position: 95% center;
    background-size: 2rem;
  }

  .select{
    &>*{
      background-color: var(--bg-white-color);
    color: #1d1d18;
    &:nth-child(3) {
      border-radius: .5rem;
      background-color: var(--bg-white-color);
      border: 0.1rem solid var(--border-color);

      & > *:nth-child(1) {
        & > *:nth-child(1) {
          color: var(--black-color);
        }
      }
    }
    }
  }

  .label {
    margin-bottom: 0.4rem;
    display: flex;
    align-items: center;
    color: #5b6871;

    span {
      margin-left: 0.4rem;
      color: #cc0905;
      display: inline-block;
      font-size: 1.7rem;
      transform: translateY(0.4rem);
    }
  }

  .input {
    border: 0.1rem solid var(--border-color);
    border-radius: 0.8rem;
    padding: 1.3rem 1rem;
    outline: none;
    width: 100%;
    font-size: 1.4rem;
    //   background-color: transparent;
    font-size: 1.35rem;
    color: var(--black-shade-color);
    color: var(--black-color);
    background-color: var(--bg-white-color);

    &::placeholder {
      font-size: 1.2rem;
      text-transform: capitalize;
    }

    &:focus {
      border: 0.1rem solid var(--blue-color);
      outline: none;
    }
  }

  .input-active {
    border: 0.1rem solid var(--blue-color);
  }
}
