.approval-setting-modal-wrap {
  width: 45rem;
  min-height: 35rem;
  border-radius: 1rem;
  background-color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5rem 4rem;
  position: relative;

  .cancel-box {
    width: 3rem;
    height: 3rem;
    background-color: #dde2e4;
    position: absolute;
    top: 2rem;
    right: 2rem;
    border-radius: 50%;
    display: grid;
    place-items: center;
    cursor: pointer;
    transition: all .3s;

    &:hover{
        transform: scale(1.05);
    }

    .icon {
      width: 2rem;
      height: 2rem;
      color: #1D1D18;
    }
  }

  .img-box {
    width: 20rem;
    height: 10rem;

    .img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .title {
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  .text {
    font-size: 1.3rem;
  }

  .button-blue-wrap {
    width: 50%;
    margin-top: 3rem;
  }


  .btn-box{
    display: flex;
    align-items: center;
    margin-top: 3rem;

    .btn-white{
        margin-right: 2rem;
        border: .1rem solid var(--blue-color);
        border-radius: .4rem;
        padding: 1rem 2rem;
        background-color: var(--white-color);
        color: var(--blue-color);
        // padding: 1.2rem 3rem;
        transition: all 0.3s;
        font-weight: 600;
        font-size: 1.2rem;
        border-radius: 0.6rem;
        text-transform: capitalize;
        cursor: pointer;
    
        &:hover {
          transform: translateY(-0.1rem);
        }
    }

    .button-blue-wrap {
        width: unset;
        margin-top: 0rem;
        margin-bottom: unset;
      }
  }
}
