.emp-report-custom-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0rem 4rem;
  text-align: center;

  .left-box {
    display: flex;
    gap: 2rem;
  }

  .custom-title {
    font-weight: 700;
    font-size: 1.8rem;
    margin-top: 7px;
    color: var(--black-color);
  }
  .select {
    // background-color: #e5e5e5;
    border-radius: 0.9rem;
    display: flex;
    align-items: center;
    border: 0.1rem solid var(--border-color);
    overflow: hidden;
    padding: 1.2rem 1rem;
    width: 15rem;
    color: #6e7c87;
    outline: none;
    // border: none;

    &:focus {
      outline: none;
      // border: none;
    }
  }

  .right-box {
    display: flex;
    justify-content: right;
    gap: 2rem;
  }
  .emp-report-btn {
    padding: 1rem;
    background-color: var(--blue-color);
    color: var(--white-color);
    border-radius: 6px;
    // border-color: var(--blue-color);
    border: none;
    width: 100%;
    margin-top: 1.5px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      transform: translateY(-0.1rem);
    }
  }
}
