.admin-setting-wrap {
  width: 100%;
  height: 100%;
  // background-color: red;
  display: flex;
  padding: 3rem 4rem;
  padding-bottom: 10rem;
  margin-top: 4rem;
  margin-bottom: 10rem;

  .left-box {
    flex: 0 0 35%;
    display: flex;
    justify-content: center;
    align-self: flex-start;
    margin-right: 4rem;

    .content-box {
      background-color: var(--bg-white-color);
      box-shadow: var(--box-shadow-one);
      border-radius: 1rem;
      width: 30rem;
      padding: 3rem 4rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-transform: capitalize;

      & > *:not(:last-child) {
        margin-bottom: 2rem;
      }

      .title {
        color: var(--black-color);
        font-size: 1.8rem;
        font-weight: 600;
      }

      .link {
        font-size: 1.5rem;
        cursor: pointer;
        color: var(--black-color);
      }
    }
  }

  .right-box {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-self: flex-start;
    margin-right: 4rem;

    .content-box {
      background-color: var(--bg-white-color);
      box-shadow: var(--box-shadow-one);
      border-radius: 1rem;
      width: 30rem;
      padding: 3rem 4rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-transform: capitalize;

      & > *:not(:last-child) {
        margin-bottom: 2rem;
      }

      .title {
        color: var(--black-color);
        font-size: 1.8rem;
        font-weight: 600;
      }

      .link {
        font-size: 1.5rem;
        cursor: pointer;
        // background-color: red;
        color: var(--black-color);
      }
    }
  }
}

.admin-org-settings-wrap {
  padding: 4rem;
  display: flex;
  flex-direction: column;

  .subtitle-box {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 0.5rem;
    }

    .text {
      cursor: pointer;
      color: var(--black-color);
    }

    .active {
      font-weight: 600;
      color: var(--black-color);
    }
  }

  .title-text-box {
    margin-bottom: 2rem;
    border-bottom: 0.1rem solid var(--border-color);
    width: 80%;
    padding-bottom: 1.5rem;

    .title {
      font-weight: 700;
      font-size: 1.8rem;
      margin-bottom: 1rem;
      color: var(--black-color);
    }

    .text {
      font-size: 1.35rem;
      cursor: pointer;
      color: var(--black-color);
    }
  }

  .title-btn-box {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    border-bottom: 0.1rem solid var(--border-color);
    padding-bottom: 1.5rem;

    .title-text-box {
      margin-bottom: 0rem;
      border-bottom: unset;
      // width: 80%;
      padding-bottom: unset;

      .title {
        font-weight: 700;
        font-size: 1.8rem;
        margin-bottom: 1rem;
        color: var(--black-color);
      }

      .text {
        font-size: 1.35rem;
        color: var(--black-color);
      }
    }

    .btn-download-box {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      .btn-bulk {
        padding: 1.1rem 3rem;
        border: none;
        outline: none;
        border-radius: 0.6rem;
        background-color: var(--blue-color);
        color: var(--white-color);
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s;
        justify-content: center;
        text-transform: capitalize;
        font-size: 1.25rem;

        .icon {
          margin-right: 1rem;
        }

        &:hover {
          transform: translateY(-0.15rem);
        }
      }

      .btn-bulk-inactive {
        background-color: #eceded;
        color: #c3c7ce;
        border: 0.1rem solid var(--border-color);
      }

      .text {
        margin-top: 1rem;
        font-size: 1.2rem;
        color: var(--blue-color);
        cursor: pointer;
      }
    }
  }

  .form-box {
    width: 50rem;

    .form-title {
      font-weight: 700;
      font-size: 1.45rem;
      margin-bottom: 1rem;
      color: var(--black-color);
    }

    .form {
      .wrapper {
        margin-top: 3rem;
        // background-color: red;
        // width: 80%;
        margin-bottom: 4rem;
        border-bottom: 0.1rem solid var(--border-color);
        padding-bottom: 3rem;

        .btn-set-up {
          align-self: flex-start;
          margin-top: 4rem;
          background-color: #eceded;
          padding: 1.2rem 4rem;
          color: #c3c7ce;
          text-align: center;
          cursor: pointer;
          border: 0.1rem solid var(--border-color);
          border-radius: 0.4rem;
          outline: none;
          transition: all 0.3s;
          font-weight: 500;

          &:hover {
            transform: translateY(-0.1rem);
          }
        }

        .btn-set-up-active {
          background-color: var(--blue-color);
          color: var(--white-color);
          border: none;
        }

        .box-title {
          color: #000000;
          margin-bottom: 1rem;
          font-size: 1.5rem;
        }

        .small-text {
          font-size: 1.3rem;
        }

        .form-wrap {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-gap: 3rem;

          .color-group-box {
            grid-column: 1 / -1;

            .color-title {
              font-size: 1.35rem;
              font-weight: 500;
              color: var(--black-color);
              margin-bottom: 1.5rem;
            }

            .wrap {
              display: flex;
              // width: 70%;
              flex-direction: column;
              // align-items: center;

              .text-box {
                flex: 0 0 50%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-right: 4rem;

                .color-text {
                  font-size: 1.35rem;
                }

                & > *:last-child {
                  font-size: 1.25rem;
                  // margin-top: .3rem;
                }
              }

              .color-box {
                flex: 0 0 50%;

                .form-group {
                  display: flex;
                  border: 0.1rem solid var(--border-color);
                  border-radius: 0.4rem;
                  overflow: hidden;
                  background-color: var(--bg-white-color);
                  // padding: 0rem 1rem;
                  flex-direction: row !important;
                  width: 30rem;
                  margin: 2rem 0rem;
                  // .form-input-calendar{
                  //     border: 0.1rem solid var(--border-color) !;
                  //     border-radius: 0.8rem;
                  //     padding: 1.1rem 1rem;
                  //     background-color: var(--white-color);
                  // }

                  .input {
                    padding: 1.3rem 1rem;
                    background-color: transparent;
                    flex: 0 0 75%;
                    outline: none;
                    border: none;
                    color: var(--black-color);
                    background-color: var(--bg-white-color);

                    &:focus {
                      border: none;
                      outline: none;
                    }
                  }

                  .display-color {
                    flex: 0 0 25%;
                    // background-color: red;
                    // align-self: stretch;
                  }
                }
              }
            }
          }

          .form-group {
            display: flex;
            flex-direction: column;

            .select-wrap {
              // transform: translateY(.1rem);
              text-transform: capitalize;

              & > * {
                background-color: var(--bg-white-color);
                color: #1d1d18;
                &:nth-child(3) {
                  // border: none !important;
                  min-width: 9rem;
                  background-color: var(--bg-white-color);
                  border-radius: 0.7rem !important;
                  border: 0.1rem solid var(--border-color);
                  color: #1d1d18;

                  & > *:nth-child(1) {
                    & > *:nth-child(1) {
                      color: #1d1d18;
                    }
                  }
                }
              }
            }

            .disable-select {
              cursor: not-allowed;
              & > *:nth-child(3) {
                background-color: #eceded !important;

                & > * {
                  background-color: #eceded !important;
                
                }
              }
            }

            .select-wrap-active {
              & > * {
                background-color: var(--bg-white-color);
                color: #1d1d18 !important;
                &:nth-child(3) {
                  // border: none !important;
                  min-width: 9rem;
                  background-color: var(--bg-white-color);
                  border-radius: 0.7rem !important;
                  // border: 0.1rem solid var(--border-color);
                  border: 0.1rem solid var(--blue-color);
                  color: #1d1d18;

                  & > *:nth-child(1) {
                    & > *:nth-child(1) {
                      color: #1d1d18 !important;
                    }
                  }
                }
              }
            }

            .select-wrap-multi {
              & > *:nth-child(3) {
                & > * {
                  & > *:not(:last-child) {
                    // color: #000000 !important;
                    & > * {
                      color: #ffffff !important;
                    }
                  }
                }
              }
            }

            select {
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none; /* Remove default arrow */
              background-image: url("../../../assets/drop-down-icon.svg");
              background-repeat: no-repeat; /* Add custom arrow */
              background-position: 95% center;
              background-size: 2rem;
            }

            .label {
              margin-bottom: 0.4rem;
              display: flex;
              align-items: center;
              color: #5b6871;

              span {
                margin-left: 0.4rem;
                color: #cc0905;
                display: inline-block;
                font-size: 1.7rem;
                transform: translateY(0.4rem);
              }
            }

            .input {
              border: 0.1rem solid var(--border-color);
              border-radius: 0.8rem;
              padding: 1.3rem 1rem;
              outline: none;
              width: 100%;
              font-size: 1.4rem;
              // text-transform: capitalize;
              font-size: 1.35rem;
              color: var(--black-shade-color);
              color: var(--black-color);
              background-color: var(--bg-white-color);

              &::placeholder {
                font-size: 1.2rem;
                text-transform: capitalize;
              }

              &:focus {
                border: 0.1rem solid var(--blue-color);
                outline: none;
              }
            }

            .input-active {
              border: 0.1rem solid var(--blue-color);
            }
          }
        }

        .form-wrap-six {
          grid-template-rows: repeat(3, 1fr);
        }
      }

      .input-email {
        width: 90%;
        padding: 1.2rem 2rem;
        border: 0.1rem solid var(--border-color);
        border-radius: 0.5rem;
        margin-bottom: 2rem;
        outline: none;

        &:focus {
          outline: none;
          border: 0.1rem solid var(--border-color);
        }

        &::placeholder {
          color: #5b6871;
        }
      }

      .form-check-box {
        margin-top: 2rem;
        background-color: #f6f8f9;
        padding: 2rem 3rem;
        border: 0.1rem solid var(--border-color);
        border-radius: 1rem;

        & > *:not(:last-child) {
          margin-bottom: 2rem;
        }

        .check-group {
          display: flex;
          align-items: center;

          .check-input {
            display: none;

            &:checked ~ .check-label .check-circle {
              background-color: var(--blue-color);

              .icon {
                color: var(--white-color);
              }
            }
          }

          .check-label {
            display: grid;
            align-items: center;
            grid-template-columns: min-content max-content 1rem max-content;
            // background-color: red;
            grid-column-gap: 1rem;
            cursor: pointer;

            span {
              display: inline-block;
            }

            .check-circle {
              width: 2.5rem;
              height: 2.5rem;
              border: 0.1rem solid var(--border-color);
              border-radius: 50%;
              margin-right: 1.5rem;
              display: grid;
              place-items: center;
              // padding: .6rem;

              .icon {
                color: #f6f8f9;
                width: 1.1rem;
                height: 1.1rem;
              }
            }

            .text-one {
              font-size: 1.3rem;
            }

            .text-two {
              color: #84919a;
              font-size: 1.25rem;
            }
          }
        }
      }

      .form-submit {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.3rem 3rem;
        border-radius: 0.8rem;
        background-color: #eceded;
        border: none;
        outline: none;
        cursor: pointer;
        transition: all 0.3s;
        color: #c3c7ce;
        font-weight: 600;
        margin-top: 4rem;
        font-size: 1.3rem;
        margin-top: 0rem;
        // margin-left: 2rem;

        &:hover {
          transform: translateY(-0.15rem);
        }
      }

      .form-submit-active {
        background-color: var(--blue-color);
        color: var(--white-color);
      }
    }

    .note-img-wrap {
      color: var(--black-color);
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      text-transform: capitalize;
      font-weight: 500;
      margin-top: 2.5rem;

      .img-box {
        transform: translateY(0.3rem);
        margin-left: 0.5rem;
      }
    }

    .note-box {
      background-color: rgba(255, 192, 67, 0.1);
      padding: 1rem 2rem;
      border-radius: 0.6rem;

      p {
        font-size: 1.2rem;
        color: #596080;

        span {
          font-weight: 700;
          color: var(--black-color);
          margin-right: 0.8rem;
          text-transform: capitalize;
        }
      }

      & > * {
        display: flex;
        align-items: center;

        .img-box {
          margin-right: 1rem;
          transform: translateY(0.1rem);
        }

        p {
          color: #596080;
          font-size: 1.1rem;
          //   color: red;
        }
      }
    }

    .btn-box-wrap {
      margin-top: 4rem;

      .btn-reuse {
        padding: 1.2rem 3rem;
        border: none;
        font-size: 1.3rem;
        font-size: 500;
        background-color: #eceded;
        border: 0.1rem solid var(--border-color);
        color: #c3c7ce;
        border-radius: 0.6rem;
        cursor: pointer;
        transition: all 0.3s;
        margin-right: 3rem;
      }

      .dna-wrapper-box {
        background-color: unset;
        padding: unset;
        border: unset;
        // margin-left: 3rem;
      }

      .btn-reuse:hover {
        transform: translateY(-0.1rem);
      }

      .btn-reuse {
        margin-right: 2rem;
        border-radius: 0.6rem;
        border: 0.1rem solid var(--blue-color);
        color: var(--blue-color);
        background-color: var(--bg-white-color);
      }

      .active-btn {
        color: var(--white-color);
        background-color: var(--blue-color);
      }
    }
  }

  .note-wrap {
    margin-top: 7rem;
    width: 70%;

    .note-img-wrap {
      color: var(--black-color);
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      text-transform: capitalize;
      font-weight: 500;
      margin-top: 2.5rem;

      .img-box {
        transform: translateY(0.3rem);
        margin-left: 0.5rem;
      }
    }

    .note-box {
      background-color: rgba(255, 192, 67, 0.1);
      padding: 1rem 2rem;
      border-radius: 0.6rem;

      p {
        font-size: 1.2rem;
        color: #596080;

        span {
          font-weight: 700;
          color: var(--black-color);
          margin-right: 0.8rem;
          text-transform: capitalize;
        }
      }

      & > * {
        display: flex;
        align-items: center;

        .img-box {
          margin-right: 1rem;
          transform: translateY(0.1rem);
        }

        p {
          color: #596080;
          font-size: 1.1rem;
          //   color: red;
        }
      }
    }
  }

  .btn-box-wrap {
    margin-top: 4rem;
    display: flex;
    align-items: center;

    .btn-reuse {
      padding: 1.2rem 3rem;
      border: none;
      font-size: 1.3rem;
      font-size: 500;
      background-color: #eceded;
      border: 0.1rem solid var(--border-color);
      color: #c3c7ce;
      border-radius: 0.6rem;
      cursor: pointer;
      transition: all 0.3s;
      margin-right: 3rem;
    }

    .dna-wrapper-box {
      background-color: unset;
      padding: unset;
      border: unset;
      // margin-left: 3rem;
    }

    .form-submit {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.7rem 3rem;
      border-radius: 0.8rem;
      background-color: #eceded;
      border: none;
      outline: none;
      cursor: pointer;
      transition: all 0.3s;
      color: #c3c7ce;
      font-weight: 600;
      // margin-top: 4rem;

      &:hover {
        transform: translateY(-0.15rem);
      }
    }

    .form-submit-active {
      background-color: var(--blue-color);
      color: var(--white-color);
    }

    .btn-reuse:hover {
      transform: translateY(-0.1rem);
    }

    .btn-reuse {
      margin-right: 4rem;
      border-radius: 0.6rem;
      border: 0.1rem solid var(--blue-color);
      color: var(--blue-color);
      background-color: var(--bg-white-color);
    }

    .active-btn {
      color: var(--white-color);
      background-color: var(--blue-color);
    }
  }

  .skip-box {
    // background-color: red;
    margin-top: 3rem;
    font-size: 1.3rem;
    display: flex;
    align-items: center;

    .btn {
      margin-left: 2rem;
      padding: 0.8rem 2rem;
      border: none;
      font-size: 1.3rem;
      font-size: 500;
      cursor: pointer;
      transition: all 0.3s;
      border-radius: 0.6rem;
      border: 0.1rem solid var(--blue-color);
      color: var(--blue-color);
      background-color: var(--bg-white-color);

      &:hover {
        transform: translateY(-0.1rem);
      }
    }
  }

  .removable-wrap {
    margin-top: 2rem;
    padding-top: 2rem;
    margin-bottom: 3rem;

    & > *:not(:first-child) {
      margin-top: 3rem;
    }

    .form-group {
      display: flex;
      flex-direction: column;

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none; /* Remove default arrow */
        background-image: url("../../../assets/drop-down-icon.svg");
        background-repeat: no-repeat; /* Add custom arrow */
        background-position: 95% center;
        background-size: 2rem;
      }

      .label {
        // margin-bottom: 0.4rem;
        display: flex;
        align-items: center;
        color: #5b6871;

        span {
          margin-left: 0.4rem;
          color: #cc0905;
          display: inline-block;
          font-size: 1.7rem;
          transform: translateY(0.4rem);
        }
      }

      .input {
        border: 0.1rem solid var(--border-color);
        border-radius: 0.8rem;
        padding: 1rem 1rem;
        outline: none;
        width: 100%;
        font-size: 1.4rem;
        //   background-color: transparent;
        font-size: 1.35rem;
        color: var(--black-shade-color);
        color: var(--black-color);
        background-color: var(--bg-white-color);

        &::placeholder {
          font-size: 1.2rem;
          text-transform: capitalize;
        }

        &:focus {
          border: 0.1rem solid var(--blue-color);
          outline: none;
        }
      }

      .input-active {
        border: 0.1rem solid var(--blue-color);
      }
    }

    .remove-group {
      width: 100%;
      display: grid;
      grid-template-columns: min-content 1fr 1fr 5rem;
      align-items: center;
      grid-column-gap: 3rem;

      .step {
        background-color: var(--blue-color);
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        // margin-right: 2rem;
        color: var(--white-color);
        display: grid;
        place-items: center;
        font-size: 1.2rem;
        font-weight: 600;
      }

      .cancel-box {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        justify-self: flex-start;
        display: grid;
        place-items: center;
        border: 0.15rem solid #cc0905;
        cursor: pointer;

        .icon {
          color: #cc0905;
        }
      }
    }
  }

  .add-approval-box {
    align-self: flex-start;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--blue-color);

    .text {
      font-size: 1.3rem;
      font-weight: 500;
    }

    .icon {
      margin-right: 0.7rem;
      width: 1.3rem;
      height: 1.3rem;
    }
  }

  .btn-box-save {
    transform: translateY(-110%);
    // background-color: red;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-self: flex-end;

    & > *:first-child {
      margin-right: 2rem;
      background-color: var(--bg-white-color);
      color: var(--blue-color);
      border: 0.1rem solid var(--blue-color);
    }

    .button-blue-wrap {
      margin-bottom: 0rem;
    }
  }

  .role-information-box {
    // min-height: 30rem;
    background-color: var(--bg-white-color);
    box-shadow: var(--box-shadow-two);
    margin-top: -3.5rem;
    // padding: 2rem 0rem 4rem 0rem
    padding-bottom: 2rem;

    .title {
      padding: 2rem 0rem 0rem 3rem;
      color: var(--black-color);
      font-weight: 600;
      font-size: 1.6rem;
      margin-bottom: 2rem;
    }
  }

  .two-group-box {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0rem 3rem;
    grid-gap: 3rem;
    width: 80%;

    .form-group {
      display: flex;
      flex-direction: column;

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none; /* Remove default arrow */
        background-image: url("../../../assets/drop-down-icon.svg");
        background-repeat: no-repeat; /* Add custom arrow */
        background-position: 95% center;
        background-size: 2rem;
      }

      .label {
        margin-bottom: 0.4rem;
        display: flex;
        align-items: center;
        color: #5b6871;
        text-transform: capitalize;

        span {
          margin-left: 0.4rem;
          color: #cc0905;
          display: inline-block;
          font-size: 1.7rem;
          transform: translateY(0.4rem);
        }
      }

      .input {
        border: 0.1rem solid var(--border-color);
        border-radius: 0.8rem;
        padding: 1.3rem 1rem;
        outline: none;
        width: 100%;
        font-size: 1.4rem;
        //   background-color: transparent;
        font-size: 1.35rem;
        color: var(--black-shade-color);
        color: var(--black-color);
        background-color: var(--bg-white-color);

        &::placeholder {
          font-size: 1.2rem;
          text-transform: capitalize;
        }

        &:focus {
          border: 0.1rem solid var(--blue-color);
          outline: none;
        }
      }

      .input-active {
        border: 0.1rem solid var(--blue-color);
      }
    }
  }

  .underline {
    background-color: var(--border-color);
    height: 0.1rem;
    width: 100%;
    margin: 2rem 0rem 2rem 0rem;
  }

  .three-box {
    // background-color: red;
    padding: 1.5rem 4rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .box {
      font-size: 1.25rem;
      display: flex;
      align-items: center;

      .img-box {
        width: 3rem;
        height: 3rem;
        margin-right: 1rem;
        border-radius: 50%;
        overflow: hidden;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          display: block;
        }
      }

      .transfer {
        padding: 0.4rem 1.5rem;
        border-radius: 1rem;
        color: #0e73f6;
        background-color: #d7edff;
      }
    }

    .last-box {
      justify-self: center;
      // display: none;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        transform: translateY(-0.1rem);
      }
    }

    .text-title {
      color: #5b6871;
      font-size: 1.25rem;
    }
  }

  // .three-box-content{
  //   justify-items: space-between;
  // }

  .three-box-content {
    width: 100%;
    .form-group {
      grid-column: 1 / -1;
      // display: none;
      width: 100%;
      // background-color: red;

      .select-wrap {
        margin-top: 0.4rem;

        color: #1d1d18 !important;
        & > *:nth-child(3) {
          padding: 0.3rem;
          border: 0.1rem solid var(--border-color);
          border-radius: 0.8rem;

          color: #1d1d18;

          & > *:nth-child(1) {
            & > *:nth-child(1) {
              color: #1d1d18;
            }
          }
        }
      }

      .select-wrap-multi {
        & > *:nth-child(3) {
          & > * {
            & > *:not(:last-child) {
              // color: #000000 !important;
              & > * {
                color: #ffffff !important;
              }
            }
          }
        }
      }
    }
  }

  .title-three-box {
    background-color: #f6f8f9;
    padding: 1rem 4rem;
  }

  // styles for billinbg  --------

  .card-box-billing-wrap {
    margin: 3rem 0rem;
    display: flex;
    // min-height: 25rem;

    .left-box {
      flex: 0 0 50%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      // background-color: red;

      .plan-upgrade-box {
        background-color: var(--blue-color);
        border-radius: 1rem;
        padding: 2rem;
        width: 38rem;
        color: var(--white-color);
        position: relative;
        min-height: 20rem;
        display: flex;
        flex-direction: column;
        box-shadow: var(--box-shadow-two);

        .time {
          font-size: 1rem;
          color: var(--border-color);
        }

        .amount {
          margin: 0.6rem 0rem;
          font-weight: 500;
        }

        .plan {
          font-size: 1.6rem;
        }

        & > * {
          position: relative;
          z-index: 3;
        }

        &::after {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: url("../../../assets/slash-plan-detail-img.png");
          background-size: cover;
          background-repeat: no-repeat;
          // transform: translateY(-50%);
        }

        .text-btn-box {
          display: flex;
          align-items: center;
          margin-bottom: auto;

          .text {
            font-size: 2rem;
            font-weight: 500;
          }

          .btn-blue-wrap {
            margin-left: auto;
            margin-bottom: 0rem;
            margin-top: 0rem;
            background-color: var(--bg-white-color);
            color: var(--blue-color);
            padding: 0.9rem 2rem;
          }
        }
      }
    }

    .right-box {
      flex: 1;
      // background-color: blue;

      .payment-info-wrap {
        background-color: var(--bg-white-color);
        box-shadow: var(--box-shadow-two);
        border-radius: 1rem;
        padding: 2rem;
        width: 38rem;
        // color: var(--white-color);
        position: relative;
        min-height: 20rem;
        display: flex;
        flex-direction: column;

        .top-box {
          margin-bottom: auto;
          display: flex;
          align-items: center;

          .text {
            margin-right: auto;
            font-size: 2rem;
            font-weight: 500;
            color: var(--blue-color);
          }

          .img-box {
            margin: 0rem 0.5rem;
            width: 1.5rem;
            height: 1.5rem;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              display: block;
              filter: saturate(0%);
            }
          }

          .input-switch {
            display: none;

            &:checked ~ .label-switch {
              background-color: var(--blue-color);

              & > *:first-child {
                background-color: var(--blue-color);
              }

              & > *:last-child {
                background-color: var(--bg-white-color);
              }
            }
          }

          .label-switch {
            cursor: pointer;
            width: 5rem;
            height: 2.5rem;
            background-color: #eceded;
            border-radius: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s;
            box-shadow: var(--box-shadow-one);

            span {
              display: inline-block;
              width: 2rem;
              height: 2rem;
              background-color: #eceded;
              border-radius: 50%;
              transition: all 0.3s;
            }

            & > *:first-child {
              margin-right: 0.5rem;
              background-color: var(--bg-white-color);
            }
          }

          .auto {
            // ma
            font-size: 1.45rem;
          }
        }

        .name {
          font-weight: 500;
          margin-bottom: 0.5rem;
        }

        .address {
          font-size: 1.15rem;
        }

        .num {
          margin: 0.5rem 0rem;
        }
      }
    }
  }

  .group-budget-wrap {
    height: 40rem;
    display: grid;
    place-items: center;

    .content-index-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;

      .img-box {
        width: 12rem;
        height: 12rem;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          display: block;
        }
      }

      .text-box {
        margin: 2rem 0rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        .text {
          font-size: 1.3rem;
        }
      }

      .btn-blue-wrap {
        & > *:last-child {
          margin-left: 0.7rem;
        }
      }
    }
  }

  .check-label-box {
    // background-color: red;
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    .check {
      display: none;

      &:checked ~ .label span {
        background-color: var(--blue-color);
        border: unset;

        .icon {
          visibility: visible;
        }
      }
    }

    .label {
      display: flex;
      align-items: center;
      font-size: 1.3rem;
      cursor: pointer;

      span {
        margin-right: 1.5rem;
        width: 1.7rem;
        height: 1.7rem;
        border-radius: 0.3rem;
        border: 0.1rem solid var(--border-color);
        display: grid;
        place-items: center;
        transition: all 0.3s;

        .icon {
          width: 1rem;
          height: 1rem;
          color: var(--white-color);
          visibility: hidden;
        }
      }
    }
  }

  .select {
    & > * {
      background-color: var(--bg-white-color);
      color: #1d1d18;
      &:nth-child(3) {
        // border: none !important;
        min-width: 9rem;
        background-color: var(--bg-white-color);
        border-radius: 0.7rem !important;
        border: 0.1rem solid var(--border-color);
        color: #1d1d18;

        & > *:nth-child(1) {
          & > *:nth-child(1) {
            color: #1d1d18;
          }
        }
      }
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
