.employee-congratulations {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: var(--black-color);
}

.emp-success-msg {
  text-align: center;
  p {
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
    align-items: center;
    text-align: center;
    position: relative;
  }
}
