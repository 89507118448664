.action-bar-wrapper-box {
  width: 100%;
  overflow-y: hidden;
}
.action-bar-wrapper-box .action_bar_revvex__error {
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  gap: 2rem;
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  width: 100%;
  background-color: #fff5f5;
  width: 100%;
}
.action-bar-wrapper-box .action_bar_revvex__error .img-box {
  width: 2.5rem;
  height: 2.5rem;
  transform: translateY(0.2rem);
}
.action-bar-wrapper-box .action_bar_revvex__error .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.action-bar-wrapper-box .action_bar_revvex__error .text {
  font-size: 1.3rem;
  color: #020202;
}
.action-bar-wrapper-box .action_bar_revvex__error .action-box {
  margin-right: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.action-bar-wrapper-box .action_bar_revvex__error .action-box:hover .arrow-icon {
  transform: translateX(0.2rem);
}
.action-bar-wrapper-box .action_bar_revvex__error .action-box .text {
  font-weight: 700;
  margin-right: 1rem;
  font-size: 1.4rem;
}
.action-bar-wrapper-box .action_bar_revvex__error .action-box .arrow-icon {
  border-radius: 50%;
  width: 1.8rem;
  height: 1.8rem;
  display: grid;
  place-items: center;
  transition: all 0.3s;
  position: relative;
}
.action-bar-wrapper-box .action_bar_revvex__error .action-box .arrow-icon::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 170%;
  height: 170%;
  border-radius: 50%;
  z-index: 1;
}
.action-bar-wrapper-box .action_bar_revvex__error .action-box .arrow-icon::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 2;
}
.action-bar-wrapper-box .action_bar_revvex__error .action-box .arrow-icon > * {
  z-index: 3;
}
.action-bar-wrapper-box .action_bar_revvex__error .action-box .arrow-icon .icon {
  width: 1rem;
  height: 1rem;
  color: #ffffff;
  fill: #ffffff;
}
.action-bar-wrapper-box .action_bar_revvex__error .cancel {
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.action-bar-wrapper-box .action_bar_revvex__error .cancel svg {
  width: 1.2rem;
  height: 1.2rem;
}
.action-bar-wrapper-box .action_bar_revvex__error .cancel {
  background-color: #ffdfdd;
}
.action-bar-wrapper-box .action_bar_revvex__error .cancel svg {
  fill: #ff0f00;
}
.action-bar-wrapper-box .action_bar_revvex__error .action-box .text {
  color: #ff0f00;
}
.action-bar-wrapper-box .action_bar_revvex__error .action-box .arrow-icon::after {
  background-color: #ffdfdd;
}
.action-bar-wrapper-box .action_bar_revvex__error .action-box .arrow-icon::before {
  background-color: #ff0f00;
}
.action-bar-wrapper-box .action_bar_revvex__info {
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  gap: 2rem;
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  width: 100%;
  background: #e9f5ff !important;
}
.action-bar-wrapper-box .action_bar_revvex__info .img-box {
  width: 2.5rem;
  height: 2.5rem;
  transform: translateY(0.2rem);
}
.action-bar-wrapper-box .action_bar_revvex__info .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.action-bar-wrapper-box .action_bar_revvex__info .text {
  font-size: 1.3rem;
  color: #020202;
}
.action-bar-wrapper-box .action_bar_revvex__info .action-box {
  margin-right: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.action-bar-wrapper-box .action_bar_revvex__info .action-box:hover .arrow-icon {
  transform: translateX(0.2rem);
}
.action-bar-wrapper-box .action_bar_revvex__info .action-box .text {
  font-weight: 700;
  margin-right: 1rem;
  font-size: 1.4rem;
}
.action-bar-wrapper-box .action_bar_revvex__info .action-box .arrow-icon {
  border-radius: 50%;
  width: 1.8rem;
  height: 1.8rem;
  display: grid;
  place-items: center;
  transition: all 0.3s;
  position: relative;
}
.action-bar-wrapper-box .action_bar_revvex__info .action-box .arrow-icon::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 170%;
  height: 170%;
  border-radius: 50%;
  z-index: 1;
}
.action-bar-wrapper-box .action_bar_revvex__info .action-box .arrow-icon::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 2;
}
.action-bar-wrapper-box .action_bar_revvex__info .action-box .arrow-icon > * {
  z-index: 3;
}
.action-bar-wrapper-box .action_bar_revvex__info .action-box .arrow-icon .icon {
  width: 1rem;
  height: 1rem;
  color: #ffffff;
  fill: #ffffff;
}
.action-bar-wrapper-box .action_bar_revvex__info .cancel {
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.action-bar-wrapper-box .action_bar_revvex__info .cancel svg {
  width: 1.2rem;
  height: 1.2rem;
}
.action-bar-wrapper-box .action_bar_revvex__info .cancel {
  background-color: #b9defe;
}
.action-bar-wrapper-box .action_bar_revvex__info .cancel svg {
  fill: #476885;
}
.action-bar-wrapper-box .action_bar_revvex__info .action-box .text {
  color: #476885;
}
.action-bar-wrapper-box .action_bar_revvex__info .action-box .arrow-icon::after {
  background-color: #b9defe;
}
.action-bar-wrapper-box .action_bar_revvex__info .action-box .arrow-icon::before {
  background-color: #476885;
}
.action-bar-wrapper-box .action_bar_revvex__warning {
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  gap: 2rem;
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  width: 100%;
  background: #fff6ed !important;
  position: relative;
}
.action-bar-wrapper-box .action_bar_revvex__warning .img-box {
  width: 2.5rem;
  height: 2.5rem;
  transform: translateY(0.2rem);
}
.action-bar-wrapper-box .action_bar_revvex__warning .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.action-bar-wrapper-box .action_bar_revvex__warning .text {
  font-size: 1.3rem;
  color: #020202;
}
.action-bar-wrapper-box .action_bar_revvex__warning .action-box {
  margin-right: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.action-bar-wrapper-box .action_bar_revvex__warning .action-box:hover .arrow-icon {
  transform: translateX(0.2rem);
}
.action-bar-wrapper-box .action_bar_revvex__warning .action-box .text {
  font-weight: 700;
  margin-right: 1rem;
  font-size: 1.4rem;
}
.action-bar-wrapper-box .action_bar_revvex__warning .action-box .arrow-icon {
  border-radius: 50%;
  width: 1.8rem;
  height: 1.8rem;
  display: grid;
  place-items: center;
  transition: all 0.3s;
  position: relative;
}
.action-bar-wrapper-box .action_bar_revvex__warning .action-box .arrow-icon::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 170%;
  height: 170%;
  border-radius: 50%;
  z-index: 1;
}
.action-bar-wrapper-box .action_bar_revvex__warning .action-box .arrow-icon::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 2;
}
.action-bar-wrapper-box .action_bar_revvex__warning .action-box .arrow-icon > * {
  z-index: 3;
}
.action-bar-wrapper-box .action_bar_revvex__warning .action-box .arrow-icon .icon {
  width: 1rem;
  height: 1rem;
  color: #ffffff;
  fill: #ffffff;
}
.action-bar-wrapper-box .action_bar_revvex__warning .cancel {
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.action-bar-wrapper-box .action_bar_revvex__warning .cancel svg {
  width: 1.2rem;
  height: 1.2rem;
}
.action-bar-wrapper-box .action_bar_revvex__warning .cancel {
  background-color: #ffe6cd;
  position: absolute;
  right: 4rem;
}
.action-bar-wrapper-box .action_bar_revvex__warning .cancel svg {
  fill: #ea872d;
}
.action-bar-wrapper-box .action_bar_revvex__warning .action-box .text {
  color: #ea872d;
}
.action-bar-wrapper-box .action_bar_revvex__warning .action-box .arrow-icon::after {
  background-color: #ffe6cd;
}
.action-bar-wrapper-box .action_bar_revvex__warning .action-box .arrow-icon::before {
  background-color: #ea872d;
}
.action-bar-wrapper-box .action_bar_revvex_show {
  padding: 1rem 4rem;
  max-height: 7rem;
  transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
}
.action-bar-wrapper-box .action_bar_revvex__transform {
  transform: translateY(-100%);
}

@keyframes translateActionUp {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}/*# sourceMappingURL=ActionBar.css.map */