.notification-modal-wrap {
  height: 100vh;
  background-color: transparent !important;
  width: 100vw;
  position: fixed;
  z-index: 5000;

  .trans-box {
    height: 100vh;
    width: calc(100vw);
    // background-color: transparent !important;
    position: absolute;
    left: 0;
    top: 0;
    backdrop-filter: blur(3px);
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 2;
  }

  .content-wrap {
    height: 100vh;
    width: 40rem;
    background-color: var(--bg-white-color);
    position: absolute;
    right: 0;
    top: 0;
    animation: moveRightNotify 0.5s ease-in-out;
    color: var(--black-color);
    padding: 5rem 3rem 3rem 3rem;
    display: flex;
    flex-direction: column;
    z-index: 3;

    .title-wrap {
      // background-color: red;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 1.5rem;

      .page-title {
        flex: 0 0 5%;
        //   background-color: red;
        font-weight: 800;
        font-size: 2rem;
        white-space: nowrap;
      }

      .mark-all-as-read {
        span {
          align-self: flex-end;
          padding: 0.7rem 2rem;
          border-radius: 1.5rem;
          background-color: #cccccc;
          font-size: 1rem;
          font-weight: 600;
          color: var(--blue-color);
          cursor: pointer;
          transition: all 0.3s;

          &:hover {
            transform: translateY(-0.1rem);
            background-color: var(--blue-color);
            color: #ffffff;
          }
        }
      }
    }

    .cancel-box {
      position: absolute;
      top: 1rem;
      left: 1rem;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 0.6rem;
      justify-self: flex-start;
      display: grid;
      place-items: center;
      border: 0.15rem solid #cc0905;
      cursor: pointer;

      .icon {
        color: #cc0905;
      }
    }

    .notify-wrap {
      // height: 100vh;
      padding: 0rem 2rem 2rem 0rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      flex: 1;
      overflow-y: scroll;
      // background-color: red;

      &::-webkit-scrollbar {
        display: none;
      }

      .message-box {
        padding: 1rem 1rem;
        border: 0.1rem solid var(--blue-color);
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .message-title {
          font-weight: 600;
          font-size: 1.5rem;
          text-transform: capitalize;
        }

        .message-text {
          font-size: 1.35rem;
          color: #676767;
        }

        .messagebox-footer {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 1.5rem;
        }

        .date {
          font-size: 1.2rem;
          color: #676767;
          opacity: 0.5;
        }

        .read {
          align-self: flex-end;
          padding: 0.7rem 2rem;
          border-radius: 1.5rem;
          background-color: #cccccc;
          font-size: 1rem;
          font-weight: 600;
          color: var(--blue-color);
          cursor: pointer;
          transition: all 0.3s;

          &:hover {
            transform: translateY(-0.1rem);
            background-color: var(--blue-color);
            color: #ffffff;
          }
        }

        .read-active {
          background-color: var(--blue-color) !important;
          color: #ffffff;
        }
      }

      .message-box-inactive {
        opacity: 0.35;
      }
    }
  }
}

@keyframes moveRightNotify {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
