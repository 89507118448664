.profile-setting-wrap {
  padding: 4rem;
}
.profile-setting-wrap .subtitle-box {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  border-bottom: 0.1rem solid var(--border-color);
  width: 80%;
  padding-bottom: 1.5rem;
}
.profile-setting-wrap .subtitle-box > *:not(:last-child) {
  margin-right: 0.5rem;
}
.profile-setting-wrap .subtitle-box .text {
  cursor: pointer;
}
.profile-setting-wrap .subtitle-box .active {
  font-weight: 600;
  color: var(--black-color);
}
.profile-setting-wrap .title-text-box {
  width: 80%;
  padding-bottom: 1.5rem;
}
.profile-setting-wrap .title-text-box .title {
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: var(--black-color);
}
.profile-setting-wrap .title-text-box .text {
  font-size: 1.35rem;
  cursor: pointer;
}
.profile-setting-wrap .form .form-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.7rem 3rem;
  border-radius: 0.8rem;
  background-color: #eceded;
  border: none;
  outline: none;
  transition: all 0.3s;
  color: #c3c7ce;
  font-weight: 600;
  margin-top: 4rem;
}
.profile-setting-wrap .form .form-submit-active {
  background-color: var(--blue-color);
  color: var(--white-color);
  cursor: pointer;
}
.profile-setting-wrap .form .form-submit-active:hover {
  transform: translateY(-0.15rem);
}
.profile-setting-wrap .form .form-submit-forget-password {
  margin-top: 2rem;
}
.profile-setting-wrap .form .image-display-box {
  display: flex;
  flex-direction: column;
}
.profile-setting-wrap .form .image-display-box .text {
  font-size: 1.25rem;
  color: #84919a;
}
.profile-setting-wrap .form .image-display-box .wrap {
  width: 20rem;
  height: 17rem;
  position: relative;
  margin-bottom: 2rem;
}
.profile-setting-wrap .form .image-display-box .wrap .img-display {
  position: absolute;
  top: 0;
  left: 0;
  height: 17rem;
  width: 17rem;
  border-radius: 50%;
  z-index: 1;
}
.profile-setting-wrap .form .image-display-box .wrap .img-display .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 50%;
}
.profile-setting-wrap .form .image-display-box .wrap .input {
  display: none;
}
.profile-setting-wrap .form .image-display-box .wrap .label {
  position: absolute;
  bottom: -2rem;
  right: 1rem;
  z-index: 2;
  width: 6rem;
  height: 6rem;
}
.profile-setting-wrap .form .image-display-box .wrap .label .img-box {
  width: 4rem;
  height: 4rem;
  background-color: var(--blue-color);
  position: relative;
  border-radius: 50%;
  margin-left: 3rem;
}
.profile-setting-wrap .form .image-display-box .wrap .label .img-box .img {
  transform: translateY(-1rem) translateX(-2rem);
  cursor: pointer;
  width: 200%;
  height: 200%;
  display: block;
}
.profile-setting-wrap .form .form-group-wrap {
  display: flex;
  flex-direction: column;
  border-bottom: 0.1rem solid var(--border-color);
  padding-bottom: 1.5rem;
  margin-top: 2rem;
  width: 80%;
}
.profile-setting-wrap .form .form-group-wrap .title {
  font-weight: 700;
  font-size: 1.45rem;
  margin-bottom: 1rem;
  color: var(--black-color);
}
.profile-setting-wrap .form .form-group-wrap .wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 2rem;
}
.profile-setting-wrap .form .form-group-wrap .wrap .form-group {
  display: flex;
  flex-direction: column;
}
.profile-setting-wrap .form .form-group-wrap .wrap .form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  background-image: url("../../../assets/drop-down-icon.svg");
  background-repeat: no-repeat; /* Add custom arrow */
  background-position: 95% center;
  background-size: 2rem;
}
.profile-setting-wrap .form .form-group-wrap .wrap .form-group .select-class {
  background-color: var(--bg-white-color);
  color: #1d1d18;
}
.profile-setting-wrap .form .form-group-wrap .wrap .form-group .select-class > *:nth-child(3) {
  padding: 0.3rem;
  background-color: var(--bg-white-color);
  border: 0.1rem solid var(--border-color);
}
.profile-setting-wrap .form .form-group-wrap .wrap .form-group .select-class > *:nth-child(3) > *:nth-child(1) > *:nth-child(1) {
  color: var(--black-color);
}
.profile-setting-wrap .form .form-group-wrap .wrap .form-group .label {
  margin-bottom: 0.4rem;
  display: flex;
  align-items: center;
  color: #5b6871;
}
.profile-setting-wrap .form .form-group-wrap .wrap .form-group .label span {
  margin-left: 0.4rem;
  color: #cc0905;
  display: inline-block;
  font-size: 1.7rem;
  transform: translateY(0.4rem);
}
.profile-setting-wrap .form .form-group-wrap .wrap .form-group .input {
  border: 0.1rem solid var(--border-color);
  border-radius: 0.8rem;
  padding: 1.3rem 1rem;
  outline: none;
  width: 100%;
  font-size: 1.4rem;
  font-size: 1.35rem;
  color: var(--black-color);
  background-color: var(--bg-white-color);
}
.profile-setting-wrap .form .form-group-wrap .wrap .form-group .input::-moz-placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.profile-setting-wrap .form .form-group-wrap .wrap .form-group .input::placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.profile-setting-wrap .form .form-group-wrap .wrap .form-group .input:focus {
  border: 0.1rem solid var(--blue-color);
  outline: none;
}
.profile-setting-wrap .form .form-group-wrap .wrap .form-group .input-active {
  border: 0.1rem solid var(--blue-color);
}/*# sourceMappingURL=ProfileSetting.css.map */