.three-box-permission-common {
  padding: 0rem 4rem !important;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  cursor: pointer;
}
.three-box-permission-common .input-check-box {
  justify-self: center;
  background-color: var(--white-color);
  width: 2rem;
  height: 2rem;
  border-radius: 0.4rem;
  border: 0.1rem solid var(--border-color);
  display: grid;
  place-items: center;
}
.three-box-permission-common .input-check-box .icon {
  color: var(--white-color);
  width: 1rem;
  height: 1rem;
}
.three-box-permission-common .box {
  font-size: 1.25rem;
  display: flex;
  align-items: center;
}
.three-box-permission-common .box .img-box {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  border-radius: 50%;
  overflow: hidden;
}
.three-box-permission-common .box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.three-box-permission-common .box .transfer {
  padding: 0.4rem 1.5rem;
  border-radius: 1rem;
  color: #0E73F6;
  background-color: #D7EDFF;
}
.three-box-permission-common .last-box {
  justify-self: center;
}
.three-box-permission-common .text-title {
  color: #5B6871;
  font-size: 1.25rem;
}

.input-check-common-permission {
  display: none;
}
.input-check-common-permission:checked ~ .three-box-permission-common .input-check-box {
  background-color: var(--blue-color);
}

.underline-common-permission {
  margin-top: unset;
  margin-bottom: unset;
  margin: 0rem;
  opacity: 0.5;
}/*# sourceMappingURL=CommoPermissionBox.css.map */