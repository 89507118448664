.dashboard-layout-wrap {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.dashboard-layout-wrap .sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 25rem;
  background-color: var(--blue-color);
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: 6rem;
  overflow-x: hidden;
  transition: width 0.5s;
  z-index: 3;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.dashboard-layout-wrap .sidebar::-webkit-scrollbar {
  width: 0.5rem;
}
.dashboard-layout-wrap .sidebar::-webkit-scrollbar-track {
  background-color: var(--bg-color);
  border-radius: 10px;
}
.dashboard-layout-wrap .sidebar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #ccc;
}
.dashboard-layout-wrap .sidebar-reduce {
  width: 7rem;
}
.dashboard-layout-wrap .header {
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 25rem);
  height: 6rem;
  background-color: var(--bg-white-color);
  box-shadow: 0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.1);
  z-index: 3;
  transition: width 0.5s;
}
.dashboard-layout-wrap .header-reduce {
  width: calc(100% - 7rem);
  z-index: 2;
}
.dashboard-layout-wrap .main {
  position: absolute;
  top: 6rem;
  right: 0;
  width: calc(100% - 25rem);
  background-color: var(--bg-color);
  height: calc(100vh - 6rem);
  z-index: 1;
  overflow-y: scroll;
  transition: width 0.5s;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.dashboard-layout-wrap .main::-webkit-scrollbar:horizontal {
  display: none;
}
.dashboard-layout-wrap .main::-webkit-scrollbar {
  width: 0.6rem;
}
.dashboard-layout-wrap .main::-webkit-scrollbar-track {
  background-color: transparent !important;
}
.dashboard-layout-wrap .main::-webkit-scrollbar-thumb {
  background: var(--blue-color);
  border-radius: 30rem;
}
.dashboard-layout-wrap .main::-webkit-scrollbar-thumb:hover {
  background: var(--blue-color);
}
.dashboard-layout-wrap .main-reduce {
  width: calc(100% - 7rem);
  z-index: 1;
}/*# sourceMappingURL=DashboardLayout.css.map */