.auth-layout-wrap {
  width: 100vw;
  min-height: 100vh;
  // background-color: red;
  display: flex;

  .left-box {
    flex: 0 0 50%;
    background-color: var(--bg-white-color);
    display: grid;
    place-items: center;
    box-shadow: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.08);
    position: relative;
    z-index: 2;
    padding-top: 4rem;

    .content-box {
      align-self: flex-start;
      width: 65%;
      display: flex;
      flex-direction: column;
      height: 75%;
      //   background-color: red;
      justify-content: space-between;

      .logo-box {
        //   width: 10rem;
        align-self: flex-start;
        // background-color: red;
        cursor: pointer;
      }

      .list-box {
        display: flex;
        flex-direction: column;
        margin-bottom: 7rem;
        overflow-x: hidden;

        & > *:not(:last-child) {
          margin-bottom: 4rem;
        }

        .item {
          display: flex;
          align-items: center;
          span {
            flex: 0 0 10%;
            .icon {
              background-color: var(--blue-color);
              // background-image: var(--purple-gradient-color);
              width: 3rem;
              height: 3rem;
              border-radius: 50%;
              display: grid;
              place-items: center;
              padding: 0.9rem;
              color: var(--white-color);
              box-shadow: var(--box-shadow-one);
            }
          }

          .text {
            font-size: 1.6rem;
            flex: 1;
          }
        }
      }

      .list-box-anime {
        & > *:first-child {
          animation: displayOne 1.5s cubic-bezier(0, 0, 1, 0) backwards;
        }

        & > *:nth-child(2) {
          animation: displayOne 1.5s 1.5s cubic-bezier(0, 0, 1, 0) backwards;
        }

        & > *:last-child {
          animation: displayOne 1.5s 3s cubic-bezier(0, 0, 1, 0) backwards;
        }
      }

      .copy-text {
        color: #84919a;
      }
    }
  }

  .right-box {
    flex: 1;
    background-color: #f8f9ff;
    display: grid;
    place-items: center;
    position: relative;
    z-index: 1;

    .wrap {
      //   width: 65%;
      display: flex;
      flex-direction: column;
      //   justify-content: space-between;
      height: 100%;
      //   height: 100%;
      // background-color: red;
      padding-bottom: 4rem;

      .route-to-sign-up {
        span{
          cursor: pointer;
          color: var(--blue-color);
          font-weight: 600;
        }
      }

      .content-wrap {
        flex: 0 0 85%;
        display: grid;
        place-items: center;
        // background-color: red;
      }

      .content-box {
        width: 100%;
        min-height: 20rem;
        // border: 0.1rem solid #dde2e4;
        background-color: var(--white-color);
        box-shadow: var(--box-shadow-two);
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        border: 0.1rem solid var(--border-color);
        padding: 3rem 4rem;

        .title {
          font-weight: 700;
          font-size: 2.5rem;
          margin-bottom: 1rem;
          color: var(--black-color);
        }

        .small-text {
          font-size: 1.3rem;
        }

        .btn-box {
          display: flex;
          // background-color: red;
          padding: 1rem;
          border: 0.1rem solid var(--border-color);
          border-radius: 0.7rem;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: transform 0.3s;

          &:hover {
            transform: translateY(-0.1rem);
          }

          .img-box {
            width: 3rem;
            height: 3rem;
            margin-right: 1.5rem;

            .img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              display: block;
            }
          }
        }

        .btn-box-first {
          margin-top: 4rem;
        }

        .or-box {
          margin: 2.5rem 0rem;
          display: grid;
          grid-template-columns: 1fr min-content 1fr;
          align-items: center;
          grid-column-gap: 1.5rem;

          & > *:first-child,
          & > *:last-child {
            height: 0.1rem;
            background-color: #dde2e4;
          }

          span {
            font-size: 1.3rem;
          }
        }

        .term-privacy-box {
          // background-color: red;
          margin-top: 3rem;

          & > *:first-child {
            margin-bottom: 0.5rem;
          }

          .text {
            color: #84919a;
            font-size: 1.35rem;

            span {
              color: var(--blue-color);
              cursor: pointer;
            }
          }
        }

        .already-box {
          margin-top: 5rem;

          .text {
            font-size: 1.4rem;

            span {
              color: var(--blue-color);
              cursor: pointer;
              margin-left: 0.6rem;
              font-weight: 500;
            }
          }
        }

        .already-box-login {
          margin-top: 0rem;
        }
      }

      .get-help-box {
        // flex: 1;
        background-color: var(--blue-color);
        // background-image: var(--purple-gradient-color);
        padding: 1.5rem 2rem;
        border-radius: 5rem;
        margin-left: auto;
        display: flex;
        align-items: center;
        color: var(--white-color);
        cursor: pointer;
        margin-top: auto;
        transition: transform 0.3s;

        &:hover {
          transform: translateY(-0.2rem);
        }

        .img-box {
          width: 2rem;
          height: 2rem;

          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: block;
          }
        }

        .text {
          margin-right: 1rem;
          font-weight: 600;
        }
      }
    }

    // check mail styles start
    .check-mail-wrap {
      width: 35rem;
      height: 100%;
      // background-color: red;
      display: grid;
      place-items: center;

      .img-box {
        width: 12rem;
        height: 12rem;
        // background-color: ;

        .img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          display: block;
        }
      }

      .img-box-email {
        width: 8rem;
        height: 8rem;
        margin-bottom: 3rem;
      }

      .title {
        font-weight: 700;
        font-size: 2.5rem;
        margin-bottom: 1rem;
        color: var(--black-color);
      }

      .small-text {
        font-size: 1.3rem;
      }

      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.3rem 3rem;
        border-radius: 0.8rem;
        background-color: var(--blue-color);
        border: none;
        outline: none;
        cursor: pointer;
        transition: all 0.3s;
        color: var(--white-color);
        font-weight: 600;
        margin-top: 3rem;

        &:hover {
          transform: translateY(-0.15rem);
        }
      }

      .already-box {
        margin-top: 4rem;

        .text {
          font-size: 1.4rem;

          span {
            color: var(--blue-color);
            cursor: pointer;
            margin-left: 0.6rem;
            font-weight: 500;
          }
        }
      }
    }
  }

  .go-back-box {
    // background-color: red;
    align-self: flex-start;
    display: flex;
    align-items: center;
    color: var(--blue-color);
    font-weight: 500;
    margin-top: 4rem;
    cursor: pointer;
    font-size: 1.3rem;

    &:hover .icon {
      transform: translateX(-0.3rem);
    }

    .text {
      margin-left: 0.5rem;
    }

    .icon {
      transition: all 0.3s;
    }
  }
}

@keyframes displayOne {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
