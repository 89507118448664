.emp-card-wrapper {
  // display: flex;
  // gap: 3rem;
  padding: 2rem 0rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
  grid-gap: 3rem;
  grid-column-gap: 2rem;
  .emp-card {
    // width: 255px;
    // width: 23.2%;
    // // width: 50%;
    // // width: 26.5rem;
    // height: 12rem;
    // // background: #ffffff;
    // border-radius: 6px;
    // flex: none;
    // order: 0;
    // padding: 3rem;
    // border-radius: 1rem;
    // background-color: var(--bg-white-color);
    // box-shadow: var(--box-shadow-one);
    // flex-grow: 0;
    padding: 3rem;
    border-radius: 1rem;
    background-color: var(--bg-white-color);
    box-shadow: var(--box-shadow-one);
  }
  .card-name {
    margin-bottom: 1.5rem;
    font-size: 1.3rem;
  }
  .card-value {
    font-weight: 700;
    font-size: 2rem;
    color: var(--black-color);
    // width: 100%;
    overflow-wrap: break-word;
  }
  .emp-card-pointer {
    cursor: pointer;
  }
  .emp-card-active {
    border: 1px solid var(--blue-color);
  }
}

.emp-total-info-detail-box {
  display: flex;
  align-items: center;
  gap: 4rem;
  // margin: 2rem 0rem;
  margin-bottom: 2rem;
  margin-top: 2rem;

  .detail-box {
    display: flex;
    flex-direction: column;

    .title {
      font-weight: 500;
      color: #84919a;
    }

    .value {
      font-weight: 800;
      font-size: 2.5rem;
      color: #5b6871;
    }
  }
}
// filter
.filter-icon {
  transition: all 0.5s;
  border: 0.1rem solid var(--blue-color);
  border-radius: 0.5rem;
  padding: 0 0.5rem;
  width: 3rem;
  margin-top: 0.4rem;
  display: flex;
  place-items: center;
  color: var(--blue-color);
  cursor: pointer;
  height: 3.4rem;
  &:hover {
    transform: scale(1.05);
  }
}
