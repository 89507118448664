.budget-settings-common-modal-wrap {
  background-color: var(--bg-white-color);
  width: 40rem;
  border-radius: 1rem;
  min-height: 35rem;
  display: flex;
  flex-direction: column;

  .btn-blue-wrap{
    align-self: flex-start;
    margin-bottom: -2rem;
    background-color: #cc0905 !important;
   }

  .form-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.7rem 3rem;
    border-radius: 0.8rem;
    background-color: #eceded;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
    color: #c3c7ce;
    font-weight: 600;
    margin-top: 4rem;
    margin-left: 2rem;
    align-self: flex-start;
    margin-bottom: 2rem;

    &:hover {
      transform: translateY(-0.15rem);
    }
  }

  .form-submit-active {
    background-color: var(--blue-color);
    color: var(--white-color);
  }

  .form-submit-forget-password {
    margin-top: 2rem;
  }

  .title-box {
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    border-bottom: 0.1rem solid var(--border-color);

    .title {
      font-size: 1.7rem;
      font-weight: 600;
      color: var(--black-color);
    }

    .cancel-box {
      margin-left: auto;
      background-color: #ffefeb;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      display: grid;
      place-items: center;
      cursor: pointer;

      .icon {
        color: #cc0905;
      }
    }
  }

  .content-box {
    flex: 1;
    padding: 1rem 2rem 3rem 2rem;
    background-color: var(--bg-fade-color);
    // background-color: red;

    .text {
      font-size: 1.3rem;
      margin: 1rem 0rem 0rem 0rem;
      line-height: 1.6rem;
    }

    & > *:not(:last-child) {
      margin-bottom: 2rem;
    }

    .file-input-box {
      // background-color: red;
      display: flex;
      flex-direction: column;

      p {
        margin-bottom: 0.7rem;
      }

      input {
        display: none;
      }

      label {
        color: var(--blue-color);
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon {
          color: currentColor;
          margin-right: 0.7rem;
        }
      }
    }

    .form-group {
      display: flex;
      flex-direction: column;

     

      .select{
        & > * {
          background-color: var(--bg-white-color);
          color: #1d1d18;
          &:nth-child(3) {
            // border: none !important;
            min-width: 9rem;
            background-color: var(--bg-white-color);
            border-radius: 0.7rem !important;
            border: 0.1rem solid var(--border-color);
    
            & > *:nth-child(1) {
              & > *:nth-child(1) {
                color: var(--black-color);
              }
            }
          }
        }
      }

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none; /* Remove default arrow */
        background-image: url("../../../assets/drop-down-icon.svg");
        background-repeat: no-repeat; /* Add custom arrow */
        background-position: 95% center;
        background-size: 2rem;
      }

      .label {
        margin-bottom: 0.4rem;
        display: flex;
        align-items: center;
        color: #5b6871;

        span {
          margin-left: 0.4rem;
          color: #cc0905;
          display: inline-block;
          font-size: 1.7rem;
          transform: translateY(0.4rem);
        }
      }

      .input {
        border: 0.1rem solid var(--border-color);
        border-radius: 0.8rem;
        padding: 1.3rem 1rem;
        outline: none;
        width: 100%;
        font-size: 1.4rem;
        //   background-color: transparent;
        font-size: 1.35rem;
        color: var(--black-color);
        background-color: var(--bg-white-color);

        &::placeholder {
          font-size: 1.2rem;
          text-transform: capitalize;
        }

        &:focus {
          border: 0.1rem solid var(--blue-color);
          outline: none;
        }
      }

      .input-active {
        border: 0.1rem solid var(--blue-color);
      }
    }

    .form-group-check{
      margin-top: -1rem;
      .input-check-box{
        display: none;

        &:checked ~ .label-check-box .new-check{
          background-color: var(--blue-color);
          border: none;
        }

        &:checked ~ .label-check-box .new-check .icon{
          display: block;
        }
      }

      .label-check-box{
        display: flex;
        align-items: center;
        gap: 1rem;
        cursor: pointer;

        .text{
          margin-top: unset;
          font-weight: 500;
          // color: #FFC700;
        }

        .new-check{
          border-radius: .5rem;
          border: .1rem solid #cccccc;
          display: grid;
          place-items: center;
          width: 1.8rem;
          height: 1.8rem;

          .icon{
            width: 50%;
            height: 50%;
            display: none;
            color: #ffffff;
          }
        }
      }
    }

    .check-label-box {
      // background-color: red;
      margin: 2rem 0rem;
      display: flex;
      align-items: center;

      .check {
        display: none;

        &:checked ~ .label span {
          background-color: var(--blue-color);
          border: unset;

          .icon {
            visibility: visible;
          }
        }
      }

      .label {
        display: flex;
        align-items: center;
        font-size: 1.1rem;
        cursor: pointer;

        .blue {
          color: var(--blue-color);
          margin: 0rem 0.4rem;
        }

        span {
          margin-right: 1rem;
          width: 1.7rem;
          height: 1.7rem;
          border-radius: 0.3rem;
          border: 0.1rem solid var(--border-color);
          display: grid;
          place-items: center;
          transition: all 0.3s;

          .icon {
            width: 1rem;
            height: 1rem;
            color: var(--white-color);
            visibility: hidden;
          }
        }
      }
    }
  }

  .content-box-long {
    height: 40rem;
    flex: unset;
    overflow-y: scroll;
    // background-color: red;

    //     &::-webkit-scrollbar {
    //         display: none;
    //    }

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0.5rem;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--bg-white-color);
      -webkit-border-radius: 10px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: var(--blue-color);
    }
  }

  .btn-box {
    padding: 1.5rem 2rem;
  }
}

// currency styles =---------

.form-group-currency-modal {
  display: flex;
  flex-direction: column;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; /* Remove default arrow */
    background-image: url("../../../assets/drop-down-icon.svg");
    background-repeat: no-repeat; /* Add custom arrow */
    background-position: 95% center;
    background-size: 2rem;
  }

  .label {
    margin-bottom: 0.4rem;
    display: flex;
    align-items: center;
    color: #5b6871;

    span {
      margin-left: 0.4rem;
      color: #cc0905;
      display: inline-block;
      font-size: 1.7rem;
      transform: translateY(0.4rem);
    }
  }

  .input {
    border: 0.1rem solid var(--border-color);
    border-radius: 0.8rem;
    padding: 1.3rem 1rem;
    outline: none;
    width: 100%;
    font-size: 1.4rem;
    //   background-color: transparent;
    font-size: 1.35rem;
    color: var(--black-shade-color);

    &::placeholder {
      font-size: 1.2rem;
      text-transform: capitalize;
    }

    &:focus {
      border: 0.1rem solid var(--blue-color);
      outline: none;
    }
  }

  .input-active {
    border: 0.1rem solid var(--blue-color);
  }
}
