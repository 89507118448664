.customization-wrap {
  min-height: 100vh;
  display: flex;
}
.customization-wrap .left-box {
  width: 23rem;
  background-color: var(--bg-white-color);
  border-right: 0.1rem solid var(--border-color);
  display: flex;
  flex-direction: column;
  padding: 3rem 2.5rem;
}
.customization-wrap .left-box .dashboard {
  margin-bottom: 2rem;
  margin-top: 3rem;
  text-transform: capitalize;
}
.customization-wrap .left-box .title {
  color: var(--blue-color);
  cursor: pointer;
  align-self: flex-start;
}
.customization-wrap .right-box {
  flex: 1;
  padding: 4rem;
}
.customization-wrap .right-box .title-btn-box {
  border-bottom: 0.1rem solid var(--border-color);
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
}
.customization-wrap .right-box .title-btn-box .button-blue-wrap {
  margin-bottom: unset;
}
.customization-wrap .right-box .title-btn-box .title {
  margin-right: auto;
  font-weight: 700;
  font-size: 1.5rem;
}
.customization-wrap .right-box .table-wrap-body .form .wrapper {
  margin-top: 3rem;
  margin-bottom: 4rem;
  border-bottom: 0.1rem solid var(--border-color);
  padding-bottom: 3rem;
}
.customization-wrap .right-box .table-wrap-body .form .wrapper .btn-set-up {
  align-self: flex-start;
  margin-top: 4rem;
  background-color: #eceded;
  padding: 1.2rem 4rem;
  color: #c3c7ce;
  text-align: center;
  cursor: pointer;
  border: 0.1rem solid var(--border-color);
  border-radius: 0.4rem;
  outline: none;
  transition: all 0.3s;
  font-weight: 500;
}
.customization-wrap .right-box .table-wrap-body .form .wrapper .btn-set-up:hover {
  transform: translateY(-0.1rem);
}
.customization-wrap .right-box .table-wrap-body .form .wrapper .btn-set-up-active {
  background-color: var(--blue-color);
  color: var(--white-color);
  border: none;
}
.customization-wrap .right-box .table-wrap-body .form .wrapper .box-title {
  color: var(--black-color);
  margin-bottom: 1rem;
  font-size: 1.5rem;
}
.customization-wrap .right-box .table-wrap-body .form .wrapper .small-text {
  font-size: 1.3rem;
}
.customization-wrap .right-box .table-wrap-body .form .wrapper .form-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 3rem;
}
.customization-wrap .right-box .table-wrap-body .form .wrapper .form-wrap .form-group {
  display: flex;
  flex-direction: column;
}
.customization-wrap .right-box .table-wrap-body .form .wrapper .form-wrap .form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  background-image: url("../../../assets/drop-down-icon.svg");
  background-repeat: no-repeat; /* Add custom arrow */
  background-position: 95% center;
  background-size: 2rem;
}
.customization-wrap .right-box .table-wrap-body .form .wrapper .form-wrap .form-group .label {
  margin-bottom: 0.4rem;
  display: flex;
  align-items: center;
  color: #5b6871;
}
.customization-wrap .right-box .table-wrap-body .form .wrapper .form-wrap .form-group .label span {
  margin-left: 0.4rem;
  color: #cc0905;
  display: inline-block;
  font-size: 1.7rem;
  transform: translateY(0.4rem);
}
.customization-wrap .right-box .table-wrap-body .form .wrapper .form-wrap .form-group .input {
  border: 0.1rem solid var(--border-color);
  border-radius: 0.8rem;
  padding: 1.3rem 1rem;
  outline: none;
  width: 100%;
  font-size: 1.4rem;
  font-size: 1.35rem;
  color: var(--black-color);
  background-color: var(--bg-white-color);
}
.customization-wrap .right-box .table-wrap-body .form .wrapper .form-wrap .form-group .input::-moz-placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.customization-wrap .right-box .table-wrap-body .form .wrapper .form-wrap .form-group .input::placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.customization-wrap .right-box .table-wrap-body .form .wrapper .form-wrap .form-group .input:focus {
  border: 0.1rem solid var(--blue-color);
  outline: none;
}
.customization-wrap .right-box .table-wrap-body .form .wrapper .form-wrap .form-group .input-active {
  border: 0.1rem solid var(--blue-color);
}
.customization-wrap .right-box .table-wrap-body .form .wrapper .form-wrap-six {
  grid-template-rows: repeat(3, 1fr);
}
.customization-wrap .right-box .table-wrap-body .form .input-email {
  width: 90%;
  padding: 1.2rem 2rem;
  border: 0.1rem solid var(--border-color);
  border-radius: 0.5rem;
  margin-bottom: 2rem;
  outline: none;
  color: var(--black-color);
  background-color: var(--bg-white-color);
}
.customization-wrap .right-box .table-wrap-body .form .input-email:focus {
  outline: none;
  border: 0.1rem solid var(--border-color);
}
.customization-wrap .right-box .table-wrap-body .form .input-email::-moz-placeholder {
  color: #5b6871;
}
.customization-wrap .right-box .table-wrap-body .form .input-email::placeholder {
  color: #5b6871;
}
.customization-wrap .right-box .table-wrap-body .form .form-check-box {
  margin-top: 2rem;
  background-color: #f6f8f9;
  padding: 2rem 3rem;
  border: 0.1rem solid var(--border-color);
  border-radius: 1rem;
}
.customization-wrap .right-box .table-wrap-body .form .form-check-box > *:not(:last-child) {
  margin-bottom: 2rem;
}
.customization-wrap .right-box .table-wrap-body .form .form-check-box .check-group {
  display: flex;
  align-items: center;
}
.customization-wrap .right-box .table-wrap-body .form .form-check-box .check-group .check-input {
  display: none;
}
.customization-wrap .right-box .table-wrap-body .form .form-check-box .check-group .check-input:checked ~ .check-label .check-circle {
  background-color: var(--blue-color);
}
.customization-wrap .right-box .table-wrap-body .form .form-check-box .check-group .check-input:checked ~ .check-label .check-circle .icon {
  color: var(--white-color);
}
.customization-wrap .right-box .table-wrap-body .form .form-check-box .check-group .check-label {
  display: grid;
  align-items: center;
  grid-template-columns: min-content max-content 1rem max-content;
  grid-column-gap: 1rem;
  cursor: pointer;
}
.customization-wrap .right-box .table-wrap-body .form .form-check-box .check-group .check-label span {
  display: inline-block;
}
.customization-wrap .right-box .table-wrap-body .form .form-check-box .check-group .check-label .check-circle {
  width: 2.5rem;
  height: 2.5rem;
  border: 0.1rem solid var(--border-color);
  border-radius: 50%;
  margin-right: 1.5rem;
  display: grid;
  place-items: center;
}
.customization-wrap .right-box .table-wrap-body .form .form-check-box .check-group .check-label .check-circle .icon {
  color: #f6f8f9;
  width: 1.1rem;
  height: 1.1rem;
}
.customization-wrap .right-box .table-wrap-body .form .form-check-box .check-group .check-label .text-one {
  font-size: 1.3rem;
}
.customization-wrap .right-box .table-wrap-body .form .form-check-box .check-group .check-label .text-two {
  color: #84919a;
  font-size: 1.25rem;
}
.customization-wrap .right-box .table-wrap-body .form .form-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.3rem 3rem;
  border-radius: 0.8rem;
  background-color: #eceded;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  color: #c3c7ce;
  font-weight: 600;
  margin-top: 4rem;
  font-size: 1.3rem;
}
.customization-wrap .right-box .table-wrap-body .form .form-submit:hover {
  transform: translateY(-0.15rem);
}
.customization-wrap .right-box .table-wrap-body .form .form-submit-active {
  background-color: var(--blue-color);
  color: var(--white-color);
}/*# sourceMappingURL=CustomizationStyles.css.map */