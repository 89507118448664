

.welcome-box-wrap{
    transform: translate(50%, -60%);
    border-radius: 1.5rem;
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    width: 35rem;
    padding-top: 4rem;
    animation: moveUpWelcome .7s linear backwards;
    background-color: var(--bg-white-color);

    .arrow-box{
        position: absolute;
        top: 2rem;
        right: 2rem;
        cursor: pointer;
        
        .icon{
            color: var(--black-color);
        }
    }

    .title{
        font-weight: 500;
        margin-bottom: .5rem;
        color: var(--blue-color);
        font-size: 1.8rem;
    }

    .text{
        color: #84919A;
        font-size: 1.1rem;
    }

    .progress-box{
        display: flex;
        align-items: center;
        margin: 1.5rem 0rem;

        .percentage{
            flex: 0 0 5%;
            margin-right: 1rem;
            color: var(--black-color);
            font-weight: 600;
        }
    }

    .list-box{
        display: flex;
        flex-direction: column;

        .item{
            padding: 1.5rem 0rem;
            border-bottom: .1rem solid var(--border-color);
            display: flex;
            align-items: center;
        }

        .check-box {
            margin-right: 2rem;
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            display: grid;
            place-items: center;
            border: 0.1rem solid var(--border-color);
            // padding: .7rem;

            .icon {
              color: var(--white-color);
              width: 1rem;
              height: 1rem;
            }
          }

          .check-box-active{
            border: unset;
            background-color: var(--blue-color);

            .icon {
              color: #ffffff;
            }
          }

          .link{
            text-decoration: none;
            // background-color: red;
            // flex: 1;
            color: var(--black-color);
            font-size: 1.4rem;
          }
    }

    .dismiss{
        color: var(--blue-color);
        text-transform: capitalize;
        margin-top: 1.5rem;
        cursor: pointer;
        align-self: flex-start;
        font-size: 1.5rem;

    }
}




@keyframes moveUpWelcome {
    0%{
        transform: translate(50%, -40%);
        opacity: 0;
        filter: blur(.05rem);
    }
    100%{
        transform: translate(50%, -60%);
        opacity: 1;
        filter: blur(0rem);
    }
}