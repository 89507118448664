.action-bar-wrapper-box {
  width: 100%;
  overflow-y: hidden;

  @mixin action-bar {
    font-size: 1.25rem;
    // padding: 1rem 4rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    max-height: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    width: 100%;

    .img-box {
      width: 2.5rem;
      height: 2.5rem;
      transform: translateY(0.2rem);
      .img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    .text {
      font-size: 1.3rem;
      color: #020202;
    }

    .action-box {
      margin-right: auto;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover .arrow-icon {
        transform: translateX(0.2rem);
      }

      .text {
        font-weight: 700;
        margin-right: 1rem;
        font-size: 1.4rem;
      }

      .arrow-icon {
        border-radius: 50%;
        width: 1.8rem;
        height: 1.8rem;
        display: grid;
        place-items: center;
        transition: all 0.3s;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 170%;
          height: 170%;
          border-radius: 50%;
          // background-color: green;
          z-index: 1;
        }

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          border-radius: 50%;
          // background-color: red;
          z-index: 2;
        }

        & > * {
          z-index: 3;
        }

        .icon {
          width: 1rem;
          height: 1rem;
          color: #ffffff;
          fill: #ffffff;
        }
      }
    }

    .cancel {
      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;
      display: grid;
      place-items: center;
      // margin-left: auto;
      cursor: pointer;

      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }

  .action_bar_revvex__error {
    @include action-bar;
    background-color: #fff5f5;
    width: 100%;
    // display: none;

    .cancel {
      background-color: #ffdfdd;
      svg {
        fill: #ff0f00;
      }
    }

    .action-box {
      .text {
        color: #ff0f00;
      }

      .arrow-icon {
        &::after {
          background-color: #ffdfdd;
        }

        &::before {
          background-color: #ff0f00;
        }
      }
    }
  }

  .action_bar_revvex__info {
    @include action-bar;
    background: #e9f5ff !important;

    .cancel {
      background-color: #b9defe;
      svg {
        fill: #476885;
      }
    }

    .action-box {
      .text {
        color: #476885;
      }

      .arrow-icon {
        &::after {
          background-color: #b9defe;
        }

        &::before {
          background-color: #476885;
        }
      }
    }
  }

  .action_bar_revvex__warning {
    @include action-bar;
    background: #fff6ed !important;
    position: relative;

    .cancel {
      background-color: #ffe6cd;
      position: absolute;
      right: 4rem;
      svg {
        fill: #ea872d;
      }
    }

    .action-box {
      .text {
        color: #ea872d;
      }

      .arrow-icon {
        &::after {
          background-color: #ffe6cd;
        }

        &::before {
          background-color: #ea872d;
        }
      }
    }
  }

  .action_bar_revvex_show {
      padding: 1rem 4rem;
    max-height: 7rem;
    transition: all 0.5s linear-bezier(0, 1.05, 0, 1);
  }

  .action_bar_revvex__transform {
    transform: translateY(-100%);
  }
}

//   @each $key, $val in $colors {

//   }

@keyframes translateActionUp {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}
