.budget-main-wrap {
  //   padding: 4rem;
  // flex: 1;
  display: flex;
  height: 100%;
  position: relative;
    // background-color: red;

  .subtitle-box {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 0.5rem;
    }

    .text {
      cursor: pointer;
    }

    .active {
      font-weight: 600;
      color: var(--black-color);
    }
  }

  .side-wrap {
    flex: 0 0 20%;
    background-color: var(--bg-white-color);
    border-right: 0.1rem solid var(--border-color);
    display: flex;
    flex-direction: column;
   height: 100%;
    padding-top: 4rem;

    .underline {
      height: 0.1rem;
      background-color: var(--border-color);
      width: 100%;
      margin-bottom: 4rem;
    }

    .title {
      font-weight: 700;
      color: #464f54;
      margin-bottom: 3rem;
      padding-left: 3rem;
      cursor: pointer;
    }

    .link {
      font-size: 1.45rem;
      margin-bottom: 2.5rem;
      padding-left: 3rem;
      cursor: pointer;
    }

    .link-active {
      color: var(--blue-color);
    }
  }

  .main-wrap {
    flex: 1;
  }
}

.common-link-expenditure-rev {
  // background-color: red;
  padding: 4rem 0rem;

  // .btn-blue-wrap{
  //   display: none;
  //  }

  .main-title {
    margin-bottom: 3rem;
    font-weight: 700;
    font-size: 1.7rem;
    // color: ;
    padding-left: 3rem;
  }

  .box {
    display: flex;
    align-items: center;
    padding-bottom: 0.5rem;
    // background-color: red;
    border-bottom: 0.1rem solid var(--border-color);

    & > *:not(:last-child) {
      margin-right: 3rem;
    }

    .text {
      color: var(--black-color);
      padding: 1rem 5rem;
      font-weight: 500;
      cursor: pointer;
    }

    .text-active {
      background-color: var(--bg-white-color);
      box-shadow: var(--box-shadow-two);
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 0.5rem;
        width: 70%;
        left: 50%;
        background-color: var(--blue-color);
        transform: translateX(-50%);
        border-radius: 5rem;
      }
    }
  }
}

.table-box-budget {
  padding: 0rem 3rem;

  .btn-box-buget-template{
    display: flex;
    align-items: center;
    margin-top: 3rem;

    &>*:first-child{
        background-color: var(--bg-white-color);
        color: var(--blue-color);
        border: .1rem solid var(--blue-color);
        margin-right: 2rem;
    }
  }

  .drap-drop-box {
    height: 25rem;

    & > * {
      min-width: unset;
      max-width: unset;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center !important;
      border: 0.1rem dashed #b0babf;
      font-size: 2rem;

      & > *:not(:first-child) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center !important;
      }

      &>*:nth-child(2){
        // display: none;
        transform: scale(2) translateY(1.4rem);
        filter: saturate(0%);
      }

     
    }
  }

  .wrapper {
    margin-bottom: 4rem;
    border-bottom: 0.1rem solid var(--border-color);
    padding-bottom: 3rem;

    .check-label-box {
      // background-color: red;
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;
      width: 100%;

      .check {
        display: none;

        &:checked ~ .label span {
          background-color: var(--blue-color);
          border: unset;

          .icon {
            visibility: visible;
          }
        }
      }

      .label {
        display: flex;
        align-items: center;
        font-size: 1.5rem;
        cursor: pointer;

        span {
          margin-right: 1rem;
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          border: 0.1rem solid var(--border-color);
          display: grid;
          place-items: center;
          transition: all 0.3s;

          .icon {
            width: 1rem;
            height: 1rem;
            color: var(--white-color);
            visibility: hidden;
          }
        }
      }
    }

    .btn-set-up {
      align-self: flex-start;
      margin-top: 4rem;
      background-color: #eceded;
      padding: 1.2rem 4rem;
      color: #c3c7ce;
      text-align: center;
      cursor: pointer;
      border: 0.1rem solid var(--border-color);
      border-radius: 0.4rem;
      outline: none;
      transition: all 0.3s;
      font-weight: 500;

      &:hover {
        transform: translateY(-0.1rem);
      }
    }

    .btn-set-up-active {
      background-color: var(--blue-color);
      color: var(--white-color);
      border: none;
    }

    .box-title {
      color: var(--black-color);
      margin-bottom: 1rem;
      font-size: 1.5rem;
    }

    .small-text {
      font-size: 1.3rem;
    }

    .form-wrap {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 3rem;

      .form-group {
        display: flex;
        flex-direction: column;

        

        select {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none; /* Remove default arrow */
          background-image: url("../../../assets/drop-down-icon.svg");
          background-repeat: no-repeat; /* Add custom arrow */
          background-position: 95% center;
          background-size: 2rem;
        }

        .label {
          margin-bottom: 0.4rem;
          display: flex;
          align-items: center;
          color: #5b6871;

          span {
            margin-left: 0.4rem;
            color: #cc0905;
            display: inline-block;
            font-size: 1.7rem;
            transform: translateY(0.4rem);
          }
        }

        .input {
          border: 0.1rem solid var(--border-color);
          border-radius: 0.8rem;
          padding: 1.3rem 1rem;
          outline: none;
          width: 100%;
          font-size: 1.4rem;
          //   background-color: transparent;
          font-size: 1.35rem;
          color: var(--black-color);
          background-color: var(--bg-white-color);

          &::placeholder {
            font-size: 1.2rem;
            text-transform: capitalize;
          }

          &:focus {
            border: 0.1rem solid var(--blue-color);
            outline: none;
          }
        }

        .input-active {
          border: 0.1rem solid var(--blue-color);
        }
      }

      // .btn-blue-wrap{
      //   display: none;
      //  }
    }

    .form-wrap-six {
      grid-template-rows: repeat(3, 1fr);
    }
  }
}
