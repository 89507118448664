.budget-settings-common-modal-wrap {
  background-color: var(--bg-white-color);
  width: 40rem;
  border-radius: 1rem;
  min-height: 35rem;
  display: flex;
  flex-direction: column;
}
.budget-settings-common-modal-wrap .btn-blue-wrap {
  align-self: flex-start;
  margin-bottom: -2rem;
  background-color: #cc0905 !important;
}
.budget-settings-common-modal-wrap .form-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.7rem 3rem;
  border-radius: 0.8rem;
  background-color: #eceded;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  color: #c3c7ce;
  font-weight: 600;
  margin-top: 4rem;
  margin-left: 2rem;
  align-self: flex-start;
  margin-bottom: 2rem;
}
.budget-settings-common-modal-wrap .form-submit:hover {
  transform: translateY(-0.15rem);
}
.budget-settings-common-modal-wrap .form-submit-active {
  background-color: var(--blue-color);
  color: var(--white-color);
}
.budget-settings-common-modal-wrap .form-submit-forget-password {
  margin-top: 2rem;
}
.budget-settings-common-modal-wrap .title-box {
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  border-bottom: 0.1rem solid var(--border-color);
}
.budget-settings-common-modal-wrap .title-box .title {
  font-size: 1.7rem;
  font-weight: 600;
  color: var(--black-color);
}
.budget-settings-common-modal-wrap .title-box .cancel-box {
  margin-left: auto;
  background-color: #ffefeb;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.budget-settings-common-modal-wrap .title-box .cancel-box .icon {
  color: #cc0905;
}
.budget-settings-common-modal-wrap .content-box {
  flex: 1;
  padding: 1rem 2rem 3rem 2rem;
  background-color: var(--bg-fade-color);
}
.budget-settings-common-modal-wrap .content-box .text {
  font-size: 1.3rem;
  margin: 1rem 0rem 0rem 0rem;
  line-height: 1.6rem;
}
.budget-settings-common-modal-wrap .content-box > *:not(:last-child) {
  margin-bottom: 2rem;
}
.budget-settings-common-modal-wrap .content-box .file-input-box {
  display: flex;
  flex-direction: column;
}
.budget-settings-common-modal-wrap .content-box .file-input-box p {
  margin-bottom: 0.7rem;
}
.budget-settings-common-modal-wrap .content-box .file-input-box input {
  display: none;
}
.budget-settings-common-modal-wrap .content-box .file-input-box label {
  color: var(--blue-color);
  display: flex;
  align-items: center;
  cursor: pointer;
}
.budget-settings-common-modal-wrap .content-box .file-input-box label .icon {
  color: currentColor;
  margin-right: 0.7rem;
}
.budget-settings-common-modal-wrap .content-box .form-group {
  display: flex;
  flex-direction: column;
}
.budget-settings-common-modal-wrap .content-box .form-group .select > * {
  background-color: var(--bg-white-color);
  color: #1d1d18;
}
.budget-settings-common-modal-wrap .content-box .form-group .select > *:nth-child(3) {
  min-width: 9rem;
  background-color: var(--bg-white-color);
  border-radius: 0.7rem !important;
  border: 0.1rem solid var(--border-color);
}
.budget-settings-common-modal-wrap .content-box .form-group .select > *:nth-child(3) > *:nth-child(1) > *:nth-child(1) {
  color: var(--black-color);
}
.budget-settings-common-modal-wrap .content-box .form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  background-image: url("../../../assets/drop-down-icon.svg");
  background-repeat: no-repeat; /* Add custom arrow */
  background-position: 95% center;
  background-size: 2rem;
}
.budget-settings-common-modal-wrap .content-box .form-group .label {
  margin-bottom: 0.4rem;
  display: flex;
  align-items: center;
  color: #5b6871;
}
.budget-settings-common-modal-wrap .content-box .form-group .label span {
  margin-left: 0.4rem;
  color: #cc0905;
  display: inline-block;
  font-size: 1.7rem;
  transform: translateY(0.4rem);
}
.budget-settings-common-modal-wrap .content-box .form-group .input {
  border: 0.1rem solid var(--border-color);
  border-radius: 0.8rem;
  padding: 1.3rem 1rem;
  outline: none;
  width: 100%;
  font-size: 1.4rem;
  font-size: 1.35rem;
  color: var(--black-color);
  background-color: var(--bg-white-color);
}
.budget-settings-common-modal-wrap .content-box .form-group .input::-moz-placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.budget-settings-common-modal-wrap .content-box .form-group .input::placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.budget-settings-common-modal-wrap .content-box .form-group .input:focus {
  border: 0.1rem solid var(--blue-color);
  outline: none;
}
.budget-settings-common-modal-wrap .content-box .form-group .input-active {
  border: 0.1rem solid var(--blue-color);
}
.budget-settings-common-modal-wrap .content-box .form-group-check {
  margin-top: -1rem;
}
.budget-settings-common-modal-wrap .content-box .form-group-check .input-check-box {
  display: none;
}
.budget-settings-common-modal-wrap .content-box .form-group-check .input-check-box:checked ~ .label-check-box .new-check {
  background-color: var(--blue-color);
  border: none;
}
.budget-settings-common-modal-wrap .content-box .form-group-check .input-check-box:checked ~ .label-check-box .new-check .icon {
  display: block;
}
.budget-settings-common-modal-wrap .content-box .form-group-check .label-check-box {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}
.budget-settings-common-modal-wrap .content-box .form-group-check .label-check-box .text {
  margin-top: unset;
  font-weight: 500;
}
.budget-settings-common-modal-wrap .content-box .form-group-check .label-check-box .new-check {
  border-radius: 0.5rem;
  border: 0.1rem solid #cccccc;
  display: grid;
  place-items: center;
  width: 1.8rem;
  height: 1.8rem;
}
.budget-settings-common-modal-wrap .content-box .form-group-check .label-check-box .new-check .icon {
  width: 50%;
  height: 50%;
  display: none;
  color: #ffffff;
}
.budget-settings-common-modal-wrap .content-box .check-label-box {
  margin: 2rem 0rem;
  display: flex;
  align-items: center;
}
.budget-settings-common-modal-wrap .content-box .check-label-box .check {
  display: none;
}
.budget-settings-common-modal-wrap .content-box .check-label-box .check:checked ~ .label span {
  background-color: var(--blue-color);
  border: unset;
}
.budget-settings-common-modal-wrap .content-box .check-label-box .check:checked ~ .label span .icon {
  visibility: visible;
}
.budget-settings-common-modal-wrap .content-box .check-label-box .label {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  cursor: pointer;
}
.budget-settings-common-modal-wrap .content-box .check-label-box .label .blue {
  color: var(--blue-color);
  margin: 0rem 0.4rem;
}
.budget-settings-common-modal-wrap .content-box .check-label-box .label span {
  margin-right: 1rem;
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 0.3rem;
  border: 0.1rem solid var(--border-color);
  display: grid;
  place-items: center;
  transition: all 0.3s;
}
.budget-settings-common-modal-wrap .content-box .check-label-box .label span .icon {
  width: 1rem;
  height: 1rem;
  color: var(--white-color);
  visibility: hidden;
}
.budget-settings-common-modal-wrap .content-box-long {
  height: 40rem;
  flex: unset;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.budget-settings-common-modal-wrap .content-box-long::-webkit-scrollbar {
  width: 0.5rem;
}
.budget-settings-common-modal-wrap .content-box-long::-webkit-scrollbar-track {
  background-color: var(--bg-white-color);
  border-radius: 10px;
}
.budget-settings-common-modal-wrap .content-box-long::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--blue-color);
}
.budget-settings-common-modal-wrap .btn-box {
  padding: 1.5rem 2rem;
}

.form-group-currency-modal {
  display: flex;
  flex-direction: column;
}
.form-group-currency-modal select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  background-image: url("../../../assets/drop-down-icon.svg");
  background-repeat: no-repeat; /* Add custom arrow */
  background-position: 95% center;
  background-size: 2rem;
}
.form-group-currency-modal .label {
  margin-bottom: 0.4rem;
  display: flex;
  align-items: center;
  color: #5b6871;
}
.form-group-currency-modal .label span {
  margin-left: 0.4rem;
  color: #cc0905;
  display: inline-block;
  font-size: 1.7rem;
  transform: translateY(0.4rem);
}
.form-group-currency-modal .input {
  border: 0.1rem solid var(--border-color);
  border-radius: 0.8rem;
  padding: 1.3rem 1rem;
  outline: none;
  width: 100%;
  font-size: 1.4rem;
  font-size: 1.35rem;
  color: var(--black-shade-color);
}
.form-group-currency-modal .input::-moz-placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.form-group-currency-modal .input::placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.form-group-currency-modal .input:focus {
  border: 0.1rem solid var(--blue-color);
  outline: none;
}
.form-group-currency-modal .input-active {
  border: 0.1rem solid var(--blue-color);
}/*# sourceMappingURL=BudgetSettingCommonModal.css.map */