.budget-assumption-modal-home-page {
  // background-color: red;
  margin-top: auto;
  width: 100%;
  overflow-x: scroll;
  padding: 2rem 2rem;
  height: 15rem;

  .box-file {
    box-shadow: 0.1rem 0.1rem 8rem rgba(0, 0, 0, 0.05) !important;
    // background-color: red !important;
    // background-color: var(--blue-color) !important;
  }

  &::-webkit-scrollbar:vertical {
    display: none;
  }

  /* width */
  &::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.5rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: none;
    -webkit-box-shadow: none !important;
    background-color: transparent !important;
    // height: 1rem;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--blue-color);
    border-radius: 30rem;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: var(--blue-color);
  }

  .wrap {
    display: grid;
    grid-template-rows: 1fr;
    justify-items: flex-start;
    width: 100%;
    flex: 1;
    // background-color: green;
    padding-bottom: 1.5rem;

    & > *:not(:last-child) {
      margin-right: 2rem;
    }
    .box {
      width: 25rem;
      height: 13rem;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .box-file {
      background-color: var(--bg-color);
      box-shadow: 0.1rem 0.1rem 8rem 0.2rem rgba(0, 0, 0, 0.05);
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      font-size: 1.3rem;
      padding: 1rem 2rem;

      .type {
        span {
          font-weight: 800;
          text-transform: uppercase;
        }
      }

      .name {
        span {
          font-weight: 800;
          // color: var(--black-color);
        }
      }
    }
  }
}

.show-big-budget-assumption-modal-homne {
  width: 80vw;
  height: 90vh;
  background-color: var(--bg-white-color);
  display: grid;
  place-items: center;
  position: relative;
  border-radius: 1rem;

  .cancel-box {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    justify-self: flex-start;
    display: grid;
    place-items: center;
    border: 0.15rem solid #cc0905;
    cursor: pointer;

    .icon {
      color: #cc0905;
    }
  }

  .wrap {
    width: 90%;
    height: 90%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}
