.emp-expenditure-shared-service-budget-wrap {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.emp-expenditure-shared-service-budget-wrap .shared-percent-title {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}
.emp-expenditure-shared-service-budget-wrap .hide-box {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.emp-expenditure-shared-service-budget-wrap .hide-box .input {
  display: none;
}
.emp-expenditure-shared-service-budget-wrap .hide-box .input:checked ~ .label {
  background-color: var(--blue-color);
}
.emp-expenditure-shared-service-budget-wrap .hide-box .input:checked ~ .label > *:last-child {
  background-color: var(--bg-white-color);
}
.emp-expenditure-shared-service-budget-wrap .hide-box .input:checked ~ .label > *:first-child {
  background-color: var(--blue-color);
}
.emp-expenditure-shared-service-budget-wrap .hide-box .label-text {
  font-size: 1.6rem;
  font-weight: 500;
  margin-right: 1.5rem;
}
.emp-expenditure-shared-service-budget-wrap .hide-box .label {
  width: 5.5rem;
  height: 2.7rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eceded;
  transition: all 0.3s;
  cursor: pointer;
}
.emp-expenditure-shared-service-budget-wrap .hide-box .label span {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  transition: all 0.3s;
}
.emp-expenditure-shared-service-budget-wrap .hide-box .label > *:first-child {
  background-color: var(--bg-white-color);
}
.emp-expenditure-shared-service-budget-wrap .hide-box .label > *:last-child {
  background-color: #eceded;
  margin-left: 0.5rem;
}
.emp-expenditure-shared-service-budget-wrap .shared-budget-form-wrap {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.emp-expenditure-shared-service-budget-wrap .shared-budget-form-wrap .selected-main-wrapper-box {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.emp-expenditure-shared-service-budget-wrap .shared-budget-form-wrap .selected-main-wrapper-box .selected-item-sub {
  display: flex;
  flex-direction: column;
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  border-radius: 1.2rem;
  padding: 2rem;
}
.emp-expenditure-shared-service-budget-wrap .shared-budget-form-wrap .selected-main-wrapper-box .selected-item-sub .title {
  font-size: 1.55rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.emp-expenditure-shared-service-budget-wrap .shared-budget-form-wrap .selected-main-wrapper-box .selected-item-sub .title span {
  font-weight: 500;
}
.emp-expenditure-shared-service-budget-wrap .shared-budget-form-wrap .selected-main-wrapper-box .selected-item-sub .title .budget-input {
  width: 8rem;
  margin-left: 2rem;
  font-weight: 800;
}
.emp-expenditure-shared-service-budget-wrap .shared-budget-form-wrap .selected-main-wrapper-box .selected-item-sub .shared-item-box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.emp-expenditure-shared-service-budget-wrap .shared-budget-form-wrap .form-group {
  display: flex;
  flex-direction: column;
}
.emp-expenditure-shared-service-budget-wrap .shared-budget-form-wrap .form-group .select-wrap {
  text-transform: capitalize;
}
.emp-expenditure-shared-service-budget-wrap .shared-budget-form-wrap .form-group .select-wrap > * {
  background-color: var(--bg-white-color);
  color: #fff;
}
.emp-expenditure-shared-service-budget-wrap .shared-budget-form-wrap .form-group .select-wrap > *:nth-child(3) {
  min-width: 9rem;
  background-color: var(--bg-white-color);
  border-radius: 0.7rem !important;
  border: 0.1rem solid var(--border-color);
  color: #1d1d18;
}
.emp-expenditure-shared-service-budget-wrap .shared-budget-form-wrap .form-group .select-wrap > *:nth-child(3) > *:nth-child(1) > *:nth-child(1) {
  color: #1d1d18;
}
.emp-expenditure-shared-service-budget-wrap .shared-budget-form-wrap .form-group .disable-select > *:nth-child(3) {
  background-color: #eceded !important;
}
.emp-expenditure-shared-service-budget-wrap .shared-budget-form-wrap .form-group .disable-select > *:nth-child(3) > * {
  background-color: #eceded !important;
}
.emp-expenditure-shared-service-budget-wrap .shared-budget-form-wrap .form-group .select-wrap-active > * {
  background-color: var(--bg-white-color);
  color: #1d1d18 !important;
}
.emp-expenditure-shared-service-budget-wrap .shared-budget-form-wrap .form-group .select-wrap-active > *:nth-child(3) {
  min-width: 9rem;
  background-color: var(--bg-white-color);
  border-radius: 0.7rem !important;
  border: 0.1rem solid var(--blue-color);
  color: #1d1d18;
}
.emp-expenditure-shared-service-budget-wrap .shared-budget-form-wrap .form-group .select-wrap-active > *:nth-child(3) > *:nth-child(1) > *:nth-child(1) {
  color: #1d1d18 !important;
}
.emp-expenditure-shared-service-budget-wrap .shared-budget-form-wrap .form-group .select-wrap-multi > *:nth-child(3) > * > *:not(:last-child) > * {
  color: #ffffff !important;
}
.emp-expenditure-shared-service-budget-wrap .shared-budget-form-wrap .form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  background-image: url("../../../../../assets/drop-down-icon.svg");
  background-repeat: no-repeat; /* Add custom arrow */
  background-position: 95% center;
  background-size: 2rem;
}
.emp-expenditure-shared-service-budget-wrap .shared-budget-form-wrap .form-group .label {
  margin-bottom: 0.4rem;
  display: flex;
  align-items: center;
  color: #5b6871;
}
.emp-expenditure-shared-service-budget-wrap .shared-budget-form-wrap .form-group .label span {
  margin-left: 0.4rem;
  color: #cc0905;
  display: inline-block;
  font-size: 1.7rem;
  transform: translateY(0.4rem);
}
.emp-expenditure-shared-service-budget-wrap .shared-budget-form-wrap .form-group .input {
  border: 0.1rem solid var(--border-color);
  border-radius: 0.8rem;
  padding: 1.3rem 1rem;
  outline: none;
  width: 100%;
  font-size: 1.4rem;
  font-size: 1.35rem;
  color: var(--black-shade-color);
  color: var(--black-color);
  background-color: var(--bg-white-color);
}
.emp-expenditure-shared-service-budget-wrap .shared-budget-form-wrap .form-group .input::-moz-placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.emp-expenditure-shared-service-budget-wrap .shared-budget-form-wrap .form-group .input::placeholder {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.emp-expenditure-shared-service-budget-wrap .shared-budget-form-wrap .form-group .input:focus {
  border: 0.1rem solid var(--blue-color);
  outline: none;
}
.emp-expenditure-shared-service-budget-wrap .shared-budget-form-wrap .form-group .input-active {
  border: 0.1rem solid var(--blue-color);
}
.emp-expenditure-shared-service-budget-wrap .subsidiary-title {
  text-transform: capitalize;
}
.emp-expenditure-shared-service-budget-wrap .shared-form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.emp-expenditure-shared-service-budget-wrap .shared-budget-label {
  font-weight: 500;
}
.emp-expenditure-shared-service-budget-wrap .shared-budget-label span {
  color: #cc0905;
  margin-left: 0.3rem;
}
.emp-expenditure-shared-service-budget-wrap .shared-form-input {
  outline: none;
  width: 9rem;
  height: 4rem;
  justify-content: center;
  display: flex;
  border-radius: 1rem;
  border: 0.1rem solid var(--border-color);
  padding: 0 2rem;
}
.emp-expenditure-shared-service-budget-wrap .shared-form-input :focus {
  border: 0.1rem solid var(--blue-color);
}
.emp-expenditure-shared-service-budget-wrap .shared-form-input-active {
  border: 0.1rem solid var(--blue-color);
}
.emp-expenditure-shared-service-budget-wrap .share-budget-form-box {
  display: grid;
  grid-template-columns: 0.1fr 1fr 0.5fr 1fr;
  grid-gap: 2rem;
  align-items: center;
}
.emp-expenditure-shared-service-budget-wrap .share-budget-form-box .shared-budget-step {
  background-color: var(--blue-color);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  color: var(--white-color);
  display: grid;
  place-items: center;
  font-size: 1.2rem;
  font-weight: 600;
}
.emp-expenditure-shared-service-budget-wrap .share-budget-form-box .shared-budget-box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
}
.emp-expenditure-shared-service-budget-wrap .share-budget-form-box .shared-budget-label {
  font-weight: 500;
}
.emp-expenditure-shared-service-budget-wrap .share-budget-form-box .shared-budget-label span {
  color: #cc0905;
  margin-left: 0.3rem;
}
.emp-expenditure-shared-service-budget-wrap .share-budget-form-box .cancel-icon-wrap {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  justify-self: flex-start;
  display: grid;
  place-items: center;
  border: 0.15rem solid #cc0905;
  cursor: pointer;
}
.emp-expenditure-shared-service-budget-wrap .share-budget-form-box .cancel-icon-wrap .minus-icon {
  color: #cc0905;
}
.emp-expenditure-shared-service-budget-wrap .sharing-percent-text {
  color: var(--blue-color);
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 3rem;
  cursor: pointer;
}

.title-hide-box {
  display: flex;
  align-items: center;
  margin: 4rem 0rem 2rem 0rem;
}
.title-hide-box .title {
  font-size: 1.8rem;
  color: var(--black-color);
  font-weight: 700;
}
.title-hide-box .title span {
  color: #5b6871;
  font-weight: 500;
  margin-left: 2rem;
  font-size: 1.7rem;
}
.title-hide-box .hide-box {
  display: flex;
  align-items: center;
}
.title-hide-box .hide-box .input {
  display: none;
}
.title-hide-box .hide-box .input:checked ~ .label {
  background-color: var(--blue-color);
}
.title-hide-box .hide-box .input:checked ~ .label > *:last-child {
  background-color: var(--bg-white-color);
}
.title-hide-box .hide-box .input:checked ~ .label > *:first-child {
  background-color: var(--blue-color);
}
.title-hide-box .hide-box .label-text {
  font-size: 1.6rem;
  font-weight: 500;
  margin-right: 1.5rem;
}
.title-hide-box .hide-box .label {
  width: 5.5rem;
  height: 2.7rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eceded;
  transition: all 0.3s;
  cursor: pointer;
}
.title-hide-box .hide-box .label span {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  transition: all 0.3s;
}
.title-hide-box .hide-box .label > *:first-child {
  background-color: var(--bg-white-color);
}
.title-hide-box .hide-box .label > *:last-child {
  background-color: #eceded;
  margin-left: 0.5rem;
}/*# sourceMappingURL=expenditureProjection.css.map */