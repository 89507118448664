.subscription-end-general-modal {
  width: 45rem;
  min-height: 40rem;
  background-color: #ffffff;
  display: grid;
  place-items: center;
  position: relative;
}
.subscription-end-general-modal .cancel-box {
  margin-left: auto;
  background-color: #ffefeb;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.3rem;
  display: grid;
  place-items: center;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.subscription-end-general-modal .cancel-box .icon {
  color: #cc0905;
}
.subscription-end-general-modal .wrap {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  text-align: center;
  align-items: center;
}
.subscription-end-general-modal .wrap .img-wrap {
  display: grid;
  place-items: center;
}
.subscription-end-general-modal .wrap .img-wrap .img-box {
  width: 17rem;
  height: 17rem;
}
.subscription-end-general-modal .wrap .img-wrap .img-box .img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.subscription-end-general-modal .wrap .title {
  font-weight: 600;
  font-size: 2.5rem;
}
.subscription-end-general-modal .wrap .text {
  color: rgb(94, 99, 102);
}/*# sourceMappingURL=SubscriptionEndModal.css.map */