


.emp-expenditure-shared-service-budget-wrap {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  .shared-percent-title {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  .hide-box {
    margin-left: auto;
    display: flex;
    align-items: center;

    .input {
      display: none;

      &:checked ~ .label {
        background-color: var(--blue-color);

        & > *:last-child {
          background-color: var(--bg-white-color);
        }

        & > *:first-child {
          background-color: var(--blue-color);
        }
      }
    }

    .label-text {
      font-size: 1.6rem;
      font-weight: 500;
      margin-right: 1.5rem;
    }

    .label {
      width: 5.5rem;
      height: 2.7rem;
      border-radius: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #eceded;
      transition: all 0.3s;
      cursor: pointer;

      span {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        transition: all 0.3s;
      }

      & > *:first-child {
        background-color: var(--bg-white-color);
      }

      & > *:last-child {
        background-color: #eceded;
        margin-left: 0.5rem;
      }
    }
  }
  .shared-budget-form-wrap {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    .selected-main-wrapper-box {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .selected-item-sub {
        display: flex;
        flex-direction: column;
        border: 0.1rem solid rgba(0, 0, 0, 0.1);
        border-radius: 1.2rem;
        padding: 2rem;

        .title {
          font-size: 1.55rem;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          span {
            font-weight: 500;
          }
          .budget-input {
            width: 8rem;
            margin-left: 2rem;
            font-weight: 800;
          }
        }
        .shared-item-box {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }
      }
    }

    .form-group {
      display: flex;
      flex-direction: column;

      .select-wrap {
        // transform: translateY(.1rem);
        text-transform: capitalize;

        & > * {
          background-color: var(--bg-white-color);
          // color: #1d1d18;
          color: #fff;
          &:nth-child(3) {
            // border: none !important;
            min-width: 9rem;
            background-color: var(--bg-white-color);
            border-radius: 0.7rem !important;
            border: 0.1rem solid var(--border-color);
            color: #1d1d18;

            & > *:nth-child(1) {
              & > *:nth-child(1) {
                color: #1d1d18;
              }
            }
          }
        }
      }

      .disable-select {
        & > *:nth-child(3) {
          background-color: #eceded !important;

          & > * {
            background-color: #eceded !important;
          }
        }
      }

      .select-wrap-active {
        & > * {
          background-color: var(--bg-white-color);
          color: #1d1d18 !important;
          &:nth-child(3) {
            // border: none !important;
            min-width: 9rem;
            background-color: var(--bg-white-color);
            border-radius: 0.7rem !important;
            // border: 0.1rem solid var(--border-color);
            border: 0.1rem solid var(--blue-color);
            color: #1d1d18;

            & > *:nth-child(1) {
              & > *:nth-child(1) {
                color: #1d1d18 !important;
              }
            }
          }
        }
      }

      .select-wrap-multi {
        & > *:nth-child(3) {
          & > * {
            & > *:not(:last-child) {
              // color: #000000 !important;
              & > * {
                color: #ffffff !important;
              }
            }
          }
        }
      }

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none; /* Remove default arrow */
        background-image: url("../../../../../assets/drop-down-icon.svg");
        //   background-image: url("../../../assets/drop-down-icon.svg");
        background-repeat: no-repeat; /* Add custom arrow */
        background-position: 95% center;
        background-size: 2rem;
      }

      .label {
        margin-bottom: 0.4rem;
        display: flex;
        align-items: center;
        color: #5b6871;

        span {
          margin-left: 0.4rem;
          color: #cc0905;
          display: inline-block;
          font-size: 1.7rem;
          transform: translateY(0.4rem);
        }
      }

      .input {
        border: 0.1rem solid var(--border-color);
        border-radius: 0.8rem;
        padding: 1.3rem 1rem;
        outline: none;
        width: 100%;
        font-size: 1.4rem;
        // text-transform: capitalize;
        font-size: 1.35rem;
        color: var(--black-shade-color);
        color: var(--black-color);
        background-color: var(--bg-white-color);

        &::placeholder {
          font-size: 1.2rem;
          text-transform: capitalize;
        }

        &:focus {
          border: 0.1rem solid var(--blue-color);
          outline: none;
        }
      }

      .input-active {
        border: 0.1rem solid var(--blue-color);
      }
    }
  }

  .subsidiary-title {
    text-transform: capitalize;
  }
  .shared-form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    // width: 50rem;
  }
  .shared-budget-label {
    font-weight: 500;
    span {
      color: #cc0905;
      margin-left: 0.3rem;
    }
  }
  .shared-form-input {
    outline: none;
    width: 9rem;
    // font-weight: 800;

    // background-color: red;
    height: 4rem;
    justify-content: center;
    display: flex;
    border-radius: 1rem;
    border: 0.1rem solid var(--border-color);
    //   gap: 2rem;
    padding: 0 2rem;

    :focus {
      border: 0.1rem solid var(--blue-color);
    }
  }
  .shared-form-input-active {
    border: 0.1rem solid var(--blue-color);
  }

  .share-budget-form-box {
    display: grid;
    grid-template-columns: 0.1fr 1fr 0.5fr 1fr;
    grid-gap: 2rem;
    align-items: center;

    .shared-budget-step {
      background-color: var(--blue-color);
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      color: var(--white-color);
      display: grid;
      place-items: center;
      font-size: 1.2rem;
      font-weight: 600;
    }
    .shared-budget-box {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      position: relative;
    }
    .shared-budget-label {
      font-weight: 500;
      // margin-bottom: 1rem;
      span {
        color: #cc0905;
        margin-left: 0.3rem;
      }
    }
    .cancel-icon-wrap {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      justify-self: flex-start;
      display: grid;
      place-items: center;
      border: 0.15rem solid #cc0905;
      cursor: pointer;
      .minus-icon {
        color: #cc0905;
      }
    }
  }

  .sharing-percent-text {
    color: var(--blue-color);
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 3rem;
    cursor: pointer;
  }
}

.title-hide-box {
  display: flex;
  align-items: center;
  margin: 4rem 0rem 2rem 0rem;

  .title {
    font-size: 1.8rem;
    color: var(--black-color);
    font-weight: 700;

    span {
      color: #5b6871;
      font-weight: 500;
      margin-left: 2rem;
      font-size: 1.7rem;
    }
  }

  .hide-box {
    // margin-left: auto;
    display: flex;
    align-items: center;

    .input {
      display: none;

      &:checked ~ .label {
        background-color: var(--blue-color);

        & > *:last-child {
          background-color: var(--bg-white-color);
        }

        & > *:first-child {
          background-color: var(--blue-color);
        }
      }
    }

    .label-text {
      font-size: 1.6rem;
      font-weight: 500;
      margin-right: 1.5rem;
    }

    .label {
      width: 5.5rem;
      height: 2.7rem;
      border-radius: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #eceded;
      transition: all 0.3s;
      cursor: pointer;

      span {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        transition: all 0.3s;
      }

      & > *:first-child {
        background-color: var(--bg-white-color);
      }

      & > *:last-child {
        background-color: #eceded;
        margin-left: 0.5rem;
      }
    }
  }

  //   .hide-box {
  //     margin-left: auto;
  //     display: flex;
  //     align-items: center;

  //     .input {
  //       display: none;

  //       &:checked ~ .label {
  //         background-color: var(--blue-color);

  //         & > *:last-child {
  //           background-color: var(--bg-white-color);
  //         }

  //         & > *:first-child {
  //           background-color: var(--blue-color);
  //         }
  //       }
  //     }

  // not really need this
  //     .label-text {
  //       font-size: 1.6rem;
  //       font-weight: 500;
  //       margin-right: 1.5rem;
  //     }

  //     .label {
  //       width: 5.5rem;
  //       height: 2.7rem;
  //       border-radius: 2rem;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       background-color: #eceded;
  //       transition: all 0.3s;
  //       cursor: pointer;

  //       span {
  //         width: 2rem;
  //         height: 2rem;
  //         border-radius: 50%;
  //         transition: all 0.3s;
  //       }

  //       & > *:first-child {
  //         background-color: var(--bg-white-color);
  //       }

  //       & > *:last-child {
  //         background-color: #eceded;
  //         margin-left: 0.5rem;
  //       }
  //     }
  //   }
  // }
}
