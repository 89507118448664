.employee-activate-message {
  padding: 12rem;
  margin-left: 12rem;
  font-weight: 400;
  font-size: 16px;
  /* bottom: 3rem; */
  /* position: relative; */
  margin-top: 0;
}

.employee-salutation {
  width: 130px;
  height: 24px;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #1d1d18;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 5rem;
}

.employee-profile {
  display: flex;
}

.employee-profile-img {
  width: 30px;
  height: 30px;
  padding: 2px;
  margin-right: 4px;
  flex-grow: 0;
  position: relative;
  top: 10px;
}

.employee-p-2 {
  margin-top: 2rem;
  display: block;
  width: 200vh;
  line-height: 6rem;
}

.employee-link-msg {
  font-size: 10px;
  font-weight: 400;
  width: 100vh;
  line-height: 2rem;
  margin-top: 5rem;
}
.employee-email-span {
  color: #84919A;
  font-weight: 300;
}
