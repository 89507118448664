

.three-box-permission-common{
  // background-color: red;
  padding: 0rem 4rem !important;
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  cursor: pointer;

  .input-check-box{
    justify-self: center;
    background-color: var(--white-color);
    width: 2rem;
    height: 2rem;
    border-radius: .4rem;
    border: .1rem solid var(--border-color);
    display: grid;
    place-items: center;

    .icon{
      color: var(--white-color);
      width: 1rem;
      height: 1rem;
    }
  }

  .box{
    font-size: 1.25rem;
    display: flex;
    align-items: center;


    .img-box{
      width: 3rem;
      height: 3rem;
      margin-right: 1rem;
      border-radius: 50%;
      overflow: hidden;

      .img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        display: block;
      }
    }

    .transfer{
      padding: .4rem 1.5rem;
      border-radius: 1rem;
      color: #0E73F6;
      background-color: #D7EDFF;
    }
  }

  .last-box{
    justify-self: center;
  }
  
  .text-title{
    color: #5B6871;
    font-size: 1.25rem;
  }
}

// .three-box-content{
//   justify-items: space-between;
// }

.input-check-common-permission{
  display: none;

  &:checked ~ .three-box-permission-common .input-check-box {
    background-color:  var(--blue-color);
  }
}

.underline-common-permission{
  margin-top: unset;
  margin-bottom: unset;
  margin: 0rem ;
  opacity: .5;
}