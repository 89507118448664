.emp-adjust-report {
  // display: grid;
  // grid-template-columns: 1fr 1fr !important;
  margin-top: -2rem;
}

.emp-filter-section {
  display: flex;
  justify-content: flex-end;
  // grid-template-columns: 1fr 1fr 1fr;
  // position: absolute;
  // right: 5rem;
  // gap: 1.5rem;
  // text-align: center;
  .filter-label {
    font-size: 12px;
    // width:  100%;
    margin-top: 1rem;
    // position: relative;
    // left: 5rem;
  }

}
.emp-report-table-wrap {
  display: grid;
  grid-template-columns: 1fr;

  .emp-report-table-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
  }
  .reports-title-wrap {
    display: flex;
  }
  .reports-title {
    color: var(--black-color);
    font-weight: 500;
    font-size: 1.7rem;
    margin: 0.5rem;
  }
  .report-items {
    margin-top: 2rem;
    display: grid;
    gap: 2rem;
  }
  .reports-text {
    color: var(--blue-color);
    font-size: 15px;
    font-weight: 300;
    cursor: pointer;
  }
}
