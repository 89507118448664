.success-modal-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 2rem;
  padding: 3rem;
  font-weight: 400;
  width: 70rem;
  position: relative;
  // font-size: 2rem;

  .cancel-icon {
    position: absolute;
    right: 2rem;
    top: 0.5rem;
    cursor: pointer;
    padding: 0.6rem;
    margin: 0.6rem;
    border-radius: 50%;
    background-color: rgba(255, 239, 235, 1);
  }
  img {
    width: 10rem;
  }
  p {
    font-size: 1.5rem;
    // line-height: 3rem;
  }
}

.paystack-wrap {
  width: 50rem;
  .cancel {
    display: flex;
    justify-content: right;
    cursor: pointer;
    padding: 0.6rem;
    margin: 0.6rem;
    border-radius: 50%;
  }
}
