.approval-flow-wrap {
  width: 100%;
  padding: 3rem 4rem;
  display: flex;
  flex-direction: column;
}
.approval-flow-wrap .title {
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: var(--black-color);
}
.approval-flow-wrap .img-text-wrap {
  height: 50rem;
  display: grid;
  place-items: center;
}
.approval-flow-wrap .img-text-wrap .content-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.approval-flow-wrap .img-text-wrap .content-box .img-box {
  width: 15rem;
  height: 15rem;
}
.approval-flow-wrap .img-text-wrap .content-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  display: block;
}
.approval-flow-wrap .img-text-wrap .content-box .text {
  color: #84919A;
  font-size: 1.6rem;
}
.approval-flow-wrap .text-button-box {
  margin-top: 5rem;
  align-self: center;
  display: flex;
  align-items: center;
}
.approval-flow-wrap .text-button-box .btn {
  background-color: var(--blue-color);
  padding: 0.8rem 2rem;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--white-color);
  margin-left: 2rem;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  transition: all 0.3s;
  font-weight: 600;
}
.approval-flow-wrap .text-button-box .btn:hover {
  transform: translateY(-0.1rem);
}
.approval-flow-wrap .text-button-box .text {
  font-size: 1.3rem;
}/*# sourceMappingURL=ApprovalDetailFlow.css.map */