.partial-reimburse-wrap {
  display: flex;
  flex-direction: column;
  // gap: 1rem;
  width: 65rem;
  margin-top: 2rem;
  .title {
    padding: 1rem 3rem;
    font-weight: 600;
    font-size: 2rem;
  }
  .text {
    padding: 1rem 3rem;
    margin-bottom: 1rem;
  }

  .par-form-wrap {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    .par-form-group {
      display: flex;
      width: 100%;
      // gap: 2rem;

      background-color: #f8fafc;
      padding: 2rem 3rem;
      // justify-content: left;
      justify-content: space-between;
      align-items: center;
      .label {
        display: flex;
        gap: 2rem;
        cursor: pointer;
      }
      .label-active {
        font-weight: 400;
        // transition: 0.1s;
      }
    }
    input[type="text"] {
      border: 0.1rem solid var(--border-color);
      border-radius: 0.3rem;
      outline: none;
      padding: 1.5rem 1rem;
      width: 30rem;
      // background-color: var(--border-color);
    }
    input[type="radio"] {
      display: none;
    }
    input:read-only {
      cursor: default;
    }
    .field-active {
      border: 0.1rem solid var(--blue-color) !important;
      transition: 0.5s;
    }
    .radio-icon {
      color: var(--border-color);
      font-size: 2rem;
      cursor: pointer;
    }
    .radio-icon-active {
      color: var(--blue-color);
      transition: 0.5s;
    }
  }
  .btn {
    display: flex;
    justify-content: right;
    gap: 2rem;
    padding: 3rem;
  }
}
